import React from 'react'
import JobTitleReportPage from '../../components/Result/Report/JobTitle/JobTitleReportPage';
import { usePageState } from '../../system/context/PageContext';

function JobTitleMain() {
  const page = usePageState();

  if(page[0]){
    return (
      <JobTitleReportPage evaluationId={page[0].evaluationId}></JobTitleReportPage>
    )
  }else{
    return <></>;
  }
}

export default JobTitleMain