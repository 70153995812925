import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Paper } from '@material-ui/core'
import TitleDiv from '../../Common/TitleDiv'
import MaterialTable, { MTableToolbar } from 'material-table';
import { TABLEICONS } from '../../../system/Constants';
import { EvaluationItemViewModel } from '../../../system/types/type';
import { ErrorHandler, EvaluationApi } from '../../../system/ApiService';
import { LMATERIAL_TABLE } from '../../../system/Localization';
import { useUserState } from '../../../system/context/UserContext';
import SubTitleDiv from '../../Common/SubTitleDiv';
import PrimaryButton from '../../Common/PrimaryButton';
import EvaluatePage from './EvaluatePage';
import { InitEvaluationItemViewModel } from '../../../system/types/initObject';
import { TABLE_HEADER } from '../../../styles/theme';
import SecondaryButton from '../../Common/SecondaryButton';

interface EvaluateTableProps {
  id: number,
}

function EvaluateTable({id: evaluationID}: EvaluateTableProps) {
  const user = useUserState();
  const [data, setData] = useState<EvaluationItemViewModel[]>([]);
  const [evaluateModal, setEvaluateModal] = useState<boolean>(false);
  const [state, setState] = useState<EvaluationItemViewModel>(InitEvaluationItemViewModel);

  const onLoad = useCallback(() => {
    if (evaluationID !== 0) {
      EvaluationApi.GetEvaluationItemList(evaluationID, "evaluator", user.employeeId, 1)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err,"err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    }
  }, [evaluationID, user.employeeId])

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const openEvaluateModal = (data: EvaluationItemViewModel) => {
    setState(data);
    setEvaluateModal(true);
  };

  const closeEvaluateModal = () => {
    setEvaluateModal(false)
    onLoad();
  };
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title='평가하기'></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <SubTitleDiv title='본인의 평가대상자를 확인하신 후 본인 및 타인 평가를 진행해 주시기 바랍니다.'></SubTitleDiv>
      </Grid>
      <Grid item xs={8} style={{ minWidth: "1000px"}}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            { title: 'No.', field: '', cellStyle: { width: "5%" }, align: "center",        
              render: rowData => {
                return (
                  <>{data.indexOf(rowData) + 1}</>
                )
              } 
            },
            { title: '파트', field: 'evaluateeParentTeam', cellStyle: { width: "15%" },  align: "center", },
            { title: '팀', field: 'evaluateeTeam', cellStyle: { width: "15%" }, align: "center", },
            { title: '성명', field: 'evaluateeName', cellStyle: { width: "10%" }, align: "center", },
            { title: '', field: '', cellStyle: { width: "10%" }, align: "center",
              render: rowData => {
                return (
                  <>
                    {rowData.status === 3 ?      
                      <SecondaryButton
                        onClick={() => openEvaluateModal(rowData)}
                      >평가완료</SecondaryButton> :
                      <PrimaryButton
                        onClick={() => openEvaluateModal(rowData)}
                      >평가진행</PrimaryButton> 
                    }
                  </>     
                )
              }
            },
          ]}
          data={data || []}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            headerStyle: TABLE_HEADER,
            padding: "dense",
          }}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
            Toolbar: props => (
              <div style={{backgroundColor: '#fafafa'}}>
                <MTableToolbar {...props} />
              </div>
            )
          }}
          localization={LMATERIAL_TABLE}
        />
        <EvaluatePage
          state={state}
          open={evaluateModal}
          onClose={closeEvaluateModal}
        ></EvaluatePage>
      </Grid>
    </Grid>
  )
}


export default EvaluateTable