import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import TitleDiv from "../../../Common/TitleDiv";
import SelectEvaluation from "../../../Common/SelectEvaluation";
import { Evaluation, EvaluationStatisticsTalbeInfo } from "../../../../system/types/type";
import { InitEvaluation, InitEvaluationStatisticsTableInfo } from "../../../../system/types/initObject";
import TitleDivInReport from "../../../Common/TitleDivInReport";
import EvaluationStatisticsTable from "../common/EvaluationStatisticsTable";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";
import EvaluationStatisticsSummary from "../common/EvaluationStatisticsSummaryTable";

interface JobPositionReportPageProps {
   evaluationId: number;
}

function JobPositionReportPage({ evaluationId }: JobPositionReportPageProps) {
   const [evaluation, setEvaluation] = useState<Evaluation>({ ...InitEvaluation, id: evaluationId });
   const [info, setInfo] = useState<EvaluationStatisticsTalbeInfo>(InitEvaluationStatisticsTableInfo);
   const columnName = "jobPosition";

   useEffect(() => {
      let obj: EvaluationStatisticsTalbeInfo = InitEvaluationStatisticsTableInfo;
      ReportApi.GetEvaluationStatisticsKind(evaluationId)
         .then((res) => {
            let kinds = res.data.jobPosition;
            kinds.push({ text: "전체 평균", value: "전체 평균" });
            obj = { ...obj, kinds: kinds };
            ReportApi.GetEvaluationReportTypes(evaluationId, "total", 0)
               .then((res) => {
                  obj = { ...obj, types: res.data };
                  setInfo(obj);
               })
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [evaluationId]);

   const onChangeEvaluation = (evaluation: Evaluation) => {
      setEvaluation(evaluation);
   };

   return <>
   <Grid container spacing={2}>
      <Grid item xs={12}>
         <TitleDiv title="직위별 평가 결과"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
         <SelectEvaluation onChangeEvaluation={onChangeEvaluation}></SelectEvaluation>
      </Grid>
      <Grid item xs={12}>
         <TitleDivInReport title={`${evaluation.title} 결과 조회(직위별 종합)`}></TitleDivInReport>
      </Grid>
      {/* EvaluationItemType별 결과 통계 테이블 */}
      {info.types.map((item, index) => {
         return (
            <Grid item xs={12}>
               <EvaluationStatisticsTable
                  evaluation={evaluation}
                  evaluationItemType={item.type}
                  evaluationItemTypeName={item.value}
                  index={index}
                  kinds={info.kinds}
                  columnName={columnName}
               ></EvaluationStatisticsTable>
            </Grid>
         );
      })}
      <Grid item xs={12}>
         <EvaluationStatisticsSummary evaluationId={evaluation.id} columnName={columnName}></EvaluationStatisticsSummary>
      </Grid>
   </Grid>
</>;
}

export default JobPositionReportPage;
