import React, { useEffect, useState, useMemo } from "react";
import TitleDiv from "../../Common/TitleDiv";
import { Grid, Typography } from "@material-ui/core";
import SelectEmployeeSimple from "../../Common/SelectEmployee";
import { InitEvaluation, InitUser } from "../../../system/types/initObject";
import { useUserState } from "../../../system/context/UserContext";
import { Evaluation, User } from "../../../system/types/type";
import SelectEvaluation from "../../Common/SelectEvaluation";
import { EmployeeApi, ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import EvaluateeInformation from "./common/EvaluateeInformation";
import MultipleChoiceReport from "./MultipleChoice/MultipleChoiceReport";
import SelectEmployeeByTreeView from "../../Common/SelectEmployeeByTreeView";
import { IsAdmin1 } from "../../../system/types/interface";
import EssayAnswerReport from "./EssayAnswer/EssayAnswerReport";
import TitleDivInReport from "../../Common/TitleDivInReport";

interface EvaluationReportProps {
   id: number;
}

function EvaluationReport({ id }: EvaluationReportProps) {
   const user = useUserState();
   const [employee, setEmployee] = useState<User>({ ...InitUser, employeeId: user.employeeId });
   const [evaluatee, setEvaluatee] = useState<User>(InitUser);
   const [evaluation, setEvaluation] = useState<Evaluation>({...InitEvaluation, id: id});
   const [empList, setEmpList] = useState<User[]>([]);

   useMemo(() => {
      // 전체사원 리스트 조회
      EmployeeApi.GetEmployee()
         .then((res) => {
            setEmpList(res.data);
         })
         .catch((err) => {
            console.log(err);
            let msg = ErrorHandler(err);
            alert(msg);
         });
   }, []);

   useEffect(() => {
      if (employee.employeeId !== "") {
         EvaluationApi.GetEvaluateeByEmployeeId(evaluation.id, employee.employeeId)
            .then((res) => {
               if (!res.data.employeeId) {
                  setEvaluatee({ ...InitUser });
               } else {
                  EmployeeApi.GetEmployeeByEmployeeId(res.data.employeeId)
                     .then((emp) => {
                        setEvaluatee(emp.data);
                     })
                     .catch((err) => {
                        let msg = ErrorHandler(err);
                        alert(msg);
                     });
               }
            })
            .catch((error) => {
               let msg = ErrorHandler(error);
               alert(msg);
            });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [evaluation, employee]);

   const onChangeEvaluation = (evaluation: Evaluation) => {
      setEvaluation(evaluation);
   };

   const onChangeEmp = (data: User) => {
      if (data) {
         setEmployee(data);
      }
   };

   const nodeClicked = (args: any): void => {
      var employeeId = args.node.dataset.uid;
      var employee = empList.find((x) => x.employeeId === employeeId);
      if (employee !== undefined) setEmployee(employee);
   };

   return (
      <>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <TitleDiv title="결과 보고서"></TitleDiv>
            </Grid>
            <Grid item xs={6}>
               <SelectEvaluation onChangeEvaluation={onChangeEvaluation}></SelectEvaluation>
            </Grid>
            <Grid item xs={6}>
               {IsAdmin1(user.role) && <SelectEmployeeSimple onChange={onChangeEmp}></SelectEmployeeSimple>}
            </Grid>
            <Grid item xs={12}>
               <TitleDivInReport title={`${evaluation.title} 결과보고서`}></TitleDivInReport>
            </Grid>
            {IsAdmin1(user.role) &&<Grid item xs={2}>
               <Grid container>
                  <SelectEmployeeByTreeView nodeClicked={nodeClicked}></SelectEmployeeByTreeView>
               </Grid>
            </Grid>}
            {/* 혈압계 임시 조회 기능 */}
            {(user.employeeId === "20190118" || user.employeeId === "20190907") &&<Grid item xs={2}>
               <Grid container>
                  <SelectEmployeeByTreeView nodeClicked={nodeClicked}></SelectEmployeeByTreeView>
               </Grid>
            </Grid>}
            <Grid item xs={(IsAdmin1(user.role) || (user.employeeId === "20190118" || user.employeeId === "20190907")) ? 10 : 12}>
               {evaluatee.employeeId === "" ? (
                  <Grid item xs={12}>
                     <Typography variant="h6">해당 평가를 진행하지 않은 임직원입니다.</Typography> 
                  </Grid>
               ) : (
                  <>
                     {/* 평가 대상자 정보 */}
                     <Grid item xs={9}>
                        <EvaluateeInformation employee={evaluatee}></EvaluateeInformation>
                     </Grid>
                     <Grid item xs={3}></Grid>
                     {/* 객관식 평가 결과 */}
                     <Grid item xs={12}>
                        <MultipleChoiceReport evaluationId={evaluation.id} employee={evaluatee}></MultipleChoiceReport>
                     </Grid>
                     {/* 주관식 평가 결과 */}
                     <Grid item xs={12}>
                        <EssayAnswerReport evaluationId={evaluation.id} employee={evaluatee}></EssayAnswerReport>
                     </Grid>
                     {/* 임시 주관식 수정 테이블 */}
                     {/* <Grid item xs={12}>
                        <br></br>
                        <br></br>
                        <TitleDiv title="임시 주관식 평가 수정"></TitleDiv>
                        <EssayAnswerReportForAdmin evaluationId={evaluation.id} employee={evaluatee}></EssayAnswerReportForAdmin>
                     </Grid> */}
                  </>
               )}
            </Grid>
         </Grid>
      </>
   );
}

export default EvaluationReport;
