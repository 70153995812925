import { TreeViewComponent } from '@syncfusion/ej2-react-navigations'
import React, { useEffect, useState } from "react";
import { EmployeeApi, ErrorHandler } from '../../system/ApiService';
import { useUserState } from '../../system/context/UserContext';
import { InitDeptTreeView } from '../../system/types/initObject';
import { DeptTreeView } from '../../system/types/type';

interface SelectEmployeeByTreeViewProps{
   nodeClicked: (args: any)=>void;
}

function SelectEmployeeByTreeView({ nodeClicked }: SelectEmployeeByTreeViewProps) {
   const [dept, setDept] = useState<DeptTreeView>(InitDeptTreeView);
   const field: Object = { dataSource: [dept], id: "id", text: "name", child: "subChild" };
   const style: string = "DeptAndEmpTree";
   const user = useUserState();

   useEffect(() => {
      // 현재연도 조직도 불러오기
      EmployeeApi.GetDeptTreeViewAndEmployee(new Date().getFullYear())
         .then((res) => {
            if (res !== undefined) {
               res.data.subChild?.forEach((x) => {
                  if (x.name !== "지사") x.expanded = true;
               });
               res.data.subChild = res.data.subChild?.filter((x)=>x.id!=="00C00000");
               // 혈압계 임시 조회 기능 시작 ///////////////////////////////////////////////////////
               if(user.employeeId === "20190118" || user.employeeId === "20190907"){
                  res.data.subChild = res.data.subChild?.filter((x)=>x.id==="22BS01");
                  res.data.subChild?.forEach((x) => {
                     x.subChild?.forEach((y)=>{
                        y.expanded = true;
                     })
                  });
               }
               // 혈갑계 임시 조회 기능 끝 ///////////////////////////////////////////////////////
               setDept({ ...res.data, expanded: true });
            }
         })
         .catch((err) => {
            console.log(err);
            let msg = ErrorHandler(err);
            alert(msg);
         });
   }, []);



  return (
    <><TreeViewComponent
    fields={field}
    cssClass={style}
    nodeClicked={nodeClicked}
 ></TreeViewComponent></>
  )
}

export default SelectEmployeeByTreeView