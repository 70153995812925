import React, { useCallback, useEffect, useState } from 'react'
import { Grid, makeStyles, Paper, TextField } from '@material-ui/core'
import MaterialTable, { MTableToolbar } from 'material-table';
import { TABLEICONS } from '../../../system/Constants';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EvaluationTerm } from '../../../system/types/type';
import { ErrorHandler, EvaluationApi } from '../../../system/ApiService';
import { LMATERIAL_TABLE } from '../../../system/Localization';
import { TABLE_ACTION, TABLE_HEADER } from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  inputDate: {
    border: "black",
    borderBottom: "1px black solid",
    "&:focus" : {
      border: "0.5px solid green",
    }
  }
}));

interface EvaluationPeriodTableProps {
  id: number;
}

function EvaluationPeriodTable({id}: EvaluationPeriodTableProps ) {
  const classes = useStyles();
  const [data, setData] = useState<EvaluationTerm[]>([]);

  const onLoad = useCallback(() => {
    EvaluationApi.GetTerm(id)
    .then((res) => {
      setData(res.data)
    })
    .catch((err) => {
      console.log(err, "err");
      let msg = ErrorHandler(err);
        alert(msg);
    })
  }, [id])

  useEffect(() => {
    onLoad();
  }, [onLoad])

  function checkValidation(data: EvaluationTerm) {
    if (data.started === undefined) {
       alert("시작일자를 입력해주세요.");
       return false;
    } else if (data.ended === undefined) {
       alert("종료일자를 선택해주세요.");
       return false;
    } else if (data.term === undefined) {
       alert("내용을 입력해주세요.");
       return false;
    } 
    return true;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ minWidth: "1000px"}}>
        <MaterialTable
          title="평가 기간 설정"
          icons={TABLEICONS}
          columns={[
            { title: '시작 일자', field: 'started', cellStyle: { width: 300 },
              editComponent: props => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    value={new Date(props.value)}
                    minDate={new Date("2022-01-01")}
                    format="yyyy-MM-dd"
                    variant="inline"
                    className={classes.inputDate}
                    style={{ textAlign: "center" }}
                    onChange={(e: MaterialUiPickersDate) => 
                      props.onChange(moment(e).format("YYYY-MM-DD"))
                    }
                    disableToolbar
                  />
                </MuiPickersUtilsProvider>
              )
            },
            { title: '종료 일자', field: 'ended', cellStyle: { width: 300 },
              editComponent: props => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    value={new Date(props.value)}
                    minDate={props.rowData.started || new Date("2022-01-01")}
                    format="yyyy-MM-dd"
                    variant="inline"
                    className={classes.inputDate}
                    style={{ textAlign: "center" }}
                    onChange={(e: MaterialUiPickersDate) => 
                      props.onChange(moment(e).format("YYYY-MM-DD"))
                    }
                    disableToolbar
                  />
              </MuiPickersUtilsProvider>
              )
            },
            { title: '내용', field: 'term', cellStyle: { width: "50%" }, editable: 'never',
              editComponent: props => (
                <TextField
                  multiline
                  value={props.value}
                  style={{ width: "100%" }}
                  onChange={e => props.onChange(e.target.value)}
                ></TextField>
              )
            },
          ]}
          data={data}
          editable={{
            onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (checkValidation(newData)) {
                  EvaluationApi.UpdateTerm(newData)
                  .then(() => {
                    onLoad();
                  })
                  resolve(newData);
                } else {
                  reject();
                }
              }, 100)
            }),
          }}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
            Toolbar: props => (
              <div style={{backgroundColor: '#fafafa'}}>
                <MTableToolbar {...props} />
              </div>
            )
          }}
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            pageSize: 9,
            headerStyle: TABLE_HEADER,
            actionsCellStyle: TABLE_ACTION,
          }}
          localization={LMATERIAL_TABLE}
        />
      </Grid>
    </Grid>
  )
}


export default EvaluationPeriodTable