import React, {useEffect, useState} from 'react'
import { ErrorHandler, ReportApi } from '../../../../system/ApiService';
import { EvaluationAnswer, User } from '../../../../system/types/type';
import { Typography, makeStyles, TableBody, TableRow } from "@material-ui/core";
import EvaluationReportAsEvaluatorHeader from './EvaluationReportAsEvaluatorHeader';
import EvaluationReportAsEvaluatorRows from './EvaluationReportAsEvaluatorRows';

interface EvaluationReportAsEvaluatorProps{
   evaluationId: number;
   employee: User;
}

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: "800px",
   },
 }));

function EvaluationReportAsEvaluator({evaluationId, employee }:EvaluationReportAsEvaluatorProps) {
   const [data, setData] = useState<EvaluationAnswer[][]>([]);
   const classes = useStyles();

   useEffect(()=>{
      ReportApi.GetEvaluationReportAsEvaluator(evaluationId, employee.employeeId, "choice", 2).then((res)=>{
         let temp : EvaluationAnswer[][] = [];
         temp.push(res.data);
         ReportApi.GetEvaluationReportAsEvaluator(evaluationId, employee.employeeId, "choice", 6).then((res)=>{
            temp.push(res.data);
            setData(temp);
         }).catch((error)=>{
            let msg = ErrorHandler(error);
            alert(msg);
         });
      }).catch((error)=>{
         let msg = ErrorHandler(error);
         alert(msg);
      })
   }, [evaluationId, employee]);

  return (
    <>   
      <Typography variant="h6" style={{marginTop:"20px", marginBottom:"10px"}}>4. 평가대상자가 진행한 평가 결과</Typography>
      <TableBody className={classes.table}>
         <TableRow>
            {/* 리더평가, 회사평가 중 더 길이가 긴 것을 헤더로 */}
            {data.length > 0 && <EvaluationReportAsEvaluatorHeader data={data[0].length > data[1].length ? data[0] : data[1]}></EvaluationReportAsEvaluatorHeader>}
         </TableRow>
            {/* 내용 */}
            {data.length > 0 && <EvaluationReportAsEvaluatorRows data={data}></EvaluationReportAsEvaluatorRows>}
      </TableBody>
    </>
  )
}

export default EvaluationReportAsEvaluator