import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

export const TABLEICONS = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const INBODY_EMPLOYEENO = "00000000";

export const CONSTANT_TABLE_ARRAY = [
  { name: "평가 기간", value: "EvaluationTerm" },
  { name: "평가자 현황", value: "EvaluationGroup" },
  { name: "평가 상태", value: "EvaluationItem" },
  { name: "질문/답안", value: "Question" },
  { name: "권한", value: "Authorization" },
  { name: "만족도 조사 상태", value: "SurveyItem" },
  { name: "만족도 조사", value: "SurveyQuestion" },
];

export const CONSTANT_TABLE = {
  EvaluationTerm: "평가 기간",
  EvaluationGroup: "평가자 현황",
  EvaluationItem: "평가 상태",
  Question: "답안",
  Authorization: "권한",
  SurveyItem: "만족도 조사 상태",
  SurveyQuestion: "만족도 조사",
};

export const CONSTANT_TABLE_COLUMN: { [index: string]: { [name: string]: string } } = {
  EvaluationTerm: {
    Term: "평가 기간",
  },
  EvaluationGroup: {
    Status: "평가자 현황",
  },
  EvaluationItem: {
    Type: "평가자 관계",
    Status: "평가 상태",
    Order: "평가 유형 순서",
  },
  Question: {
    AnswerType: "답안 유형",
  },
  Authorization: {
    Auth: "권한",
  },
  SurveyItem: {
    Status: "상태",
  },
  SurveyQuestion: {
    SurveyAnswerType: "질문 유형",
  },
};
