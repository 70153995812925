import { Grid, TextField } from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Fragment, useEffect, useState } from 'react';
import { EmployeeApi, ErrorHandler } from '../../system/ApiService';
import { User } from '../../system/types/type';

interface SelectEmployeeProps {
  list?: User[];
  onChange: (data: User) => void;
}

function SelectEmployee({list, onChange}: SelectEmployeeProps) {
  const [empList, setEmpList] = useState<User[]>([]);

  useEffect(() => {
    if (list) {
      setEmpList(list)
    } else {
      EmployeeApi.GetEmployee()
      .then((res) => {
        setEmpList(res.data);
      })
      .catch((err) => {
        console.log(err);
        let msg = ErrorHandler(err);
        alert(msg);
      });
    }
  }, [list])

  const handleSelect = (event: React.ChangeEvent<{}>, value: User | null) => {
    if (value !== null) {
      onChange(value)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          options={empList}
          onChange={handleSelect}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => (
            <Fragment>
              {option.name}
              <span
                style={{ marginLeft: "20px", fontSize: "14px", color: "#888888" }}
              >{`\t ${option.employeeNo}, ${option.team}, ${option.jobPosition}`}</span>
            </Fragment>
          )}
          renderInput={(params) => <TextField {...params} label="사원 검색" />}
          style={{ width: "100%" }}
        ></Autocomplete>
      </Grid>
    </Grid>
  )
}


export default SelectEmployee