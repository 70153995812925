import React, { useCallback, useEffect, useState } from 'react'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import TitleDiv from '../../Common/TitleDiv'
import MaterialTable, { MTableToolbar } from 'material-table';
import { TABLEICONS } from '../../../system/Constants';
import { EvaluationItemViewModel } from '../../../system/types/type';
import { ErrorHandler, EvaluationApi } from '../../../system/ApiService';
import { LMATERIAL_TABLE } from '../../../system/Localization';
import SelectModal from './SelectModal';
import { useUserState } from '../../../system/context/UserContext';
import PrimaryButton from '../../Common/PrimaryButton';
import { TABLE_ACTION, TABLE_HEADER } from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  headTitleBar: {
    letterSpacing: "-0.5px",
    fontWeight: "bold",
  },
  button: {
    float: "right",
  },
  iconButton: {
    padding: "5px",
  },
  buttonGroup: {
    textAlign: "end",
  },
  test: {
    div: {
      color: "red"
    }
  }
}));

interface SelectTableProps {
  id: number;
}

function SelectTable({id: evaluationID}: SelectTableProps) {
  const classes = useStyles();
  const user = useUserState();
  const [data, setData] = useState<EvaluationItemViewModel[]>([]);
  const [selectModal, setSelectModal] = useState<boolean>(false);

  const openSelectModal = () => {
    if (evaluationID !== 0) {
      setSelectModal(true)
    } else {
      alert("평가를 선택해주세요.")
    }
  };
  
  const closeSelectModal = () => {
    setSelectModal(false)
    onLoad();
  };

  const onLoad = useCallback(() => {
    if (evaluationID !== 0) {
      EvaluationApi.GetEvaluationItemList(evaluationID, "evaluatee", user.employeeId, 0)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err,"err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    }
  }, [evaluationID, user.employeeId])

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title='평가자 선택'></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="h5" className={classes.headTitleBar}>
          본인을 평가할 수 있다고 생각하는 임직원을 선택해 주시기 바랍니다.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ minWidth: "1000px"}}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            { title: 'No.', field: 'id', cellStyle: { width: "5%" }, align: "center",
              render: rowData => {
                return (
                  <>{data.indexOf(rowData) + 1}</>
                )
              } 
            },
            { title: '파트', field: 'evaluatorParentTeam', cellStyle: { width: "15%" }, editable: 'never', align: "center", },
            { title: '팀', field: 'evaluatorTeam', cellStyle: { width: "15%" }, editable: 'never', align: "center", },
            { title: '성명', field: 'evaluatorName', cellStyle: { width: "10%" }, editable: 'never', align: "center", },
            { title: '협업 내용', field: 'collaboration', cellStyle: { width: "40%" }, },
          ]}
          data={data || []}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (newData.evaluatorName !== user.name) {
                  setTimeout(() => {
                    EvaluationApi.UpdateEvaluationItem(newData)
                    .then((res) => {
                      onLoad();
                    })
                  }, 100)
                } else {alert("본인 평가는 수정할 수 없습니다.")}
                resolve(newData);
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                if (oldData.evaluatorName !== user.name) {
                  setTimeout(() => {
                    EvaluationApi.DeleteEvaluationItem(oldData.id)
                    .then(() => {
                      onLoad();
                    })
                  }, 100)
                } else {alert("본인 평가는 삭제할 수 없습니다.")}
                resolve(data);
              }),
          }}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
            Toolbar: props => (
              <Grid container justifyContent='flex-end' alignItems='center' style={{backgroundColor: '#fafafa'}}>
                <Grid item>
                  <MTableToolbar {...props} />
                </Grid> 
                <Grid item>
                  <PrimaryButton onClick={openSelectModal}>추가</PrimaryButton>
                </Grid> 
              </Grid>
            )
          }}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            headerStyle: TABLE_HEADER,
            actionsCellStyle: TABLE_ACTION,
            padding: "dense",
          }}
          localization={LMATERIAL_TABLE}
        />
        <SelectModal
          id={evaluationID}
          type={"Evaluatee"}
          user={user}
          open={selectModal}
          onClose={closeSelectModal}
        ></SelectModal>
      </Grid>
    </Grid>
  )
}


export default SelectTable