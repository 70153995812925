import { useEffect, useState } from "react";
import { ErrorHandler, SurveyApi } from "../../../system/ApiService";
import { useUserState } from "../../../system/context/UserContext";
import { IsAdmin1 } from "../../../system/types/interface";
import { SurveyItem } from "../../../system/types/type";
import EvaluationReport from "./EvaluationReport";
import SurveyPage from "./SurveyPage";

interface ReportPageProps {
  id: number;
}

function ReportPage({ id: evaluationId }: ReportPageProps) {
  const user = useUserState();
  const [data, setData] = useState<SurveyItem>();
  const [surveyModal, setSurveyModal] = useState<boolean>(false);

  const closeSurveyModal = () => {
    setSurveyModal(false);
  };

  useEffect(() => {
    SurveyApi.GetSurveyItemByEmployeeId(evaluationId, user.employeeId)
      .then((res) => {
        setData(res.data);
        if (res.data.status === 0) {
          setSurveyModal(true);
        } else {
          setSurveyModal(false);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [evaluationId, user.employeeId, surveyModal]);

  if (data?.status === 0) {
    return (
      <>
        <EvaluationReport id={evaluationId}></EvaluationReport>;
        {!IsAdmin1(user.role) && <SurveyPage data={data} open={surveyModal} onClose={closeSurveyModal}></SurveyPage>}
      </>
    );
  } else if (data?.status === 1 || data?.status === 2) {
    return <EvaluationReport id={evaluationId}></EvaluationReport>;
  } else {
    return <>알 수 없는 오류입니다. 인프라개발파트에 문의해주세요.</>;
  }
}

export default ReportPage;
