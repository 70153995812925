import FeedbackTable from "../../components/Result/Feedback/FeedbackTable";

function FeedbackMain() {
  return (
    <>
      <FeedbackTable></FeedbackTable>
    </>
  );
}
export default FeedbackMain;
