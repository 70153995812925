import { Grid, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { useCallback, useEffect, useState, useMemo } from "react";
import { TABLE_ACTION, TABLE_HEADER } from "../../../styles/theme";
import { EmployeeApi, ErrorHandler, SurveyApi } from "../../../system/ApiService";
import { TABLEICONS } from "../../../system/Constants";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { InitLookup } from "../../../system/types/initObject";
import { Lookup } from "../../../system/types/interface";
import { SurveyItemViewModel } from "../../../system/types/type";
import SelectEvaluation from "../../Common/SelectEvaluation";
import TitleDiv from "../../Common/TitleDiv";

function ResultTable() {
  const [data, setData] = useState<SurveyItemViewModel[]>([]);
  const [evaluationID, setEvaluationID] = useState<number>(0);
  const [lookup, setLookup] = useState<Lookup>(InitLookup);
  const status = { 대기: "대기", 완료: "완료" };

  const onChange = (id: number) => {
    setEvaluationID(id);
  };

  useMemo(() => {
    EmployeeApi.GetLookup("all").then((res) => {
      var parentTeam: string[] = res.data.parentTeam;
      var parentTeamLookups: Object = parentTeam.reduce((x, y) => ({ ...x, [y]: y }), {});
      var team: string[] = res.data.team;
      var teamLookups: Object = team.reduce((x, y) => ({ ...x, [y]: y }), {});
      var temp: Lookup = InitLookup;
      temp = { ...temp, parentTeam: parentTeamLookups, team: teamLookups };
      setLookup(temp);
    });
  }, []);

  const onLoad = useCallback(() => {
    if (evaluationID !== 0) {
      SurveyApi.GetSurveyItemList(evaluationID)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  }, [evaluationID]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="평가 만족도 조사 결과 조회"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <SelectEvaluation onChange={onChange}></SelectEvaluation>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            {
              title: "No.",
              field: "no",
              cellStyle: { width: "5%" },
              align: "center",
              render: (rowData) => {
                return <>{data.indexOf(rowData) + 1}</>;
              },
            },
            {
              title: "파트",
              field: "evaluatorParentTeam",
              lookup: lookup.parentTeam,
              cellStyle: { width: "10%" },
              align: "center",
            },
            { title: "팀", field: "evaluatorTeam", lookup: lookup.team, cellStyle: { width: "10%" }, align: "center" },
            { title: "성명", field: "evaluatorName", cellStyle: { width: "8%" }, align: "center" },
            { title: "상태", field: "statusText", lookup: status, cellStyle: { width: "8%" }, align: "center" },
            { title: "1.문항test", field: "d", cellStyle: { width: "8%" } },
            { title: "2.문항test", field: "e", cellStyle: { width: "8%" } },
            { title: "3.문항test", field: "f", cellStyle: { width: "8%" } },
            { title: "4.문항test", field: "d", cellStyle: { width: "8%" } },
            { title: "5.문항test", field: "f", cellStyle: { width: "8%" } },
          ]}
          data={data || []}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div style={{ backgroundColor: "#fafafa" }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            actionsCellStyle: TABLE_ACTION,
            headerStyle: TABLE_HEADER,
            padding: "dense",
            pageSize: 20,
            pageSizeOptions: [5, 10, 20, data.length],
            exportButton: true,
            filtering: true,
            // fixedColumns: {
            //   left: 5,
            //   right: 0,
            // },
          }}
          localization={LMATERIAL_TABLE}
        />
      </Grid>
    </Grid>
  );
}

export default ResultTable;
