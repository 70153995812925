import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { useState, useEffect } from "react";
import { Answer, Question } from "../../system/types/type";

interface MultipleChoiceProps {
  data: Question,
  onChange: (answer: Answer) => void,
}

function MultipleChoice({data, onChange}: MultipleChoiceProps) {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    setValue(data.answer.choice);
  }, [data])
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value));
    onChange({...data.answer, choice: parseInt(event.target.value)})
  };

  return (
    <>
      <Grid container justifyContent="center">
        <FormControl component="fieldset">
          <RadioGroup row aria-label="choice" name="choice" value={value} onChange={handleChange}>
            {data.choices.map((x, index) => (
              <FormControlLabel key={index} value={x.order} control={<Radio />} label={x.text}/>
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  )
}

export default MultipleChoice;