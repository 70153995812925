import React, { useEffect } from "react";
import { EvaluationAnswer } from "../../../../system/types/type";
import { TableCell } from "@material-ui/core";
import { PRIMARY } from "../../../../styles/theme";

interface EvaluationReportAsEvaluatorHeaderProps {
   data: EvaluationAnswer[];
}

function EvaluationReportAsEvaluatorHeader({ data }: EvaluationReportAsEvaluatorHeaderProps) {
   useEffect(() => {}, [data]);
   return (
      <>
         {/* 구분 */}
         <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "10%" }}>구분</TableCell>
         {/* No */}
         {data !== undefined &&
            data.map((x, index) => (
               <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "10%" }}>{index}</TableCell>
            ))}
         {/* 합계 */}
         <TableCell
            rowSpan={2}
            style={{ backgroundColor: "Yellow", color: "black", textAlign: "center", width: "15%", fontWeight: "bold", whiteSpace: "pre-line" }}
         >
            합계 <br></br>
            {"(100점"}
            <br></br>
            {"만점으로변환)"}
         </TableCell>
      </>
   );
}

export default EvaluationReportAsEvaluatorHeader;
