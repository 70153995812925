import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { PRIMARY } from "../../../styles/theme";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { usePageState } from "../../../system/context/PageContext";
import { useUserState } from "../../../system/context/UserContext";
import { InitFeedbackViewModel } from "../../../system/types/initObject";
import { EvaluationFeedbackViewModel } from "../../../system/types/type";
import HtmlContent from "../../Common/HtmlContent";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "650px",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: "1rem",
  },
  tableBody: {
    minHeight: "400px",
  },
  textArea: {
    width: "100%",
    minHeight: "150px",
    padding: "10px",
    border: "none",
    outline: "none",
  },
}));

interface FeedbackTemplateProps {
  evaluationId: number;
}

function FeedbackTemplate({ evaluationId }: FeedbackTemplateProps) {
  const classes = useStyles();
  const user = useUserState();
  const page = usePageState();
  const [add, setAdd] = useState<boolean>(false);
  const [data, setData] = useState<EvaluationFeedbackViewModel>(InitFeedbackViewModel);
  const [init, setInit] = useState<string>("");

  const onLoad = useCallback(() => {
    EvaluationApi.GetMyFeedback(evaluationId, user.employeeId)
      .then((res) => {
        if (res.data.length > 0) {
          setData(res.data[0]);
          setInit(res.data[0].context);
        } else {
          setData({
            ...InitFeedbackViewModel,
            evaluationId: evaluationId,
            employeeTeamId: user.teamId,
            employeeId: user.employeeId,
          });
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationId, user.employeeId]);

  const onUpdate = () => {
    setAdd(true);
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const onSave = () => {
    if (data.id !== 0) {
      EvaluationApi.UpdateFeedback(data)
        .then(() => {
          setAdd(false);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else if (data.id === 0) {
      EvaluationApi.InsertFeedback(data)
        .then(() => {
          setAdd(false);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  };

  const onDelete = () => {
    if (window.confirm("정말 삭제하시겠습니까? 삭제 후 복구되지 않으며 기간 내 재작성이 가능합니다.")) {
      EvaluationApi.DeleteFeedback(data.id)
        .then(() => {
          onLoad();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  };

  const onCancel = () => {
    setAdd(false);
    setData({ ...data, context: init });
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      {evaluationId === page[7].evaluationId && page[7].isOpen ? (
        <Grid container item xs={12} justifyContent="flex-end">
          {add ? (
            <>
              <PrimaryButton onClick={onSave}>저장</PrimaryButton>
              <SecondaryButton onClick={onCancel}>취소</SecondaryButton>
            </>
          ) : (
            <>
              <PrimaryButton onClick={onUpdate}>작성</PrimaryButton>
              <SecondaryButton disabled={data.context === ""} onClick={onDelete}>
                삭제
              </SecondaryButton>
            </>
          )}
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: PRIMARY, color: "white" }} className={classes.tableHeader}>
                작성란
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableBody}>
                {add ? (
                  <textarea
                    className={classes.textArea}
                    placeholder="내용을 입력해주세요."
                    name="context"
                    value={data.context}
                    onChange={onChange}
                  />
                ) : (
                  <Typography className={classes.textArea}>{data.context}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.textArea}>
                <HtmlContent data={data.comment ? data.comment : "작성된 답변이 없습니다."}></HtmlContent>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}

export default FeedbackTemplate;
