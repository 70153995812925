import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { Answer, EvaluationItemViewModel, Question } from "../../../system/types/type";
import MultipleChoice from "../../Common/MultipleChoice";
import ShortAnswer from "../../Common/ShortAnswer";

const useStyles = makeStyles((theme) => ({
  qna: {
    border: "0.5px #d0d0d0 solid",
    marginTop: "10px",
  },
  question: {
    padding: "20px",
    backgroundColor: "#EDEDED",
    display: "table",
    borderBottom: "0.5px #d0d0d0 solid",
  }, 
  category: {
    backgroundColor: "#fff",
    textAlign: "center",
    verticalAlign: "middle",
    display: "table-cell",
    width: "150px",
  },
  choice: {
    marginTop: "10px",
    padding: "10px"
  }, 
  answer: {
    margin: 0,
    padding: 0,
  }
}));

interface EvaluateAnswerProps {
  data: EvaluationItemViewModel,
  kind: number,
  onAnswerList: (data: Answer[]) => void,
}

function EvaluateAnswer({data, kind, onAnswerList}: EvaluateAnswerProps) {
  const classes = useStyles();
  const [questionList, setQuestionList] = useState<Question[]>([]);
  const [answerList, setAnswerList] = useState<Answer[]>([]);

  const onLoad = useCallback(() => {
    if (data.evaluationId) {
      EvaluationApi.GetQuestionList(data.evaluationId, data.id, kind)
      .then((res) => {
        setQuestionList(res.data);
        let temp: Answer[] = [];
        res.data.map((x: Question) => {
          return temp.push(x.answer)
        })
        onAnswerList(temp);
      }) 
      .catch((err) => {
        console.log(err, "err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    } else {alert("선택된 평가가 없습니다.")}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.evaluationId, kind])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onAnswer = (answer: Answer) => {
    const clonedData = [...answerList];
    let index = answerList.findIndex(x => x.questionId === answer.questionId)
    if (index === -1) {
      clonedData.push(answer)
    } else {
      clonedData[index] = answer
    }
    setAnswerList(clonedData);
    onAnswerList(clonedData);
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={8} style={{ minWidth: "1000px"}}>
        {questionList.map((x, index) => (
          <Box key={index} className={classes.qna}>

            {/* question */}
            <Grid container className={classes.question}>
              {x.answerType === 1 || x.answerType === 4 ? 
                <>
                  <Grid item xs={11}>
                    <Typography>{index+1} {x.text}</Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.category}>
                    <Typography>{x.category}</Typography>
                  </Grid>
                </> : 
                <Grid item xs={12}>
                  <Typography>{index+1} {x.text}</Typography>
                </Grid>
              }
            </Grid>

            {/* answer */}
            {x.answerType === 1 || x.answerType === 4 ? 
              <Grid className={classes.choice}>
                <MultipleChoice data={x} onChange={onAnswer}></MultipleChoice> 
              </Grid> : null
            }
            {x.answerType !== 1 ? 
              <Grid className={classes.answer}>
                <ShortAnswer data={x} onChange={onAnswer}></ShortAnswer>
              </Grid> : null
            }
          </Box>
        ))}
        </Grid>
    </Grid>
  );
}

export default EvaluateAnswer;
