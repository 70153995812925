import React, { ReactElement, useEffect, useState } from "react";
import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { DeptTreeView, EvaluateType, EvaluationItem, EvaluationItemViewModel, User } from "../../../system/types/type";
import { EmployeeApi, ErrorHandler } from "../../../system/ApiService";
import { InitDeptTreeView, InitEvaluationItemViewModel } from "../../../system/types/initObject";
import MaterialTable from "material-table";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { TABLEICONS } from "../../../system/Constants";
import { Table } from "react-bootstrap";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: "20px",
  },
  head: {
    backgroundColor: "#5b67704a",
    textAlign: "center",
  },
  body: {
    textAlign: "center",
  },
  td: {
    verticalAlign: "middle",
  }
}));

interface SelectModalTemplateProps {
  data: EvaluationItem;
  type: EvaluateType;
  onChangeText: (data: any) => void;
  onChange: (data: EvaluationItem) => void;
}

function SelectModalTemplate({data, type, onChangeText, onChange}: SelectModalTemplateProps): ReactElement {
  const classes = useStyles();
  const [dept, setDept] = useState<DeptTreeView>(InitDeptTreeView);
  const [empList, setEmpList] = useState<User[]>([]);
  const [filteredList, setFilteredList] = useState<User[]>([]);
  const [show, setShow] = useState<EvaluationItemViewModel>(InitEvaluationItemViewModel);

  const field: Object = { dataSource: [dept], id: "id", text: "name", child: "subChild" };
  const style: string = "accordiontree";
  var treeObj: TreeViewComponent;

  useEffect(() => {
    // 현재연도 조직도 불러오기
    EmployeeApi.GetDeptTreeView(new Date().getFullYear())
      .then((res) => {
        if (res !== undefined) {
          res.data.subChild?.forEach((x) => {
            if (x.name !== "지사") x.expanded = true;
          });
          setDept({ ...res.data, expanded: true });
        }
      })
      .catch((err) => {
        console.log(err);
        let msg = ErrorHandler(err);
        alert(msg);
      });
    // 전체사원 리스트 조회
    EmployeeApi.GetEmployee()
      .then((res) => {
        setEmpList(res.data);
        setFilteredList(res.data);
      })
      .catch((err) => {
        console.log(err);
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, []);

  // 노드 클릭 이벤트
  const nodeClicked = (args: any): void => {
    let checkedNode: any = [args.node];
    if (args.event.target.classList.contains("e-fullrow") || args.event.key === "Enter") {
      let getNodeDetails: any = treeObj.getNode(args.node);
      if (getNodeDetails.isChecked === "true") {
        treeObj.uncheckAll(checkedNode);
      } else {
        treeObj.checkAll(checkedNode);
      }
    }

    let list: User[] = [];
    treeObj.checkedNodes.forEach((x) => {
      empList.forEach((emp) => {
        if (emp.teamId === x) {
          list.push(emp);
        }
      });
    });
    setFilteredList(list);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={3} style={{ padding: "10px" }}>
          <TreeViewComponent
            fields={field}
            cssClass={style} 
            showCheckBox={true}
            ref={(treeview) => (treeObj = treeview as TreeViewComponent)}
            nodeClicked={nodeClicked}
          ></TreeViewComponent>
        </Grid>
        <Grid item xs={9}>
          <MaterialTable
            title=""
            icons={TABLEICONS}
            onRowClick={(event, rowData) => {
              if (rowData) {
                if (type === "Evaluatee") {
                  setShow({
                    ...show,
                    evaluatorName: rowData.name,
                    evaluatorTeam: rowData.team,
                    evaluatorParentTeam: rowData.parentTeam,
                  })
                  onChange({
                    ...data,
                    evaluatorId: rowData.employeeId,
                    evaluatorTeamId: rowData.teamId,
                  })
                } else if (type === "Evaluator") {
                  setShow({
                    ...show,
                    evaluateeName: rowData.name,
                    evaluateeTeam: rowData.team,
                    evaluateeParentTeam: rowData.parentTeam,
                  })
                  onChange({
                    ...data,
                    evaluateeId: rowData.employeeId,
                    evaluateeTeamId: rowData.teamId,
                  })
                }
              }
            }}
            columns={[
              { title: '사업장', field: 'workplace', cellStyle: { width: 300 }, },
              { title: '파트', field: 'parentTeam', cellStyle: { width: 300 }, },
              { title: '팀', field: 'team', cellStyle: { width: 300 }, },
              { title: '이름', field: 'name', cellStyle: { width: 100 }, },
              { title: '직위', field: 'jobPosition', cellStyle: { width: 300 }, },
              { title: '직책', field: 'jobTitle', cellStyle: { width: 300 }, },
            ]}
            data={filteredList || []}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
            }}
            localization={LMATERIAL_TABLE}
          />
          <Grid container item xs={12}>
            <Table className={classes.select}>
              <thead className={classes.head}>
                <tr>
                  <td>파트</td>
                  <td>팀</td>
                  <td>이름</td>
                  <td>협업 내용</td>
                </tr>
              </thead>
              {show.evaluatorName !== "" || show.evaluateeName !== "" ? 
                <>
                  {type === "Evaluatee" ?
                    <tbody className={classes.body}>
                      <tr>
                        <td className={classes.td}><Typography>{show.evaluatorParentTeam}</Typography></td>
                        <td className={classes.td}><Typography>{show.evaluatorTeam}</Typography></td>
                        <td className={classes.td}><Typography>{show.evaluatorName}</Typography></td>
                        <td className={classes.td} style={{ width: "50%" }}>
                          <TextField
                            name="collaboration"
                            placeholder="협업 내용을 작성해주세요."
                            value={data.collaboration}
                            multiline
                            fullWidth
                            onChange={onChangeText}
                            ></TextField>
                        </td>
                      </tr>
                    </tbody> : 
                    <tbody className={classes.body}>
                      <tr>
                        <td className={classes.td}><Typography>{show.evaluateeParentTeam}</Typography></td>
                        <td className={classes.td}><Typography>{show.evaluateeTeam}</Typography></td>
                        <td className={classes.td}><Typography>{show.evaluateeName}</Typography></td>
                        <td className={classes.td} style={{ width: "50%" }}>
                          <TextField
                            name="collaboration"
                            placeholder="협업 내용을 작성해주세요."
                            value={data.collaboration}
                            multiline
                            fullWidth
                            onChange={onChangeText}
                          ></TextField>
                        </td>
                      </tr>
                    </tbody>
                  }
                </>
              : <tbody>선택한 임직원이 없습니다.</tbody>}
            </Table>
          </Grid> 
        </Grid>
      </Grid>
    </div>
  );
}

export default SelectModalTemplate;
