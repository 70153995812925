import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme } from "@material-ui/core";
import { Choice, Constant, Question } from "../../../system/types/type";
import { ConstantApi, ErrorHandler } from "../../../system/ApiService";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      formControl: {
         minWidth: 240,
      },
   })
);

interface AnswerModalTemplateProps {
   data: Question;
   choice: Choice[];
   onChange: (event: any) => void;
   onChangeChoice: (event: any, index: number) => void;
}

function AnswerModalTemplate({ data, choice, onChange, onChangeChoice }: AnswerModalTemplateProps): ReactElement {
   const classes = useStyles();
   const [itemType, setItemType] = useState<Constant[]>([]);
   const [answer, setAnswer] = useState<Constant[]>([]);
   const [show, setShow] = useState<boolean>(false);

   useMemo(() => {
      ConstantApi.GetConstantList("EvaluationItem", "Type")
         .then((res) => {
            setItemType(res.data);
         })
         .catch((err) => {
            console.log(err, "EvaluationItem Type");
            let msg = ErrorHandler(err);
            alert(msg);
         });
      ConstantApi.GetConstantList("Question", "AnswerType")
         .then((res) => {
            setAnswer(res.data);
         })
         .catch((err) => {
            console.log(err, "answer");
            let msg = ErrorHandler(err);
            alert(msg);
         });
   }, []);

   useEffect(() => {
      if (data.answerType === 1 || data.answerType === 4) {
         setShow(true);
      }
   }, [data.answerType]);

   const onChangeType = (event: any) => {
      onChange(event);
      if (event.target.value === 1 || event.target.value === 4) {
         setShow(true);
      } else {
         setShow(false);
      }
   };

   return (
      <Grid container spacing={2}>
         <Grid item xs={6}>
            <FormControl className={classes.formControl}>
               <InputLabel id="구분">구분</InputLabel>
               <Select id="구분" name="evaluationItemType" value={data.evaluationItemType} onChange={onChange} style={{ width: "100%" }}>
                  {itemType.map((x) => {
                     return (
                        <MenuItem key={x.id} value={x.key}>
                           {x.value}
                        </MenuItem>
                     );
                  })}
               </Select>
            </FormControl>
         </Grid>
         <Grid item xs={6}>
            <TextField
               id="category"
               name="category"
               label="평가유형"
               value={data.category}
               onChange={onChange}
               multiline
               style={{ width: "100%" }}
            ></TextField>
         </Grid>
         <Grid item xs={12}>
            <TextField id="text" name="text" label="평가문항" value={data.text} onChange={onChange} multiline style={{ width: "100%" }}></TextField>
         </Grid>
         <Grid item xs={6}>
            <TextField
               id="order"
               name="order"
               label="문항순서"
               type="number"
               value={data.order}
               onChange={onChange}
               style={{ width: "100%" }}
            ></TextField>
         </Grid>
         <Grid item xs={6}>
            <FormControl className={classes.formControl}>
               <InputLabel id="answerType">답안유형</InputLabel>
               <Select id="answerType" name="answerType" value={data.answerType} onChange={onChangeType} style={{ width: "100%" }}>
                  {answer.map((x) => {
                     return (
                        <MenuItem key={x.id} value={x.key}>
                           {x.value}
                        </MenuItem>
                     );
                  })}
               </Select>
            </FormControl>
         </Grid>
         {show && (
            <>
               {choice.map((x, index) => (
                  <Grid item xs={2} key={index}>
                     <TextField
                        name={`${index}`}
                        label={`${index + 1}`}
                        value={x.text}
                        onChange={(e) => onChangeChoice(e, index)}
                        style={{ width: "100%" }}
                     ></TextField>
                  </Grid>
               ))}
            </>
         )}
      </Grid>
   );
}

export default AnswerModalTemplate;
