import React, { useState, useEffect, useCallback } from "react";
import { EvaluationReportType, EvaluationStatistics, User } from "../../../../system/types/type";
import { Grid, Typography } from "@material-ui/core";
import { ErrorHandler, EvaluationApi, ReportApi } from "../../../../system/ApiService";
import { LMATERIAL_TABLE } from "../../../../system/Localization";
import { TABLE_ACTION, TABLE_HEADER } from "../../../../styles/theme";
import MaterialTable, { MTableToolbar } from "material-table";
import { TABLEICONS } from "../../../../system/Constants";

interface PersonalMultipleChoiceReportTableProps {
   evaluationId: number;
   employee: User;
   type: EvaluationReportType;
   index: number;
}

function PersonalMultipleChoiceReportTable({ evaluationId, employee, type, index }: PersonalMultipleChoiceReportTableProps) {
   const [data, setData] = useState<any[]>([]);
   const [columns, setColumns] = useState<{}[]>([]);

   const setColumnsByData = useCallback((data: any) => {
      // columns
      var cols = [];
      cols.push({
         title: "구분",
         field: "type",
         cellStyle: { width: `${100 / (data.length + 4) * 2}%`, textAlign: "center" },
         headerStyle: { textAlign: "center" },
         align: "center",
      });
      let idx = 1;
      for (var z of data) {
         cols.push({
            title: idx + "",
            field: z.category,
            cellStyle: { width: `${100 / (data.length + 4)}%`, textAlign: "center" },
            headerStyle: { textAlign: "center" },
         });
         idx++;
      }
      cols.push({
         title: "-",
         field: "sum",
         cellStyle: { width: `${100 / (data.length + 4)}%`, textAlign: "center", backgroundColor: "#FCBCC0", fontWeight: "bold" },
         headerStyle: { textAlign: "center", color: "black" },
      });
      cols.push({
         title: "-",
         field: "employeeCount",
         cellStyle: { width: `${100 / (data.length + 4)}%`, textAlign: "center", fontWeight: "bold" },
         headerStyle: { textAlign: "center", color: "black" },
      });
      setColumns(cols);
   }, []);

   useEffect(() => {
      let temp: any[] = [];
      let columnCount: number = 0;
      EvaluationApi.GetQuestionList(evaluationId, 0, type.type)
         .then((res) => {
            setColumnsByData(res.data);
            // 헤더 row input
            let categoryObj: any;
            categoryObj = { ...categoryObj, type: "평가지표", sum: "합계\r\n(100점 변환)", evaluationCount: "평가수", employeeCount: "인원수" };
            for (var x of res.data) {
               categoryObj = { ...categoryObj, [`${x.category}`]: x.category };
               columnCount++;
            }
            temp.push(categoryObj);
            
            // 평가 결과 input
            ReportApi.GetMultiChoiceReport(evaluationId, employee.employeeId, type.type).then((res)=>{
               for(var kind of res.data.kindList){
                  var searchKind = kind;
                  if(kind.includes("직위"))
                     searchKind = employee.jobPosition;
                  else if(kind.includes("직책"))
                     searchKind = employee.jobTitle;
                  // eslint-disable-next-line no-loop-func
                  let list = res.data.statisticsList.filter((x: EvaluationStatistics)=> x.kind === searchKind);
                  let scoreObj: any = { type: kind, sum: 0};
                  for (var item of list) {
                     
                     if(item.score > 10){
                        scoreObj = { ...scoreObj, [`${item.category}`]: "" };
                     }
                     else if((item.score !== 0 && kind !=="타인평가-본인평가") || kind ==="타인평가-본인평가")
                        scoreObj = { ...scoreObj, [`${item.category}`]: item.score, sum: scoreObj.sum + item.score };
                  }
                  if (scoreObj.sum !== 0 || kind ==="타인평가-본인평가")
                     temp.push({
                        ...scoreObj,
                        sum: ((scoreObj.sum * 100) / (columnCount * 10)).toFixed(1),
                        employeeCount: list[0] ? list[0].employeeCount : "-",
                     });
               }
               setData(temp);

            }).catch((error) => {
               console.log(error);
               let msg = ErrorHandler(error);
               alert(msg);
            });
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [evaluationId, type, employee, setColumnsByData]);

   return (
      <Grid container style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "30px" }}>
         <Grid item xs={12}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>{`2-${index + 1}.${type.value}`}</Typography>
         </Grid>
         <Grid item xs={index === 0 ? 12 : 9}>
            <MaterialTable
               title=""
               icons={TABLEICONS}
               style={{ marginTop: "10px", minWidth: `${index === 0 ? 1000 : 800}px` }}
               columns={columns}
               data={data}
               components={{
                  Toolbar: (props) => (
                     <div style={{ backgroundColor: "#fafafa" }}>
                        <MTableToolbar {...props} />
                     </div>
                  ),
               }}
               options={{
                  actionsColumnIndex: -1,
                  actionsCellStyle: TABLE_ACTION,
                  headerStyle: TABLE_HEADER,
                  padding: "dense",
                  exportButton: true,
                  toolbar: false,
                  paging: false,
                  sorting: false,
                  rowStyle: (rowData) => {
                     if (rowData.type === "평가지표") {
                        return { backgroundColor: "#DDDDDD", whiteSpace: "pre-line" };
                     } else if (rowData.type === "타인평가") {
                        return {  };
                     } else if (rowData.type === "파트 내 리더" || rowData.type === "파트 내 동료" || rowData.type === "파트 외 동료") {
                        return {  };
                     } else if (rowData.type === "본인평가") {
                        return { backgroundColor: "#FFFFFF" };
                     } else if (rowData.type === "전체 평균") {
                        return { backgroundColor: "#FFDEE0" };
                     } else if (rowData.type === "동일 직위 평균" || rowData.type === "동일 직책 평균") {
                        return { backgroundColor: "#DEF0FF" };
                     } else {
                        return {};
                     }
                  },
               }}
               localization={LMATERIAL_TABLE}
            />
         </Grid>
         {index > 0 && <Grid item xs={3}></Grid>}
      </Grid>
   );
}

export default PersonalMultipleChoiceReportTable;
