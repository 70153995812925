import EvaluateTable from "../../components/Progress/Evaluate/EvaluateTable"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { usePageState } from "../../system/context/PageContext";

function EvaluateMain() {
  const page = usePageState();
  const history = useHistory();

  useEffect(() => {
    if (!page[4].isOpen) {
      alert("평가 기간이 아닙니다.")
      history.push("/")
    }
  },[history, page])

  if (page[4].isOpen) {
    return (
      <>
        <EvaluateTable id={page[4].evaluationId}></EvaluateTable>
      </>
    )
  } else return <></>;
}

export default EvaluateMain
