export interface AuthorityUser extends User {
   roles: string[];
 }
export interface User {
   employeeId: string;
   employeeNo: string;
   name: string;
   gwName: string;
   gwMail: string;
   jobTitle: string;
   jobPosition: string;
   workplace: string;
   teamId: number;
   teamName: string;
   parentTeamId: number;
   parentTeamName: string;
   positionCode: string;
   role: string;
 }

 export interface Lookup{
  workplace: {};
  parentTeam: {};
  team:{};
  jobPosition: {};
  jobTitle:{};
  employmentForm:{};
 }

 export function IsAdminInRoles(roles: string[]){
  return roles.includes("Admin1") || roles.includes("Admin2");
 }
export function IsAdmin(role: string) {
  return role.includes("Admin1") || role.includes("Admin2");
}
export function IsAdmin1(role: string) {
  return role.includes("Admin1")
}
export function IsAdmin2(role: string) {
  return role.includes("Admin2")
}
export function IsUser(role: string) {
  return role.includes("User")
}