import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ErrorHandler, EvaluationApi, ReportApi } from "../../../../system/ApiService";
import { Evaluation, EvaluationStatistics, SelectItem } from "../../../../system/types/type";
import MaterialTable, { MTableToolbar } from "material-table";
import { TABLEICONS } from "../../../../system/Constants";
import { TABLE_ACTION, TABLE_HEADER } from "../../../../styles/theme";
import { LMATERIAL_TABLE } from "../../../../system/Localization";

interface EvaluationStatisticsTableProps {
   evaluation: Evaluation;
   evaluationItemType: number;
   evaluationItemTypeName: string;
   index: number;
   kinds: SelectItem[];
   columnName: string;
}

function EvaluationStatisticsTable({ evaluation, evaluationItemType, evaluationItemTypeName, index, kinds, columnName }: EvaluationStatisticsTableProps) {
   const [data, setData] = useState<any[]>([]);
   const [columns, setColumns] = useState<{}[]>([]);
   useEffect(() => {
      let temp: any[] = [];
      let columnCount: number = 0;
      // 헤더 조회
      EvaluationApi.GetQuestionList(evaluation.id, 0, evaluationItemType)
         .then((res) => {
            setColumnsByData(res.data);
            // 헤더 row input
            let categoryObj: any;
            categoryObj = { ...categoryObj, type: "평가지표", sum: "합계\r\n(100점 변환)", evaluationCount: "평가수", employeeCount: "인원수" };
            for (var x of res.data) {
               categoryObj = { ...categoryObj, [`${x.category}`]: x.category };
               columnCount++;
            }
            temp.push(categoryObj);

            // 직책별 평가 결과 조회
            ReportApi.GetEvaluationStatistics(evaluation.id, columnName, evaluationItemType)
               .then((res) => {
                  // 직책별 평균 점수 답을 row
                  for (var kind of kinds) {
                     let scoreObj: any = { type: kind.text, sum: 0 };
                     // eslint-disable-next-line no-loop-func
                     let list = res.data.filter((x: EvaluationStatistics) => x.kind === kind.text);
                     for (var item of list) {
                        scoreObj = { ...scoreObj, [`${item.category}`]: item.score, sum: scoreObj.sum + item.score };
                     }
                     if (scoreObj.sum !== 0)
                        temp.push({
                           ...scoreObj,
                           sum: ((scoreObj.sum * 100) / (columnCount * 10)).toFixed(1),
                           evaluationCount: list[0].evaluationCount,
                           employeeCount: list[0].employeeCount,
                        });
                  }
                  setData(temp);
               })
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [evaluation, evaluationItemType, kinds, columnName]);

   const setColumnsByData = (data: any) => {
      // columns
      var cols = [];
      cols.push({
         title: "구분",
         field: "type",
         cellStyle: { width: `${100 / (data.length + 4)}%`, textAlign: "center", fontWeight: "bold" },
         headerStyle: { textAlign: "center" },
         align: "center",
      });
      let idx = 1;
      for (var z of data) {
         cols.push({
            title: idx + "",
            field: z.category,
            cellStyle: { width: `${100 / (data.length + 4)}%`, textAlign: "center"},
            headerStyle: { textAlign: "center" },
         });
         idx++;
      }
      cols.push({
         title: "-",
         field: "sum",
         cellStyle: { width: `${100 / (data.length + 4)}%`, textAlign: "center", backgroundColor: "#FCBCC0", fontWeight: "bold" },
         headerStyle: { textAlign: "center", color: "black" },
      });
      cols.push({
         title: "-",
         field: "evaluationCount",
         cellStyle: { width: `${100 / (data.length + 4)}%`, textAlign: "center", fontWeight: "bold" },
         headerStyle: { textAlign: "center", color: "black" },
      });
      cols.push({
         title: "-",
         field: "employeeCount",
         cellStyle: { width: `${100 / (data.length + 4)}%`, textAlign: "center", fontWeight: "bold" },
         headerStyle: { textAlign: "center", color: "black" },
      });
      setColumns(cols);
   };

   return (
      <Grid container style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "30px" }}>
         <Grid item xs={12}>
            <Typography variant="h6">{`${index + 1}. ${evaluationItemTypeName}`}</Typography>
         </Grid>
         <Grid item xs={columns.length > 10 ? 12 : 8}>
            <MaterialTable
               title=""
               icons={TABLEICONS}
               style={{ marginTop: "10px", minWidth: `${columns.length > 10 ? 1280 : 1024}px` }}
               columns={columns}
               data={data}
               components={{
                  Toolbar: (props) => (
                     <div style={{ backgroundColor: "#fafafa" }}>
                        <MTableToolbar {...props} />
                     </div>
                  ),
               }}
               options={{
                  actionsColumnIndex: -1,
                  actionsCellStyle: TABLE_ACTION,
                  headerStyle: TABLE_HEADER,
                  padding: "dense",
                  exportButton: true,
                  toolbar: false,
                  paging: false,
                  sorting: false,
                  rowStyle: (rowData) => {
                     if (rowData.type === "평가지표") {
                        return { backgroundColor: "#DDDDDD", fontWeight: "bold", whiteSpace: "pre-line"  };
                     } else if (rowData.type === "전체 평균") {
                        return { backgroundColor: "#BFEEB5" };
                     } else {
                        return {};
                     }
                  },
               }}
               localization={LMATERIAL_TABLE}
            />
         </Grid>
         {columns.length > 10 && <Grid item xs={4}></Grid>}
      </Grid>
   );
}

export default EvaluationStatisticsTable;
