import { Grid, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { useCallback, useEffect, useState } from "react";
import { TABLE_ACTION, TABLE_HEADER } from "../../../styles/theme";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { TABLEICONS } from "../../../system/Constants";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { Evaluation } from "../../../system/types/type";
import TitleDiv from "../../Common/TitleDiv";
import FeedbackTemplate from "./FeedbackTemplate";

function FeedbackTable() {
  const [id, setId] = useState<number>();
  const [data, setData] = useState<Evaluation[]>([]);

  const onClickRow = (id: number) => {
    setId(id);
  };

  const onLoad = useCallback(() => {
    EvaluationApi.GetEvaluationList()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="피드백 요청"></TitleDiv>
      </Grid>
      <Grid item xs={8} style={{ minWidth: "1000px" }}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            {
              title: "No.",
              field: "",
              cellStyle: { width: "10%" },
              align: "center",
              render: (rowData) => {
                return <>{data.indexOf(rowData) + 1}</>;
              },
            },
            {
              title: "년도",
              field: "year",
              cellStyle: { width: "20%" },
              align: "center",
            },
            {
              title: "분기",
              field: "half",
              cellStyle: { width: "20%" },
              align: "center",
            },
            {
              title: "평가명",
              field: "title",
              cellStyle: { width: "20%" },
              align: "center",
            },
          ]}
          data={data || []}
          onRowClick={(event, rowData) => {
            if (rowData) {
              onClickRow(rowData.id);
            }
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div style={{ backgroundColor: "#fafafa" }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          options={{
            actionsCellStyle: TABLE_ACTION,
            headerStyle: TABLE_HEADER,
            padding: "dense",
            pageSize: 5,
          }}
          localization={LMATERIAL_TABLE}
        />
      </Grid>
      {id && (
        <Grid item xs={8} style={{ minWidth: "1000px" }}>
          <FeedbackTemplate evaluationId={id}></FeedbackTemplate>
        </Grid>
      )}
    </Grid>
  );
}

export default FeedbackTable;
