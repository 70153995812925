import React from 'react'
import PersonalReportPage from '../../components/Result/Report/Personal/PersonalReportPage'
import { usePageState } from "../../system/context/PageContext"

function PersonalMain() {
  const page = usePageState();
  return (
    <PersonalReportPage id={page[6].evaluationId}></PersonalReportPage>
  )
}

export default PersonalMain