import React from 'react'
import ReportPage from '../../components/Result/Report/ReportPage'
import { usePageState } from "../../system/context/PageContext";

function ReportAdminMain() {
  const page = usePageState();
  
  return (
    <ReportPage id={page[6].evaluationId}></ReportPage>
  )
}

export default ReportAdminMain