import React, { ReactElement, useEffect, useMemo, useState } from "react";
import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Constant, SurveyChoice, SurveyQuestion } from "../../../system/types/type";
import { ConstantApi, ErrorHandler } from "../../../system/ApiService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 240,
    },
  })
);

interface SurveyModalTemplateProps {
  data: SurveyQuestion;
  choice: SurveyChoice[];
  onChange: (event: any) => void;
  onChangeChoice: (event: any, index: number) => void;
}

function SurveyModalTemplate({ data, choice, onChange, onChangeChoice }: SurveyModalTemplateProps): ReactElement {
  const classes = useStyles();
  const [answer, setAnswer] = useState<Constant[]>([]);
  const [show, setShow] = useState<boolean>(false);

  useMemo(() => {
    ConstantApi.GetConstantList("Question", "AnswerType")
      .then((res) => {
        setAnswer(res.data);
      })
      .catch((err) => {
        console.log(err, "answer");
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, []);

  useEffect(() => {
    if (data.surveyAnswerType === 1 || data.surveyAnswerType === 4) {
      setShow(true);
    }
  }, [data.surveyAnswerType]);

  const onChangeType = (event: any) => {
    onChange(event);
    if (event.target.value === 1 || event.target.value === 4) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="text"
          name="text"
          label="평가문항"
          value={data.text}
          onChange={onChange}
          multiline
          style={{ width: "100%" }}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="order"
          name="order"
          label="문항순서"
          type="number"
          value={data.order}
          onChange={onChange}
          style={{ width: "100%" }}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <FormControl className={classes.formControl}>
          <InputLabel id="surveyAnswerType">답안유형</InputLabel>
          <Select
            id="surveyAnswerType"
            name="surveyAnswerType"
            value={data.surveyAnswerType}
            onChange={onChangeType}
            style={{ width: "100%" }}
          >
            {answer.map((x) => {
              return (
                <MenuItem key={x.id} value={x.key}>
                  {x.value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      {show && (
        <>
          {choice.map((x, index) => (
            <Grid item xs={2} key={index}>
              <TextField
                name={`${index}`}
                label={`${index + 1}`}
                value={x.text}
                onChange={(e) => onChangeChoice(e, index)}
                style={{ width: "100%" }}
              ></TextField>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
}

export default SurveyModalTemplate;
