import { Button, Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TABLEICONS } from "../../../system/Constants";
import { Constant, ModalType, SurveyQuestion } from "../../../system/types/type";
import { AddBox, DeleteOutline, Edit } from "@material-ui/icons";
import { ConstantApi, ErrorHandler, SurveyApi } from "../../../system/ApiService";
import { TABLE_HEADER } from "../../../styles/theme";
import SurveyModal from "./SurveyModal";
import SelectEvaluation from "../../Common/SelectEvaluation";
import TitleDiv from "../../Common/TitleDiv";
import SecondaryButton from "../../Common/SecondaryButton";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
  },
  iconButton: {
    padding: "5px",
  },
  buttonGroup: {
    textAlign: "end",
  },
}));

function SurveyTable() {
  const classes = useStyles();
  const Loading = useLoadingDispatch();
  const [evaluationID, setEvaluationID] = useState<number>(0);
  const [list, setList] = useState<SurveyQuestion[]>([]);
  const [id, setId] = useState<number>();
  const [type, setType] = useState<ModalType>("Edit");
  const [surveyModal, setSurveyModal] = useState<boolean>(false);
  const [survey, setSurvey] = useState<Constant[]>([]);
  const surveyLookUp: Object = survey.reduce((x, y) => ({ ...x, [y.key]: y.value }), {});

  const onChange = (id: number) => {
    setEvaluationID(id);
  };

  const onLoad = useCallback(() => {
    if (evaluationID !== 0) {
      SurveyApi.GetSurveyQuestionList(evaluationID, 0)
        .then((res) => {
          setList(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  }, [evaluationID]);

  useMemo(() => {
    ConstantApi.GetConstantList("Question", "AnswerType").then((res) => {
      setSurvey(res.data);
    });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onOpenModal = (id: number) => {
    setSurveyModal(true);
    if (id !== 0) {
      setId(id);
      setType("Edit");
    } else {
      setId(0);
      setType("Add");
    }
  };

  const onCloseModal = () => {
    setSurveyModal(false);
    onLoad();
  };

  const onDeleteAnswer = (id: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      SurveyApi.DeleteSurveyQuestion(id)
        .then(() => {
          onLoad();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  };

  const insertSurvey = () => {
    if (evaluationID !== 0) {
      Loading({ type: "LOADING" });
      SurveyApi.MakeSurveyItem(evaluationID)
        .then(() => alert("만족도 조사를 일괄 생성했습니다."))
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="평가 만족도 조사 설정"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <SelectEvaluation onChange={onChange}></SelectEvaluation>
      </Grid>
      <Grid item xs={12}>
        <SecondaryButton onClick={insertSurvey}>만족도 조사 일괄 생성</SecondaryButton>
      </Grid>
      <Grid item xs={12} style={{ minWidth: "1400px" }}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            {
              title: "No.",
              field: "",
              cellStyle: { width: "5%" },
              render: (rowData) => {
                return <>{list.indexOf(rowData) + 1}</>;
              },
            },
            { title: "문항순서", field: "order", cellStyle: { width: "10%" }, align: "center" },
            { title: "평가문항", field: "text", cellStyle: { width: "55%" } },
            {
              title: "답안유형",
              field: "surveyAnswerType",
              cellStyle: { width: "20%" },
              lookup: surveyLookUp,
              align: "center",
            },
            {
              title: "",
              field: "",
              cellStyle: { width: "10%" },
              render: (rowData) => {
                return (
                  <div className={classes.buttonGroup}>
                    <IconButton className={classes.iconButton} onClick={() => onOpenModal(rowData.id)}>
                      <Edit />
                    </IconButton>
                    <IconButton className={classes.iconButton} onClick={() => onDeleteAnswer(rowData.id)}>
                      <DeleteOutline />
                    </IconButton>
                  </div>
                );
              },
            },
          ]}
          data={list || []}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <Grid container justifyContent="flex-end" alignItems="center" style={{ backgroundColor: "#fafafa" }}>
                <Grid item>
                  <MTableToolbar {...props} />
                </Grid>
                <Grid item>
                  <Button onClick={() => onOpenModal(0)}>
                    <AddBox />
                  </Button>
                </Grid>
              </Grid>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            pageSize: 20,
            headerStyle: TABLE_HEADER,
            exportButton: true,
            exportAllData: true,
          }}
        />
        <SurveyModal
          evaluationId={evaluationID}
          open={surveyModal}
          id={id}
          type={type}
          onClose={onCloseModal}
        ></SurveyModal>
      </Grid>
    </Grid>
  );
}

export default SurveyTable;
