import { Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Answer, Question } from "../../system/types/type";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "100%", 
    minHeight: "150px",
    padding: "10px",
    border: "none",
    outline: "none",
  },
}));

interface ShortAnswerProps {
  data: Question,
  onChange: (answer: Answer) => void,
}

function ShortAnswer({data, onChange}: ShortAnswerProps) {
  const classes = useStyles();
  const [value, setValue] = useState(data.answer.text);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    onChange({...data.answer, text: event.target.value})
  };

  return (
    <Grid>
      <textarea
        className={classes.textArea}
        placeholder="내용을 입력해주세요."
        name="text"
        value={value}
        onChange={handleChange}
      />
    </Grid>
  )
}

export default ShortAnswer;