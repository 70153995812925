import { createStyles, makeStyles, Theme, createTheme } from "@material-ui/core";
import { koKR } from "@material-ui/core/locale";

export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

export const TABLE_HEADER = {
  backgroundColor: PRIMARY,
  color: '#FFF',
  fontWeight: "bold",
};
export const TABLE_ROW = {
  borderBottom: "1px solid black",
};
export const TABLE_ACTION = {
  margin: "auto 0",
  width: "5%"
}

export const theme = createTheme(
  {
    typography: {
      h1: {
        fontSize: "3.8rem",
      },
      h2: {
        fontSize: "3.3rem",
      },
      h3: {
        fontSize: "2.5rem",
      },
      h4: {
        fontSize: "2.0rem",
      },
      h5: {
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      h6: {
        fontSize: "1.2rem",
      },
    },
    palette: {
      primary: {
        light: "#666f73",
        main: PRIMARY,
        dark: "#2c3438",
        contrastText: "#fff",
      },
      secondary: {
        light: "#A2AAAD",
        main: SECONDARY,
        dark: "#601317",
        contrastText: "#fff",
      },
    },
  },
  koKR
);
export const MyStyle = makeStyles((theme: Theme) =>
  createStyles({
    // content
    contentWrapper: {
      minWidth: "1000px",
      marginTop: "10px",
      display: "flex",
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: "200ms",
      }),
      marginLeft: 240,
    },
    contentShift: {
      minWidth: "1000px",
      marginTop: "10px",
      display: "flex",
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: "200ms",
      }),
      marginLeft: 0,
    },
    contentBody: {
      width: "7680px",
      "@media (max-width: 3840px)": {
        width: "3800px",
      },
      "@media (max-width: 2560px)": {
        width: "2520px",
      },
      "@media (max-width: 1920px)": {
        width: "1880px",
      },
      "@media (max-width: 1680px)": {
        width: "1640px",
      },
      "@media (max-width: 1440px)": {
        width: "1400px",
      },
      "@media (max-width: 1380px)": {
        width: "1340px",
      },
      "@media (max-width: 1279px)": {
        width: "1239px",
      },
    },
    // footer
    footer: {
      height: "60px",
      width: "100%",
      padding: "10px",
      position: "absolute",
      marginBottom: theme.spacing(3),
      textAlign: "center",
      zIndex: -1,
    },
    bodyWrap: {
      minHeight: "100%",
      position: "relative",
      marginTop: "60px",
      padding: "2px",
    },
  })
);
