import { AxiosError } from "axios";
import HttpClient from "./http-client";
import { Answer, Auth, Constant, DeptTreeView, Email, Evaluation, EvaluationFeedback, EvaluationImprove, EvaluationItem, EvaluationItemViewModel, EvaluationTemplate, EvaluationTerm, Question, ReservedMail, SelectedEvaluatee, SurveyAnswer, SurveyItem, SurveyQuestion, User } from "./types/type";

const url = process.env.REACT_APP_API_SERVICE_URL;
const mailUrl = process.env.REACT_APP_API_MAIL_SERVICE_URL;

const client = new HttpClient(url);
const mailClient = new HttpClient(mailUrl);

class _UserApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  Login(userId: string, userPassword: string) {
    return this.client.post("login", {
      Id: userId,
      Password: userPassword,
    });
  }
  Logout() {
    client.disableToken();
    return this.client.get("login/logout");
  }
  SetToken(token: string) {
    client.setToken(token);
  }
  InitUserInfo() {
    return this.client.post("login/renewal");
  }
  SSOLogin(token: string | null, gwMail: string | undefined) {
    if (!token) {
      console.log("no token");
      throw new Error("No token for sso-login");
    }
    this.SetToken(token);
    const form = new FormData();
    form.append("gwMail", gwMail || "");
    return this.client.post("login/ssologin", form);
  }
}
class _ConstantApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 항목 관리 crud
  GetConstant(constantId: number) {
    return this.client.get(`Constant/${constantId}`)
  }
  DeleteConstant(constantId: number) {
    return this.client.delete(`Constant/${constantId}`)
  }
  GetConstantList(table: string, column: string) {
    return this.client.get('Constant', { params: {tableName: table, columnName: column}} )
  }
  InsertConstant(data: Constant) {
    return this.client.post('Constant', data)
  }
  UpdateConstant(data: Constant) {
    return this.client.put('Constant', data)
  }
}
class _EmployeeApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  GetEmployeeByEmployeeId(employeeId: string){
    return this.client.get<User>(`Employee/${employeeId}`);
  }
  GetEmployee() {
    return this.client.get<User[]>("Employee")
  }
  GetLookup(columnName: string) {
    return this.client.get(`Employee/lookup/${columnName}`)
  }
  GetEmail() {
    return this.client.get<Email[]>("Employee/email")
  }
  GetDeptTreeView(year: number) {
    return new HttpClient("https://insamansa2.weareinbody.com/api").get<DeptTreeView>(`department/treeview/${year}`);
  }
  GetDeptTreeViewAndEmployee(year: number) {
    return new HttpClient("https://insamansa2.weareinbody.com/api").get<DeptTreeView>(`department/treeview/${year}/employee`);
  }
}
class _AuthApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  GetAuth() {
    return this.client.get("Auth")
  }
  DeleteAuth(id: number) {
    return this.client.delete(`Auth/${id}`)
  }
  InsertAuth(data: Auth) {
    return this.client.post("Auth", data)
  }
  UpdateAuth(data: Auth) {
    return this.client.put("Auth", data)
  }
}
class _MailApi {
  private mailClient: HttpClient;

  public constructor(client: HttpClient) {
    this.mailClient = client;
  }
  GetReservedMailList() {
    return this.mailClient.get("ReservedMail", {params: {project: "다면평가"}});
  }
  GetReservedMail(id: number) {
    return this.mailClient.get(`ReservedMail/${id}`);
  }
  DeleteReservedMail(id: number) {
    return this.mailClient.delete(`ReservedMail/${id}`);
  }
  InsertReservedMail(data: ReservedMail) {
    return this.mailClient.post(`ReservedMail`, data);
  }
  UpdateReservedMail(data: ReservedMail) {
    return this.mailClient.put(`ReservedMail`, data);
  }
  SendEmailNow(data: ReservedMail) {
    return this.mailClient.post(`Mail`, 
    data, {headers: {
      'apikey': "K082zsuwXH2t6YdRmH8Sv2mpY8uUrHSfZrd7oGLH",
    }})
  }
}
class _EvaluationApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 평가 생성 crud
  DeleteEvaluation(id: number) {
    return this.client.delete(`Evaluation/${id}`)
  }
  GetEvaluationList() {
    return this.client.get("Evaluation")
  }
  GetEvaluation(evaluationId: number) {
    return this.client.get(`Evaluation/${evaluationId}`)
  }
  InsertEvaluation(data: Evaluation) {
    return this.client.post("Evaluation", data)
  }
  UpdateEvaluation(data: Evaluation) {
    return this.client.put("Evaluation", data)
  }
  AddTerm(evaluationId: number) {
    return this.client.post(`Evaluation/${evaluationId}/term`)
  }

  // 평가 기간 crud
  GetTerm(evaluationId: number) {
    return this.client.get(`Evaluation/${evaluationId}/term`)
  }
  DeleteTerm(termId: number) {
    return this.client.delete(`Evaluation/term/${termId}`)
  }
  InsertTerm(data: EvaluationTerm) {
    return this.client.post(`Evaluation/term`, data)
  }
  UpdateTerm(data: EvaluationTerm) {
    return this.client.put(`Evaluation/term`, data)
  }

  // 평가 대상자 crud
  GetEvaluatee(evaluationId: number) {
    return this.client.get(`Employee/evaluation/${evaluationId}`);
  }
  GetEvaluateeByEmployeeId(evaluationId: number, employeeId: string){
    return this.client.get(`Evaluation/${evaluationId}/group/employee/${employeeId}`);
  }
  DeleteEvaluatee(id: number) {
    return this.client.delete(`Evaluation/group/${id}`)
  }
  InsertEvaluatee(data: SelectedEvaluatee) {
    return this.client.post(`Evaluation/groups`, data)
  }

  // 평가 현황 목록 조회
  GetEvaluationGroups(evaluationId: number){
    return this.client.get(`Evaluation/${evaluationId}/group`)
  }

  // 평가지 crud
  GetTemplate(evaluationId: number) {
    return this.client.get(`Evaluation/${evaluationId}/template`)
  }
  DeleteTemplate(templateId: number) {
    return this.client.delete(`Evaluation/template/${templateId}`)
  }
  InsertTemplate(data: EvaluationTemplate) {
    return this.client.post(`Evaluation/template`, data)
  }
  UpdateTemplate(data: EvaluationTemplate) {
    return this.client.put(`Evaluation/template`, data)
  }

  // 평가대상자 crud
  GetEvaluationItem(id: number) {
    return this.client.get(`Evaluation/item/${id}`)
  }
  DeleteEvaluationItem(id: number) {
    return this.client.delete(`Evaluation/item/${id}`)
  }
  GetAllEvaluationItem(evaluationId: number) {
    return this.client.get<EvaluationItemViewModel[]>(`Evaluation/${evaluationId}/item`)
  }
  GetEvaluationItemList(evaluationId: number, kind: string, employeeId: string, status: number) {
    return this.client.get(`Evaluation/${evaluationId}/item/${kind}/${employeeId}/${status}`)
  }
  InsertEvaluationItem(data: EvaluationItem) {
    return this.client.post(`Evaluation/item`, data)
  }
  UpdateEvaluationItem(data: EvaluationItem) {
    return this.client.put(`Evaluation/item`, data)
  }
  InsertBatchEvaluationItem(evaluationId: number) {
    return this.client.post(`Evaluation/item/${evaluationId}`)
  }

  // 평가문항 crud
  GetQuestionList(evaluationId: number, evaluationItemId: number, evaluationItemType: number) {
    return this.client.get(`Evaluation/${evaluationId}/question/${evaluationItemId}/${evaluationItemType}`)
  }
  GetQuestion(id: number) {
    return this.client.get(`Evaluation/question/${id}`)
  }
  DeleteQuestion(id: number) {
    return this.client.delete(`Evaluation/question/${id}`)
  }
  InsertQuestion(data: Question) {
    return this.client.post(`Evaluation/question`, data)
  }
  UpdateQuestion(data: Question) {
    return this.client.put(`Evaluation/question`, data)
  }

  // 평가답안 제출 
  UpdateAnswer(data: Answer, employeeId: string){
    return this.client.put(`Evaluation/question/answer/${employeeId}`, data);
  }
  UpdateAnswers(data: Answer[]) {
    return this.client.post(`Evaluation/question/answers`, data)
  }

  // 평가 피드백
  GetFeedback(feedbackId: number) {
    return this.client.get(`Evaluation/feedback/${feedbackId}`)
  }
  GetAllFeedback(evaluationId: number) {
    return this.client.get(`Evaluation/${evaluationId}/feedback`)
  }
  GetMyFeedback(evaluationId: number, employeeId: string) {
    return this.client.get(`Evaluation/${evaluationId}/feedback/${employeeId}`)
  }
  InsertFeedback(data: EvaluationFeedback) {
    return this.client.post(`Evaluation/feedback`, data)
  }
  UpdateFeedback(data: EvaluationFeedback) {
    return this.client.put(`Evaluation/feedback`, data)
  }
  DeleteFeedback(feedbackId: number) {
    return this.client.delete(`Evaluation/feedback/${feedbackId}`)
  }

  // 평가 피드백
  GetImprove(improveId: number) {
    return this.client.get(`Evaluation/improve/${improveId}`)
  }
  GetImproveList(evaluationId: number) {
    return this.client.get(`Evaluation/${evaluationId}/improve`)
  }
  InsertImprove(data: EvaluationImprove) {
    return this.client.post(`Evaluation/improve`, data)
  }
  UpdateImprove(data: EvaluationImprove) {
    return this.client.put(`Evaluation/improve`, data)
  }
  DeleteImprove(improveId: number) {
    return this.client.delete(`Evaluation/improve/${improveId}`)
  }
}
class _SurveyApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 만족도 조사 아이템 id로 조회
  GetSurveyItem(surveyId: number) {
    return this.client.get(`Survey/item/${surveyId}`)
  }
  // 만족도 조사 아이템 목록 조회
  GetSurveyItemList(evaluationId: number) {
    return this.client.get(`Survey/${evaluationId}/item`)
  }
  // 만족도 조사 일괄 생성
  MakeSurveyItem(evaluationId: number) {
    return this.client.post(`Survey/${evaluationId}/item`)
  }
  // 평가id, 사번으로 만족도 조사 아이템 조회
  GetSurveyItemByEmployeeId(evaluationId: number, employeeId: string) {
    return this.client.get(`Survey/${evaluationId}/item/${employeeId}`)
  }
  // 아이템 추가
  InsertSurveyItem(data: SurveyItem) {
    return this.client.post("Survey/item", data)
  }
  // 아이템 수정
  UpdateSurveyItem(data: SurveyItem) {
    return this.client.put("Survey/item", data)
  }
  //만족도 조사 아이템 삭제
  DeleteSurveyItem(id: number) {
    return this.client.delete(`Survey/item/${id}`)
  }
  
  // 만족도 조사 문항 crud
  GetSurveyQuestion(id: number) {
    return this.client.get(`Survey/question/${id}`)
  }
  GetSurveyQuestionList(evaluationId: number, surveyitemId: number) {
    return this.client.get(`Survey/${evaluationId}/qusetion/${surveyitemId}`)
  }
  InsertSurveyQuestion(data: SurveyQuestion) {
    return this.client.post("Survey/question", data)
  }
  UpdateSurveyQuestion(data: SurveyQuestion) {
    return this.client.put("Survey/question", data)
  }
  DeleteSurveyQuestion(id: number) {
    return this.client.delete(`Survey/question/${id}`)
  }

  // 만족도 조사 답안 crud
  GetSurveyAnswer(id: number) {
    return this.client.get(`Survey/answer/${id}`)
  }
  GetSurveyAnswerList(surveyItemId: number, surveyQuestionId: number) {
    return this.client.get(`Survey/${surveyItemId}/answer/${surveyQuestionId}/answer`)
  }
  InsertSurveyAnswer(data: SurveyAnswer) {
    return this.client.post("Survey/answer", data)
  }
  UpdateSurveyAnswer(data: SurveyAnswer) {
    return this.client.put("Survey/answer", data)
  }
  DeleteSurveyAnswer(id: number) {
    return this.client.delete(`Survey/answer/${id}`)
  }
  UpdateSurvey(data: SurveyAnswer[]) {
    return this.client.post(`Survey/answers`, data)
  }
}

class _ReportApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  // 평가 결과 //
  GetEvaluationReport(evaluationId: number, employeeId: string, answerType: string, evaluationItemType: number, isSelf: number){
    return client.get(`report/${evaluationId}/${employeeId}/${answerType}/${evaluationItemType}/${isSelf}`);
  }
  GetEvaluationReportAsEvaluator(evaluationId:number, employeeId:string, answerType: string, evaluationItemType: number){
    return client.get(`report/${evaluationId}/evaluator/${employeeId}/${answerType}/${evaluationItemType}`);
  }
  GetMultiChoiceReport(evaluationId: number, employeeId: string, evaluationItemType:number){
    return client.get(`report/${evaluationId}/${employeeId}/${evaluationItemType}/choice/personal`);
  }
  GetEvaluationReportForAdmin(evaluationId: number, employeeId: string, answerType: string, evaluationItemType: number, isSelf: number){
    return client.get(`report/${evaluationId}/${employeeId}/${answerType}/${evaluationItemType}/${isSelf}/admin`);
  }
  GetEvaluationReportTypes(evaluationId: number, employeeId: string, isPersonal: number){
    return client.get(`report/${evaluationId}/${employeeId}/type/${isPersonal}`);
  }
  GetEvaluationReportSummary(evaluationId: number, employeeId: string){
    return client.get(`report/${evaluationId}/${employeeId}/summary`)
  }
  GetQuestionCategories(evaluationId: number, answerType: number, evaluationItemType: number){
    return client.get(`report/${evaluationId}/${answerType}/${evaluationItemType}`);
  }
  GetCompanyEvaluationSummary(evaluationId: number){
    return client.get(`report/${evaluationId}/company/summary`);
  }
  // 평가 결과 통계 //
  GetEvaluationStatisticsKind(evaluationId: number){
    return client.get(`report/${evaluationId}/statistics/kind`);
  }
  GetEvaluationStatistics(evaluationId:number, columnName: string, evaluationItemType: number){
    return client.get(`report/${evaluationId}/statistics/${columnName}/${evaluationItemType}`);
  }
  GetEvaluationStatisticsSummary(evaluationId:number, columnName: string){
    return client.get(`report/${evaluationId}/statistics/${columnName}/summary`)
  }
  InsertEvaluationStatistics(evaluationId: number, columnName: string, list: string[]){
    return client.post(`report/${evaluationId}/statistics/${columnName}`, list);
  }
}

export const UserApi = new _UserApi(client);
export const ConstantApi = new _ConstantApi(client);
export const EmployeeApi = new _EmployeeApi(client);
export const AuthApi = new _AuthApi(client);
export const MailApi = new _MailApi(mailClient);
export const EvaluationApi = new _EvaluationApi(client);
export const SurveyApi = new _SurveyApi(client);
export const ReportApi = new _ReportApi(client);

export function ErrorHandler(error: AxiosError): string {
   let msg = "";
   if (error.response) {
     msg += error.response.data;
     if (msg === "" || msg === "[object Object]") {
       //여전히 빈 상태라면...
       switch (error.response.status) {
         case 401:
           msg = "세션이 만료되었습니다.";
           break;
         case 400: //Bad Request
           msg = "데이터 입력이 잘못되었습니다.";
           break;
         case 403: //Forbidden
           msg = "접근할 수 없습니다.";
           break;
         case 404: //NotFound
           msg = "표시할 데이터가 없습니다.";
           break;
         case 405: //method not allowd
           msg = "405: 접근할 수 없습니다.";
           break;
         case 500:
           msg = "서버 에러가 발생하였습니다.";
           break;
         default:
           msg = "알 수 없는 오류입니다. 관리자에게 문의해주세요";
           break;
       }
     }
   } else {
     msg += "서버에서 데이터를 가져올 수 없습니다.";
   }
   return msg;
 }
 