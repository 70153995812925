import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { ConstantApi, ErrorHandler } from "../../../system/ApiService";
import { CONSTANT_TABLE_ARRAY } from "../../../system/Constants";
import { Constant } from "../../../system/types/type";
import ItemTable from "./ItemTable";

function ItemSelect(): ReactElement {
  const [constant, setConstant] = useState<Constant[]>([]);
  const [table, setTable] = useState<string>("EvaluationTerm");

  const onChangeTable = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    setTable(value);
  };

  const refreshConstant = React.useCallback(() => {
    ConstantApi.GetConstantList(table, "")
      .then((res) => {
        setConstant(res.data);
      })
      .catch((err) => {
        console.log(err, "err");
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [table]);

  useEffect(() => {
    refreshConstant();
  }, [refreshConstant]);

  return (
    <div>
      {/* 항목관리 테이블 선택 */}
      <FormControl style={{ margin: "10px 0px 10px 20px" }}>
        <InputLabel id="select-placeholder">항목관리 테이블 선택</InputLabel>
        <Select name="year" value={table} onChange={onChangeTable} style={{ width: "200px" }}>
          {CONSTANT_TABLE_ARRAY.map((u) => (
            <MenuItem value={u.value} key={`tables-${u.name}-${u.value}`}>
              {u.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* 해당 테이블 항목들 */}
      <ItemTable table={table} data={constant} refreshData={refreshConstant}></ItemTable>
    </div>
  );
}

export default ItemSelect;
