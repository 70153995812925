import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { RestaurantMenu } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import { ErrorHandler, SurveyApi } from "../../../system/ApiService";
import { InitSurveyChoice, InitSurveyQuestion } from "../../../system/types/initObject";
import { SurveyChoice, SurveyQuestion } from "../../../system/types/type";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";
import SurveyModalTemplate from "./SurveyModalTemplate";

interface SurveyModalProps {
  evaluationId: number;
  open: boolean;
  id?: number;
  type: "Edit" | "Add";
  onClose: () => void;
}

function SurveyModal({ evaluationId, open, id, type, onClose }: SurveyModalProps) {
  const [data, setData] = useState<SurveyQuestion>(InitSurveyQuestion);

  const onLoad = useCallback(() => {
    if (id && id !== 0) {
      SurveyApi.GetSurveyQuestion(id)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      setData(InitSurveyQuestion);
    }
  }, [id]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChange = (event: any) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeChoice = (event: any, index: number) => {
    const clonedData = [...data.surveyChoices];
    clonedData[index].text = event.target.value;
    setData({...data, surveyChoices: clonedData});
  };

  const saveAnswer = () => {
    let flag = true;

    if (evaluationId === 0) {
      flag = false;
    } else if (
      (data.surveyAnswerType === 1 || data.surveyAnswerType === 4) &&
      data.surveyChoices.filter((x) => x.text === "").length >= 1
    ) {
      flag = false;
    } else if (data.surveyAnswerType === 0 || data.text === "") {
      flag = false;
    }

    if (flag) {
      if (type === "Add") {
        SurveyApi.InsertSurveyQuestion({ ...data, evaluationId: evaluationId, surveyChoices: data.surveyChoices })
          .then((res) => {
            onClose();
          })
          .catch((err) => {
            console.log(err, "Add");
            let msg = ErrorHandler(err);
            alert(msg);
          });
      } else if (type === "Edit") {
        SurveyApi.UpdateSurveyQuestion({ ...data, evaluationId: evaluationId, surveyChoices: data.surveyChoices })
          .then((res) => {
            onClose();
          })
          .catch((err) => {
            console.log(err, "Edit");
            let msg = ErrorHandler(err);
            alert(msg);
          });
      }
    } else {
      alert("평가 문항과 답안 유형을 모두 입력했는지 확인해주세요.");
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>평가 만족도 문항 설정</DialogTitle>
        <DialogContent>
          <SurveyModalTemplate
            data={data}
            choice={data.surveyChoices}
            onChange={onChange}
            onChangeChoice={onChangeChoice}
          ></SurveyModalTemplate>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={onClose}>CANCEL</SecondaryButton>
          <PrimaryButton onClick={saveAnswer}>SAVE</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SurveyModal;
