import React, { useState, useEffect } from "react";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";
import { EvaluationReportType, User } from "../../../../system/types/type";
import MultipleChoiceIntro from "./MultipleChoiceIntro";
import PersonalMultipleChoiceReportTable from "./PersonalMultipleChoiceReportTable";
import MultipleChoiceReportSummary from "./MultipleChoiceReportSummary";
import PersonalMultipleChoiceReportChart from "./PersonalMultipleChoiceReportChart";

interface PersonalMultipleChoiceReportProps {
  evaluationId: number;
  employee: User;
}

function PersonalMultipleChoiceReport({
  evaluationId,
  employee,
}: PersonalMultipleChoiceReportProps) {
  const [types, setTypes] = useState<EvaluationReportType[]>([]);

  useEffect(() => {
    ReportApi.GetEvaluationReportTypes(evaluationId, employee.employeeId, 2)
      .then((res) => {
        setTypes(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationId, employee]);

  return (
    <>
      {/* 객관식 평가 결과 설명 */}
      <MultipleChoiceIntro></MultipleChoiceIntro>
      {/* 타입별 객관식 평가 결과 상세 테이블 */}
      {types.map((item, index) => {
        return (
          <>
            <PersonalMultipleChoiceReportTable
              evaluationId={evaluationId}
              employee={employee}
              type={item}
              index={index}
              key={`PersonalMultipleChoiceReportTable-${index}`}
            ></PersonalMultipleChoiceReportTable>
            <PersonalMultipleChoiceReportChart
              evaluationId={evaluationId}
              employee={employee}
              index={index}
              type={item}
              key={`PersonalMultipleChoiceReportChart-${index}`}
            ></PersonalMultipleChoiceReportChart>
          </>
        );
      })}
      {/* 객관식 결과 요약 */}
      <MultipleChoiceReportSummary
        evaluationId={evaluationId}
        employee={employee}
        index={types.length + 1}
      ></MultipleChoiceReportSummary>
    </>
  );
}

export default PersonalMultipleChoiceReport;
