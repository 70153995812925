import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headTitleBar: {
    paddingLeft: "5px",
    letterSpacing: "-0.5px",
    fontWeight: "bold",
  },
}));

interface TitleDivProps {
  title: string;
}

function SubTitleDiv({ title }: TitleDivProps) {
  const classes = useStyles();

  return (
    <Typography variant="h6" component="h5" className={classes.headTitleBar}>
      {title}
    </Typography>
  );
}

export default SubTitleDiv;
