import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";
import SelectModalTemplate from "./SelectModalTemplate";
import { EvaluateType, EvaluationItem, User } from "../../../system/types/type";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { InitEvaluationItem } from "../../../system/types/initObject";
import { AuthorityUser } from "../../../system/types/interface";

interface SelectModalProps {
  id: number;
  type: EvaluateType;
  user: User | AuthorityUser;
  open: boolean;
  onClose: () => void;
}

function SelectModal({ id, type, user, open, onClose }: SelectModalProps) {
  const [data, setData] = useState<EvaluationItem>(InitEvaluationItem);

  const onChangeText = (event: any) => {
    setData({
      ...data,
      [event.target.name]: event.target.value as string
    })
  };

  const onChange = (data: EvaluationItem) => {
    if (type === "Evaluatee") {
      setData({
        ...data,
        evaluationId: id,
        evaluateeId: user.employeeId,
        evaluateeTeamId: user.teamId.toString(),
      })
    } else if (type === "Evaluator") {
      setData({
        ...data,
        evaluationId: id,
        evaluatorId: user.employeeId,
        evaluatorTeamId: user.teamId.toString(),
      })
    }
  };

  const saveSelect = () => {
    if (data.collaboration) {
      EvaluationApi.InsertEvaluationItem(data)
      .then(() => {
        onClose();
        setData(InitEvaluationItem)
      })
      .catch((err) => {
        console.log(err, "save");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    } else {
      alert("협력한 임직원과 협력 내용을 작성해주세요.")
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
        <DialogTitle>평가자 선택</DialogTitle>
        <DialogContent>
          <SelectModalTemplate type={type} data={data} onChangeText={onChangeText} onChange={onChange}></SelectModalTemplate>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={onClose}>CANCEL</SecondaryButton>
          <PrimaryButton onClick={saveSelect}>SAVE</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SelectModal;
