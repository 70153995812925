import React, { useEffect, useState } from "react";
import {
   ChartComponent,
   SeriesCollectionDirective,
   SeriesDirective,
   Inject,
   Tooltip,
   Legend,
   LineSeries,
   Category,
   PolarSeries,
   RadarSeries,
   AxisModel,
   LegendSettingsModel,
} from "@syncfusion/ej2-react-charts";
import { EvaluationAnswer, EvaluationReportType, User } from "../../../../system/types/type";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";

interface MultipleChoiceReportChartProps {
   evaluationId: number;
   employee: User;
   index: number;
   type: EvaluationReportType;
}

function MultipleChoiceReportChart({ evaluationId, employee, index, type }: MultipleChoiceReportChartProps) {
   const [data, setData] = useState<EvaluationAnswer[][]>();

   useEffect(() => {
      ReportApi.GetEvaluationReport(evaluationId, employee.employeeId, "choice", type.type, 1)
         .then((res) => {
            let temp: EvaluationAnswer[][] = [];
            temp.push(res.data);
            ReportApi.GetEvaluationReport(evaluationId, employee.employeeId, "choice", type.type, 0)
               .then((res2) => {
                  temp.push(res2.data);
                  // temp[0] = temp[0].filter((x)=>x.score !== 0);
                  temp[1] = temp[1].filter((x)=>x.score !== 0);
                  setData(temp);
               })
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [evaluationId, employee, type]);

   const primaryxAxis: AxisModel = { title: "category", valueType: "Category" };
   const primaryyAxis: AxisModel = { minimum: 0, maximum: 10, interval: 1, title: "score", labelFormat: "{value}" };
   const maker: any ={visible: true};
   const legendSettings: LegendSettingsModel = { visible: true, position: "Top" };

   if (data !== undefined && data[0].length > 0 && data[1].length > 0) {
      return (
         <>
            <ChartComponent
               id={`charts-${type.type}-${index}`}
               primaryXAxis={primaryxAxis}
               primaryYAxis={primaryyAxis}
               legendSettings={legendSettings}
               tooltip={{ enable: true }}
               palettes={["#2781D6", "#E94649"]}
               title={`${type.value} 항목의 본인/타인 인식 비교 그래프`}
               titleStyle={{color: "red"}}
            >
               <Inject services={[RadarSeries, PolarSeries, LineSeries, Category, Legend, Tooltip]} />
               <SeriesCollectionDirective>
                  {data[0] !== undefined && data[0].length > 0 && (
                     <SeriesDirective
                        dataSource={data[0]}
                        xName="category"
                        yName="score"
                        type="Polar"
                        name="본인 평가"
                        drawType="Line"
                        marker={maker}
                     ></SeriesDirective>
                  )}
                  {data[1] !== undefined && data[1].length > 0 && (
                     <SeriesDirective
                        dataSource={data[1]}
                        xName="category"
                        yName="score"
                        type="Polar"
                        name="타인 평가"
                        drawType="Line"
                        marker={maker}
                     ></SeriesDirective>
                  )}
               </SeriesCollectionDirective>
            </ChartComponent>
         </>
      );
   } else {
      return <></>;
   }
}

export default MultipleChoiceReportChart;
