import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { ErrorHandler, SurveyApi } from "../../../../system/ApiService";
import { SurveyAnswer, SurveyItem, SurveyQuestion } from "../../../../system/types/type";
import MultipleChoiceSurvey from "../../../Common/MultipleChoiceSurvey";
import ShortAnswerSurvey from "../../../Common/ShortAnswerSurvey";

const useStyles = makeStyles((theme) => ({
  header: {
    border: "0.5px #d0d0d0 solid",
    marginTop: "10px",
    padding: "20px",
    backgroundColor: "#EDEDED",
    display: "table",
    borderBottom: "0.5px #d0d0d0 solid",
  },
  qna: {
    border: "0.5px #d0d0d0 solid",
    marginTop: "10px",
  },
  question: {
    padding: "20px",
    backgroundColor: "#EDEDED",
    display: "table",
    borderBottom: "0.5px #d0d0d0 solid",
  },
  category: {
    backgroundColor: "#fff",
    textAlign: "center",
    verticalAlign: "middle",
    display: "table-cell",
    width: "150px",
  },
  choice: {
    marginTop: "10px",
    padding: "10px",
  },
  answer: {
    margin: 0,
    padding: 0,
  },
}));

interface SurveyAnswersProps {
  data: SurveyItem;
  onAnswerList: (data: SurveyAnswer[]) => void;
}

function SurveyAnswers({ data, onAnswerList }: SurveyAnswersProps) {
  const classes = useStyles();
  const [questionList, setQuestionList] = useState<SurveyQuestion[]>([]);
  const [answerList, setAnswerList] = useState<SurveyAnswer[]>([]);

  const onLoad = useCallback(() => {
    if (data.evaluationId) {
      SurveyApi.GetSurveyQuestionList(data.evaluationId, data.id)
        .then((res) => {
          setQuestionList(res.data);
          res.data.map((x: SurveyQuestion) => {
            return answerList.push(x.surveyAnswer);
          });
          onAnswerList(answerList);
        })
        .catch((err) => {
          console.log(err, "err");
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      alert("선택된 평가가 없습니다.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.evaluationId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onAnswer = (answer: SurveyAnswer) => {
    const clonedData = [...answerList];
    let index = answerList.findIndex((x) => x.surveyQuestionId === answer.surveyQuestionId);
    if (index === -1) {
      clonedData.push(answer);
    } else {
      clonedData[index] = answer;
    }
    setAnswerList(clonedData);
    onAnswerList(clonedData);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={8} className={classes.header}>
        <Typography variant="h5">평가 만족도 설문조사</Typography>
        <br />
        <p>
          다면평가 진행과 관련된 임직원 여러분의 소중한 의견을 청하고자 결과 조회 전 평가 만족도 설문조사를 실시하고자
          합니다.
        </p>
        <p>
          설문조사 결과를 바탕으로 다면평가가 더욱 공정하고 효과적인 평가가 될 수 있도록 지속적으로 보완해 나갈
          예정이오니, 자유롭고 솔직하게 의견을 작성해 주시기 바랍니다.
        </p>
        <br />
        <p>감사합니다.</p>
      </Grid>
      <Grid item xs={8} style={{ minWidth: "1000px" }}>
        {questionList.map((x, index) => (
          <Box key={index} className={classes.qna}>
            {/* question */}
            <Grid container className={classes.question}>
              <Grid item xs={12}>
                <Typography>
                  {index + 1} {x.text}
                </Typography>
              </Grid>
            </Grid>

            {/* answer */}
            {x.surveyAnswerType === 1 || x.surveyAnswerType === 4 ? (
              <Grid className={classes.choice}>
                <MultipleChoiceSurvey data={x} onChange={onAnswer}></MultipleChoiceSurvey>
              </Grid>
            ) : null}
            {x.surveyAnswerType !== 1 ? (
              <Grid className={classes.answer}>
                <ShortAnswerSurvey data={x} onChange={onAnswer}></ShortAnswerSurvey>
              </Grid>
            ) : null}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}

export default SurveyAnswers;
