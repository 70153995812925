import { Grid, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TABLE_ACTION, TABLE_HEADER } from "../../../styles/theme";
import { EmployeeApi, ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { TABLEICONS } from "../../../system/Constants";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { InitLookup } from "../../../system/types/initObject";
import { Lookup } from "../../../system/types/interface";
import { EvaluationFeedbackViewModel } from "../../../system/types/type";
import SelectEvaluation from "../../Common/SelectEvaluation";
import TitleDiv from "../../Common/TitleDiv";
import FeedbackTemplateAdmin from "./FeedbackTemplateAdmin";

function FeedbackTableAdmin() {
  const [id, setId] = useState<number>();
  const [data, setData] = useState<EvaluationFeedbackViewModel[]>([]);
  const [evaluationID, setEvaluationID] = useState<number>(0);
  const [lookup, setLookup] = useState<Lookup>(InitLookup);
  const check = {};

  const onChange = (id: number) => {
    setEvaluationID(id);
  };

  const onClickRow = (id: number) => {
    setId(id);
  };

  useMemo(() => {
    EmployeeApi.GetLookup("all").then((res) => {
      var parentTeam: string[] = res.data.parentTeam;
      var parentTeamLookups: Object = parentTeam.reduce((x, y) => ({ ...x, [y]: y }), {});
      var team: string[] = res.data.team;
      var teamLookups: Object = team.reduce((x, y) => ({ ...x, [y]: y }), {});
      var temp: Lookup = InitLookup;
      temp = { ...temp, parentTeam: parentTeamLookups, team: teamLookups };
      setLookup(temp);
    });
  }, []);

  const onLoad = useCallback(() => {
    if (evaluationID !== 0) {
      EvaluationApi.GetAllFeedback(evaluationID)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  }, [evaluationID]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="피드백 조회"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <SelectEvaluation onChange={onChange}></SelectEvaluation>
      </Grid>
      <Grid item xs={8} style={{ minWidth: "1000px" }}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            {
              title: "No.",
              field: "",
              cellStyle: { width: "10%" },
              align: "center",
              render: (rowData) => {
                return <>{data.indexOf(rowData) + 1}</>;
              },
            },
            {
              title: "파트",
              field: "parentTeam",
              lookup: lookup.parentTeam,
              cellStyle: { width: "20%" },
              align: "center",
            },
            {
              title: "팀",
              field: "team",
              lookup: lookup.team,
              cellStyle: { width: "20%" },
              align: "center",
            },
            {
              title: "성명",
              field: "name",
              cellStyle: { width: "20%" },
              align: "center",
            },
            {
              title: "피드백",
              field: "context",
              lookup: check,
              cellStyle: { width: "15%" },
              align: "center",
              render: (rowData) => {
                return <>{rowData.context ? "O" : "X"}</>;
              },
            },
            {
              title: "답변",
              field: "comment",
              lookup: check,
              cellStyle: { width: "15%" },
              align: "center",
              render: (rowData) => {
                return <>{rowData.comment ? "O" : "X"}</>;
              },
            },
          ]}
          data={data || []}
          onRowClick={(event, rowData) => {
            if (rowData) {
              onClickRow(rowData.id);
            }
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div style={{ backgroundColor: "#fafafa" }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            actionsCellStyle: TABLE_ACTION,
            headerStyle: TABLE_HEADER,
            padding: "dense",
            pageSize: 5,
            exportButton: true,
            filtering: true,
          }}
          localization={LMATERIAL_TABLE}
        />
      </Grid>
      {id && (
        <Grid item xs={8} style={{ minWidth: "1000px" }}>
          <FeedbackTemplateAdmin id={id}></FeedbackTemplateAdmin>
        </Grid>
      )}
    </Grid>
  );
}

export default FeedbackTableAdmin;
