import React, {useEffect, useState} from "react";
import { User } from "../../../../system/types/type";
import { Typography, makeStyles, TableBody, TableCell, TableRow } from "@material-ui/core";
import { Table } from 'react-bootstrap';
import { PRIMARY } from "../../../../styles/theme";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";

interface EssayAnswerReportProps {
   evaluationId: number;
   employee: User;
}

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: "800px",
      marginTop: "10px",
   },
   tableHeader: {
      textAlign: "center",
      fontSize: "1rem",
   },
}));

function EssayAnswerReport({ evaluationId, employee }: EssayAnswerReportProps) {
   const classes = useStyles();
   const [data, setData] = useState<string[]>([]);

   useEffect(()=>{
      let essay: string[] = [];
      ReportApi.GetEvaluationReport(evaluationId, employee.employeeId, "essay", 0, 1).then((res)=>{
         essay.push(res.data);
         ReportApi.GetEvaluationReport(evaluationId, employee.employeeId, "essay", 0, 0).then((res)=>{
            essay.push(res.data);
            setData(essay);
         }).catch((error)=>{
            let msg = ErrorHandler(error);
            alert(msg);
         });
      }).catch((error)=>{
         let msg = ErrorHandler(error);
         alert(msg);
      });
      
   }, [evaluationId, employee]);

   return (
      <>
      <br></br>
         <Typography variant="h6">3. 주관식 평가 결과</Typography>
         <Table className={classes.table}>
            <TableBody>
               <TableRow>
                  <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "15%" }}>본인평가</TableCell>
                  <TableCell style={{ textAlign: "left", width: "75%" }}>{data[0]}</TableCell>
               </TableRow>
               <TableRow>
               <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center"}}>타인평가</TableCell>
                  <TableCell style={{ textAlign: "left", whiteSpace: "pre-line" }}>
                     {data[1] || "-"}
                  </TableCell>
               </TableRow>
            </TableBody>
         </Table>
      </>
   );
}

export default EssayAnswerReport;
