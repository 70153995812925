import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useMemo, useState } from "react";
import { EmployeeApi, ErrorHandler } from "../../system/ApiService";
import { Email } from "../../system/types/type";
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { usePageState } from "../../system/context/PageContext";

interface SelectEmailProps {
  type: "cc" | "to" | "bcc";
  status: string;
  init?: string;
  onChange: (data: Email[], type: "cc" | "to" | "bcc") => void;
}

function SelectEmail({ type, status, init, onChange }: SelectEmailProps) {
  const [emailList, setEmailList] = useState<Email[]>([]);
  const [value, setValue] = useState<Email[]>([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const page = usePageState();

  useMemo(() => {
    if (init) {
      setValue(emailList.filter((x) => init.split(",").includes(x.email) && x.email !== ""));
    } else setValue([]);
  }, [emailList, init]);

  useEffect(() => {
    EmployeeApi.GetEmail()
      .then((res) => {
        var list = [];
        list.push({ name: "평가자선택 미완료자", email: `${page[0].evaluationId}|1` });
        list.push({ name: "평가대상자선택 미완료자", email: `${page[0].evaluationId}|2` });
        list.push({ name: "평가 미완료자", email: `${page[0].evaluationId}|4` });
        list = list.concat(res.data);
        setEmailList(list);
      })
      .catch((err) => {
        console.log(err);
        let msg = ErrorHandler(err);
        alert(msg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (event: React.ChangeEvent<{}>, value: Email[] | null) => {
    if (value !== null) {
      onChange(value, type);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          disabled={status !== "T"}
          value={value}
          options={emailList}
          disableCloseOnSelect
          onChange={handleSelect}
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                defaultChecked={init ? true : false}
              />
              {option.name}
              <span style={{ marginLeft: "20px", fontSize: "14px", color: "#888888" }}>{`\t ${option.email}`}</span>
            </React.Fragment>
          )}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={type === "cc" ? "참조" : type === "bcc" ? "숨은참조" : "받는 사람"}
              placeholder="메일 주소를 선택해주세요."
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default SelectEmail;
