import { Grid, makeStyles, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { PRIMARY } from "../../../styles/theme";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { InitTemplate } from "../../../system/types/initObject";
import { EvaluationTemplate } from "../../../system/types/type";
import Editor from "../../Common/Editor";
import HtmlContent from "../../Common/HtmlContent";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "650px",
  },
  tableHeader: {
    width: "15%",
    textAlign: "center",
    fontSize: "1rem",
  },
}));

interface TemplateTableProps {
  id: number;
}

function TemplateTable({id: evaluationId}: TemplateTableProps) {
  const classes = useStyles();
  const refHeader = useRef<Editor>(null);
  const refFooter = useRef<Editor>(null);
  const [add, setAdd] = useState<boolean>(false);
  const [data, setData] = useState<EvaluationTemplate>(InitTemplate);

  const onLoad = useCallback(() => {
    if (evaluationId) {
      EvaluationApi.GetTemplate(evaluationId)
      .then((res) => {
        if (!res.data[0]) {
          setData(InitTemplate)
        } else { setData(res.data[0]) }
      })
      .catch((err) => {
        console.log(err, "err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    }
  }, [evaluationId])

  const onUpdate = () => {
    if (!evaluationId) {
      alert("평가명을 선택해주세요.")
    } else {
      setAdd(true);
    }
  }

  const onSave = () => {
    if (data.id !== 0) {
      EvaluationApi.UpdateTemplate({...data, footer: refFooter.current?.getContent() || "", header: refHeader.current?.getContent() || ""})
      .then(() => {
        setAdd(false);
        onLoad();
      })
      .catch((err) => {
        console.log(err, "err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    } else {
      EvaluationApi.InsertTemplate({...data, footer: refFooter.current?.getContent() || "", header: refHeader.current?.getContent() || "",  evaluationId: evaluationId})
      .then(() => {
        setAdd(false);
        onLoad();
      })
      .catch((err) => {
        console.log(err, "err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    }
  }

  const onDelete = () => {
    if (data.id) {
      if (window.confirm("해당 템플릿을 삭제하시겠습니까? 삭제 후 복구할 수 없습니다.")) {
        EvaluationApi.DeleteTemplate(data.id)
        .then((res) => {
          setAdd(false);
          onLoad();
        })
        .catch((err) => {
          console.log(err, "err");
          let msg = ErrorHandler(err);
        alert(msg);
        })
      }
    } else {
      alert("삭제 할 수 있는 템플릿이 존재하지 않습니다.")
    }
  }

  useEffect(() => {
    onLoad()
  }, [onLoad])

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} justifyContent="flex-end">
        { add ? 
          <>
            <PrimaryButton onClick={onSave}>저장</PrimaryButton>
            <SecondaryButton onClick={() => setAdd(false)}>취소</SecondaryButton>
          </> :
          <>
            <PrimaryButton onClick={onUpdate}>수정</PrimaryButton>
            <SecondaryButton onClick={onDelete}>삭제</SecondaryButton>
          </>
        }
      </Grid>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: PRIMARY, color: "white" }} className={classes.tableHeader}>상단(평가 소개)</TableCell>
              <TableCell>
                {add ? 
                  <Editor content={data.header || ""} ref={refHeader}></Editor>
                  : <HtmlContent data={data.header ? data.header : "내용을 작성해주세요."}></HtmlContent>
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: PRIMARY, color: "white" }} className={classes.tableHeader}>하단(평가 종료)</TableCell>
              <TableCell>
                {add ? 
                  <Editor content={data.footer || ""} ref={refFooter}></Editor>
                  : <HtmlContent data={data.footer ? data.footer : "내용을 작성해주세요."}></HtmlContent>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}

export default TemplateTable;
