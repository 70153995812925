import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import SelectTable from "../../components/Progress/Select/SelectTable"
import { usePageState } from "../../system/context/PageContext";

function SelectMain() {
  const page = usePageState();
  const history = useHistory();

  useEffect(() => {
    if (!page[0].isOpen) {
      alert("평가자 선택 기간이 아닙니다.")
      history.push("/")
    }
  },[history, page])

  if (page[0].isOpen) {
    return (
      <>
        <SelectTable id={page[0].evaluationId}></SelectTable>
      </>
    )
  } else return <></>;
}

export default SelectMain
