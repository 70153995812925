import React, { useState } from "react";
import { useUserDispatch, useUserState } from "../../system/context/UserContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PersistentDrawerLeft from '../../components/Common/PersistentDrawerLeft';
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink, useHistory } from "react-router-dom";
import SiteMapModal from "../../components/Common/SiteMapModal";
import { Grid, Switch } from "@material-ui/core";
import { IsAdminInRoles } from "../../system/types/interface";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      marginLeft: "10px",
      fontWeight: "bold",
      fontSize: "20px",
    },
    navProfile: {
      marginLeft: "10px",
      marginRight: "10px",
    },
  })
);

interface HeaderProps{
   open: boolean;
   setOpen: (status: boolean) => void;
}

function Header({open, setOpen} :HeaderProps) {
   const classes = useStyles();
   const user = useUserState();
   const dispatch = useUserDispatch();
   const history = useHistory();
   const [authCheck, setAuthCheck] = useState<boolean>(false);
   const [modal, setModal] = useState<boolean>(false);
   const openSiteMap = () => setModal(true);
   const closeSiteMap = () => setModal(false);

   const handleDrawerOpen = () => {
     setOpen(!open);
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (user.roles.length === 2) {
         const newRole = user.roles.filter(x => x !== "User")[0]
         if (event.target.checked) {
            // admin 전환
            const newUser = {...user, role: newRole}
            dispatch({ type: "CHANGE_ROLE", item: newUser });
         } else {
            // user 전환
            const newUser = {...user, role: "User"}
            dispatch({ type: "CHANGE_ROLE", item: newUser });
         }
         setAuthCheck(event.target.checked);
         history.push("/");
      } else {
         alert("관리자 권한이 없습니다.")
      }
   };
      
   return (
      <div className={classes.root}>
         <AppBar color="inherit" position="fixed">
            <Toolbar>
               {/* 메뉴 아이콘 */}
               <IconButton
                  edge="start"
                  className={classes.menuButton}
                  onClick={handleDrawerOpen}
                  color="inherit"
                  aria-label="menu"
               >
                  <MenuIcon />
               </IconButton>
               {/* 로고 - 홈버튼 */}
               <NavLink to="/" >
                  <img src="/images/inbody_logo.png" width="100px" alt="title"></img>
               </NavLink>
               {/* 사이트 이름 */}
               <Typography className={classes.title} variant="button">다면평가</Typography>
               {/* 권한 전환 */}
               {IsAdminInRoles(user.roles) && 
                  <div style={{ marginRight: "20px" }}>
                     <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>User</Grid>
                        <Grid item>
                           <Switch checked={authCheck} onChange={handleChange} name="authCheck" />
                        </Grid>
                        <Grid item>Admin</Grid>
                     </Grid>
                  </div>
               }
               {/* 사용자 프로필 */}
               <div className={classes.navProfile}>
                  <Typography variant="body2">{user.gwMail}</Typography>
                  <Typography variant="body2">{`${user.teamName} ${user.name}`}</Typography>
               </div>
               {/* 사이트 맵 */}
               <div>
                  <IconButton onClick={openSiteMap}>
                     <Typography> Site Map</Typography>
                  </IconButton>
               </div>
            </Toolbar>
         </AppBar>
         {/* nav */}
         <div>
         <PersistentDrawerLeft open={open}></PersistentDrawerLeft>
         </div>
         {/* site map modal */}
         <SiteMapModal
            open={modal}
            onClose={closeSiteMap}
         ></SiteMapModal>
      </div>
   );
}

export default Header;
