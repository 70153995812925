import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { PRIMARY } from "../../../styles/theme";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { useUserState } from "../../../system/context/UserContext";
import { InitFeedbackViewModel } from "../../../system/types/initObject";
import { IsAdmin1 } from "../../../system/types/interface";
import { EvaluationFeedbackViewModel } from "../../../system/types/type";
import Editor from "../../Common/Editor";
import HtmlContent from "../../Common/HtmlContent";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "650px",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: "1rem",
  },
  tableBody: {
    minHeight: "400px",
  },
  textArea: {
    width: "100%",
    minHeight: "150px",
    padding: "10px",
    border: "none",
    outline: "none",
  },
}));

interface FeedbackTemplateAdminProps {
  id: number;
}

function FeedbackTemplateAdmin({ id }: FeedbackTemplateAdminProps) {
  const classes = useStyles();
  const user = useUserState();
  const refComment = useRef<Editor>(null);
  const [add, setAdd] = useState<boolean>(false);
  const [data, setData] = useState<EvaluationFeedbackViewModel>(InitFeedbackViewModel);

  const onLoad = useCallback(() => {
    EvaluationApi.GetFeedback(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [id]);

  const onUpdate = () => {
    setAdd(true);
  };

  const onSave = () => {
    EvaluationApi.UpdateFeedback({ ...data, comment: refComment.current?.getContent() || "" })
      .then(() => {
        setData({ ...data, comment: refComment.current?.getContent() || "" });
        setAdd(false);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  };

  const onDelete = () => {
    if (window.confirm("답변을 정말 삭제하시겠습니까? 삭제 후 복구되지 않습니다.")) {
      EvaluationApi.UpdateFeedback({ ...data, comment: "" })
        .then(() => {
          setData({ ...data, comment: "" });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} justifyContent="flex-end">
        {add ? (
          <>
            <PrimaryButton onClick={onSave}>저장</PrimaryButton>
            <SecondaryButton onClick={() => setAdd(false)}>취소</SecondaryButton>
          </>
        ) : (
          <>
            <PrimaryButton onClick={onUpdate}>작성</PrimaryButton>
            <SecondaryButton disabled={data.comment === ""} onClick={onDelete}>
              삭제
            </SecondaryButton>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: PRIMARY, color: "white" }} className={classes.tableHeader}>
                작성란
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableBody}>
                <Typography className={classes.textArea}>{data.context}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {add && IsAdmin1(user.role) ? (
                  <Editor content={data.comment || ""} ref={refComment}></Editor>
                ) : (
                  <HtmlContent data={data.comment ? data.comment : "작성된 답변이 없습니다."}></HtmlContent>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}

export default FeedbackTemplateAdmin;
