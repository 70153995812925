import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { useEffect, useState } from "react";
import { SurveyAnswer, SurveyQuestion } from "../../system/types/type";

interface MultipleChoiceSurveyProps {
  data: SurveyQuestion;
  onChange: (answer: SurveyAnswer) => void;
}

function MultipleChoiceSurvey({ data, onChange }: MultipleChoiceSurveyProps) {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    setValue(data.surveyAnswer.choice);
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value));
    onChange({ ...data.surveyAnswer, choice: parseInt(event.target.value) });
  };

  return (
    <>
      <Grid container justifyContent="center">
        <FormControl component="fieldset">
          <RadioGroup row aria-label="choice" name="choice" value={value} onChange={handleChange}>
            {data.surveyChoices.map((x, index) => (
              <FormControlLabel key={index} value={x.order} control={<Radio />} label={x.text} />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
}

export default MultipleChoiceSurvey;
