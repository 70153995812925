export enum CommonRoutes {
  root = "/",
  logout = "/logout",
  login = "/login",
}
 export enum SettingRoutes {
  root = "/setting",
  Evaluation = "/setting/evaluation", // 평가 설정 및 기간 설정
  Evaluatee = "/setting/evaluatee", // 평가 대상자 설정
  Paper = "/setting/paper", // 평가지 설정
  Auth = "/setting/admin", // 관리자 설정
  Item = "/setting/item", // 다면평가 항목관리
  Mail = "/setting/mail", // 이메일 설정
}

export enum ProgressRoutes {
  root = "/progress",
  Select = "/progress/select", // 협업한 임직원 선택
  Confirm = "/progress/confirm", // user : 평가 집단 확인
  Status = "/progress/status", // admin : 현황 관리
  ConfirmAdmin = "/progress/confirm-admin", // admin : 평가 집단 확인
  Evaluate = "/progress/evaluate", // 평가하기
}

export enum ResultRoutes {
  root = "/result",
  Setting = "/result/setting",  // 결과 보고서 설정
  Personal = "/result/personal", // 개인별 결과
  JobTitle = "/result/jobTitle", // 직책별 종합 결과
  JobPosition = "/result/jobPosition", // 직위별 종합 결과
  Company = "/result/company",
  Report = "/result/report", // 결과 보고서
  ReportAdmin = "/result/report-admin",  // 결과 보고서 - 관리자
  Feedback = "/result/feedback", // user : 피드백 요청
  FeedbackAdmin = "/result/feedback-admin",//admin : 피드백 조회
}

export enum ImprovingRoutes {
  root = "/improving",
  Survey = "/improving/survey", // 평가 만족도 조사 설정
  Result = "/improving/result", // 평가 만족도 조사 결과 조회
  List = "/improving/list", // 평가 개선 방향 설정
}

export type ModalType = "Add" | "Edit";

export type EvaluateType = "Evaluator" | "Evaluatee";

export interface Constant {
  id: number;
  tableName: string;
  columnName: string;
  key: number;
  value: string;
  remark: string;
  order: number;
}

export interface User {
  employeeId: string;
  employeeNo: string;
  name: string;
  gwName: string;
  teamId: string;
  team: string;
  parentTeamId: string;
  parentTeam: string;
  positionCode: string;
  role: string;
  workplace: string;
  jobTitle: string;
  jobPosition: string;
  gwMail: string;
  joined: string;
  employmentForm: string;
}

export interface GridData {
  result: any[];
  count: number;
}

export interface DeptTreeView {
  id: string;
  name: string;
  subChild?: DeptTreeView[];
  expanded?: boolean;
}

export interface Constant {
  id: number;
  tableName: string;
  columnName: string;
  key: number,
  value: string,
  remark: string;
  order: number;
}

export interface Evaluation {
  id: number;
  year: number;
  half: string;
  title: string;
}

export interface EvaluationTerm {
  id: number;
  evaluationId: number;
  term: string;
  started: string;
  ended: string;
  isOpen: boolean;
}

export interface SelectedEvaluatee {
  evaluationId: number,
  employeeIds: string[],
}

export interface EvaluationTemplate {
  id: number;
  evaluationId: number;
  header: string;
  footer: string;
}

export interface EvaluationItem {
  id: number;
  evaluationId: number;
  evaluateeId: string;
  evaluateeTeamId: string;
  evaluatorId: string;
  evaluatorTeamId: string;
  type: number,
  status: number;
  collaboration: string;
  rejectReason: string;
  order: number;
}

export interface EvaluationItemViewModel extends EvaluationItem {
  typeName: string;
  evaluateeName: string;
  evaluateeParentTeam:	string;
  evaluateeTeam:	string;
  evaluatorName: string;
  evaluatorParentTeam: string;
  evaluatorTeam:	string;
}

export interface Question {
  id: number,
  evaluationId: number,
  evaluationItemType: number,
  order: number,
  category: string,
  answerType: number,
  text: string,
  choices: Choice[],
  answer: Answer,
}

export interface Choice {
  id: number,
  questionId: number,
  text: string,
  order: number,
}

export interface Answer {
  id: number,
  evaluationItemId: number,
  questionId: number,
  answerType: number,
  choice: number,
  text: string,
}

export interface AnswerEditModel extends Answer{
  evaluatorName: string,
  evaluatorTeam: string
}

export interface Auth {
  id: number, 
  employeeId: string,
  authorization: string,
}

export interface AuthViewModel extends Auth {
  parentTeam: string;
  team: string;
  name: string;
}

export interface ReservedMail {
  id: number,
  project: string,
  creator: string,
  reservedDate:	string,
  sendDate:	string,
  sender:	string,
  receiverEmail:	string, // 구분자 ','
  ccEmail:	string, // 구분자 ','
  bccEmail: string, // 구분자 ','
  title:	string,
  contents:	string,
  status:	string,
}

export interface Email {
  name: string,
  email: string,
}

export interface EvaluationGroup {
  id: number;
  evaluationId: number;
  employeeId: string;
  status: number;
}

export interface EvaluationGroupViewModel extends EvaluationGroup {
    parentTeam: string;
    team: string;
    name: string;
    step1: boolean;
    step2: boolean;
    step3: boolean;
    step4: boolean;
}

export interface EvaluationFeedback {
  id: number, 
  evaluationId: number,
  employeeTeamId: number,
  employeeId: string,
  context: string,
  comment: string,
}

export interface EvaluationFeedbackViewModel extends EvaluationFeedback {
  year: number,
  half: string,
  title: string,
  parentTeam: string,
  team: string,
  name: string,
}

export interface EvaluationImprove {
  id: number,
  evaluationId: number,
  improvement: string,
  reflect: string,
}

export interface SurveyAnswer {
  id: number,
  surveyItemId: number,
  surveyAnswerType: number,
  surveyQuestionId: number,
  choice: number,
  text: string,
}

export interface SurveyChoice {
  id: number,
  surveyQuestionId: number,
  text: string,
  order: number,
}

export interface SurveyItem {
  id: number,
  evaluationId: number,
  evaluateeId: string,
  evaluatorId: string,
  evaluatorTeamId: string,
  status: number,
}

export interface SurveyItemViewModel extends SurveyItem {
  evaluatorName: string,
  evaluatorTeam: string,
  evaluatorParentTeam: string,
  statusText: string,
}

export interface SurveyQuestion {
  id: number,
  evaluationId: number,
  surveyAnswerType: number,
  text: string,
  order: number,
  surveyChoices: SurveyChoice[],
  surveyAnswer: SurveyAnswer,
}

export interface EvaluationReportType{
  type: number,
  value: string
}

export interface EvaluationStatisticsTalbeInfo{
  types: EvaluationReportType[];
  kinds: SelectItem[];
}

export interface EvaluationReportSummary {
  type: string;
  categories: EvaluationScore[];
  text: string;
}

export interface EvaluationScore {
  id: number;
  category: string;
  selfScore: number;
  othersScore: number;
}

export interface EvaluationAnswer {
  id: number;
  order: number;
  category: string;
  score: number;
  text: string;
}

export interface SelectItem{
  text: string;
  value: string;
}

export interface EvaluationStatistics {
  id: number;
  evaluationId: number;
  evaluationItemType: number;
  columnName: string;
  kind: string;
  category: string;
  score: number;
  evaluationCount: number;
  employeeCount: number;
  order: number;
}

export interface EvaluationStatisticsSummary {
    type: string;
    highCategories: EvaluationStatistics[];
    lowCategories: EvaluationStatistics[];
    high: string;
    low: string;
}