import MaterialTable from 'material-table';
import React, {useEffect, useState} from 'react'
import { ErrorHandler, EvaluationApi, ReportApi } from '../../../../system/ApiService';
import { TABLEICONS } from '../../../../system/Constants';
import { AnswerEditModel, User } from '../../../../system/types/type'
import { Paper, TextField, Typography } from "@material-ui/core";
import { TABLE_HEADER } from "../../../../styles/theme";
import { useUserState } from '../../../../system/context/UserContext';

interface EssayAnswerReportForAdminProps{
   evaluationId: number,
   employee: User,
   isCompany?: boolean
}

function EssayAnswerReportForAdmin({evaluationId, employee, isCompany}: EssayAnswerReportForAdminProps) {
   const user = useUserState();
   const [data, setData] = useState<AnswerEditModel[]>([]);
   
   useEffect(()=>{
      if(employee.employeeId !== ""){
         let evaluationItemType = 5;
         if(isCompany)
            evaluationItemType = 6;

         ReportApi.GetEvaluationReportForAdmin(evaluationId, employee.employeeId, "essay" , evaluationItemType, 1).then((res)=>{
            setData(res.data);
         }).catch((error)=>{
            let msg = ErrorHandler(error);
            alert(msg);
         })
      }
   }, [evaluationId, employee, isCompany]);

  return (
    <>
    <Typography variant="h6">3. 주관식 평가 결과</Typography>
    <MaterialTable
          title=""
          icons={TABLEICONS}
          style={{marginTop:"10px"}}
          columns={[
            { title: 'No.', field: '', cellStyle: { width: "5%" }, 
              render: rowData => {
                return (
                  <>{data.indexOf(rowData) + 1}</>
                )
              } 
            },
            { title: '파트', field: 'evaluatorParentTeam', editable: 'never', cellStyle: { width: "12%" }, align: "center", },
            { title: '팀', field: 'evaluatorTeam', editable: 'never', cellStyle: { width: "12%" }, align: "center", },
            { title: '직위', field: 'evaluatorJobPosition', editable: 'never', cellStyle: { width: "8%" }, align: "center", },
            { title: '이름', field: 'evaluatorName', editable: 'never', cellStyle: { width: "8%" },  align: "center",},
            { title: '평가내용', field: 'text', cellStyle: { width: "55%" }, align: "left", editComponent: props => (
               <TextField className="form-control" value={props.value} onChange={e => props.onChange(e.target.value)} multiline={true}></TextField>
             ) },
          ]}
          data={data || []}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
          }}
          editable={!isCompany ? {
            onRowUpdate: (newData: any, oldData: any) =>
            new Promise((resolve, reject) => {
               setTimeout(() => {
                  EvaluationApi.UpdateAnswer(newData, user.employeeId).then((res)=>{
                     const dataUpdate = [...data];
                     const index = oldData.tableData.id;
                     dataUpdate[index] = newData;
                     setData([...dataUpdate]);
                  }).catch((error)=>{
                     let msg = ErrorHandler(error);
                     alert(msg);
                  }).finally(()=>{
                     resolve(newData);
                  })
               }, 100)
            }),
          }: undefined}
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            toolbar: isCompany ? true : false,
            paging: isCompany ? true : false,
            pageSize: 10,
            pageSizeOptions: [10, 20, data.length],
            headerStyle: TABLE_HEADER,
            exportButton: true
          }}
        />
    </>
  )
}

export default EssayAnswerReportForAdmin