import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { TABLEICONS } from '../../../system/Constants';
import { EvaluationItemViewModel } from '../../../system/types/type';
import { ErrorHandler, EvaluationApi } from '../../../system/ApiService';
import { LMATERIAL_TABLE } from '../../../system/Localization';
import { Paper } from '@material-ui/core';
import { TABLE_ACTION, TABLE_HEADER } from '../../../styles/theme';
import { useLoadingDispatch } from '../../../system/context/LoadingContext';

interface AllEvaluationItemProps {
  id: number,
}

function AllEvaluationItem({id: evaluationID}: AllEvaluationItemProps) {
  const loadingDispatch = useLoadingDispatch();
  const status = {0: "선택", 1: "확인", 2: "삭제"}
  const [data, setData] = useState<EvaluationItemViewModel[]>([]);

  const onLoad = useCallback(() => {
    if (evaluationID) {
      loadingDispatch({ type: "LOADING" });
      EvaluationApi.GetAllEvaluationItem(evaluationID)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err,"err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => loadingDispatch({ type: "COMPLETE" }));
    } 
  }, [evaluationID, loadingDispatch])

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <MaterialTable
        title="전체 평가자 조회"
        icons={TABLEICONS}
        columns={[
          { title: 'No.', field: '', cellStyle: { width: "2%" },
          render: rowData => {
            return (
              <>{data.indexOf(rowData) + 1}</>
              )
            }
          },
          { title: '파트', field: 'evaluatorParentTeam', cellStyle: { width: "10%" }, align: "center", },
          { title: '팀', field: 'evaluatorTeam', cellStyle: { width: "10%" }, align: "center", },
          { title: '성명', field: 'evaluatorName', cellStyle: { width: "8%" }, align: "center", },
          { title: '파트', field: 'evaluateeParentTeam', cellStyle: { width: "10%" }, align: "center", },
          { title: '팀', field: 'evaluateeTeam', cellStyle: { width: "10%" }, align: "center", },
          { title: '평가대상', field: 'evaluateeName', cellStyle: { width: "10%" }, align: "center", },
          { title: '협업 내용', field: 'collaboration', cellStyle: { width: "15%" }, align: "center", },
          { title: '거절 사유', field: 'rejectReason', cellStyle: { width: "15%" }, align: "center", },
          { title: '상태', field: 'status', cellStyle: { width: "8%" }, lookup: status, align: "center",},
        ]}
        data={data || []}
        components={{
          Container: props => <Paper {...props} elevation={0}/>,
          Toolbar: props => (
            <div style={{backgroundColor: '#fafafa'}}>
              <MTableToolbar {...props} />
            </div>
          )
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          pageSizeOptions: [5, 10, 20, data.length],
          headerStyle: TABLE_HEADER,
          actionsCellStyle: TABLE_ACTION,
          padding: "dense",
          filtering: true,
          exportButton: true,
          exportAllData: true,
        }}
        localization={LMATERIAL_TABLE}
      />
    </>
  )
}


export default AllEvaluationItem;