import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Paper, } from '@material-ui/core'
import TitleDiv from '../../Common/TitleDiv'
import MaterialTable, { MTableToolbar } from 'material-table';
import { TABLEICONS } from '../../../system/Constants';
import SelectEvaluation from '../../Common/SelectEvaluation';
import { User } from '../../../system/types/type';
import SecondaryButton from '../../Common/SecondaryButton';
import { EmployeeApi, ErrorHandler, EvaluationApi } from '../../../system/ApiService';
import { LMATERIAL_TABLE } from '../../../system/Localization';
import { TABLE_HEADER } from '../../../styles/theme';
import { Delete } from '@material-ui/icons';
import { Lookup } from '../../../system/types/interface';
import { InitLookup } from '../../../system/types/initObject';

interface UserGroup extends User {
  evaluationGroupId: number
}
function EvaluateeTable() {
  const [evaluationID, setEvaluationID] = useState<number>(0);
  const [emps, setEmps] = useState<User[]>([]);
  const [selectedEmps, setSelectedEmps] = useState<UserGroup[]>([]);
  const [checked, setChecked] = useState<User[]>([]);
  const [lookup, setLookup] = useState<Lookup>(InitLookup);

  const onChange = (id: number) => {
    setEvaluationID(id)
  }

  const onLoadSelected = useCallback(() => {
    if (evaluationID) {
      EvaluationApi.GetEvaluatee(evaluationID)
      .then((res) => {
        setSelectedEmps(res.data)
      })
      .catch((err) => {
        console.log(err, "get evaluatee");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    }
  }, [evaluationID])

  const onLoad = useCallback(() => {
    if (evaluationID) {
      EmployeeApi.GetEmployee()
      .then((res) => {
        setEmps(res.data)
      }).catch((err)=>{
        let msg = ErrorHandler(err);
        alert(msg);
      })
    }
  }, [evaluationID])

  useMemo(() => {
    EmployeeApi.GetLookup("all").then((res)=>{
      var workplace :string[] = res.data.workplace;
      var workplaceLookups: Object = workplace.reduce((x, y) => ({ ...x, [y]: y }), {});
      var parentTeam :string[] = res.data.parentTeam;
      var parentTeamLookups: Object = parentTeam.reduce((x, y) => ({ ...x, [y]: y }), {});
      var team :string[] = res.data.team;
      var teamLookups: Object = team.reduce((x, y) => ({ ...x, [y]: y }), {});
      var jobPosition :string[] = res.data.jobPosition;
      var jobPositionLookups: Object = jobPosition.reduce((x, y) => ({ ...x, [y]: y }), {});
      var jobTitle :string[] = res.data.jobTitle;
      var jobTitleLookups: Object = jobTitle.reduce((x, y) => ({ ...x, [y]: y }), {});
      var employmentForm :string[] = res.data.employmentForm;
      var employmentFormLookups: Object = employmentForm.reduce((x, y) => ({ ...x, [y]: y }), {});
      var temp: Lookup = InitLookup;
      temp = {...temp, workplace: workplaceLookups, parentTeam: parentTeamLookups, team: teamLookups, jobPosition: jobPositionLookups, 
      jobTitle: jobTitleLookups, employmentForm: employmentFormLookups};
      setLookup(temp);
    })
  }, [])

  useEffect(() => {
    onLoad()
    onLoadSelected()
  }, [onLoadSelected, onLoad])

  const addEmps = () => {
    if (checked.length === 0) {
       alert("선택한 대상이 없습니다.");
       return;
    } else {
      let _temp: string[]  = [];
      checked.forEach((x) => {
        let _Emp = Object.values(selectedEmps).find((item) => item.employeeId === x.employeeId);
        if (_Emp === undefined) {
            _temp.push(x.employeeId);
        }
      });

      if (evaluationID) {
        EvaluationApi.InsertEvaluatee({evaluationId: evaluationID, employeeIds: _temp})
        .then(() => {
          alert("평가 대상을 추가했습니다.")
          onLoadSelected();
        })
        .catch((err) => {
          console.log(err, "update")
        })
      } else {
        alert("평가명을 선택해주세요.")
      }
    }
  };

  const onDeleteUserList = (data: any) => {
    data.map((x: UserGroup) => (
      EvaluationApi.DeleteEvaluatee(x.evaluationGroupId)
      .then(() => {})
      .catch(() => {})
    ))
    onLoadSelected();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title='평가 대상자 설정'></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <SelectEvaluation onChange={onChange}></SelectEvaluation>
      </Grid>
      <Grid item xs={12} style={{ minWidth: "1500px"}}>
        <MaterialTable
          title="평가 대상 선택"
          icons={TABLEICONS}
          columns={[
            { title: '사번', field: 'employeeNo', cellStyle: { width: "8%" }, align: "center", },
            { title: '사업장', field: 'workplace', lookup: lookup.workplace, cellStyle: { width: "8%" }, align: "center", },
            { title: '파트', field: 'parentTeam', lookup: lookup.parentTeam, cellStyle: { width: "12%" }, align: "center", },
            { title: '팀', field: 'team', lookup: lookup.team, cellStyle: { width: "12%" }, align: "center", },
            { title: '직위', field: 'jobPosition', lookup: lookup.jobPosition, cellStyle: { width: "7%" }, align: "center", },
            { title: '직책', field: 'jobTitle', lookup: lookup.jobTitle, cellStyle: { width: "10%" }, align: "center", },
            { title: '성명', field: 'name', cellStyle: { width: "8%" }, align: "center", },
            { title: '고용형태', field: 'employmentForm', lookup: lookup.employmentForm, cellStyle: { width: "15%" }, align: "center", },
            { title: '입사일자', field: 'joined', cellStyle: { width: "9%" }, align: "center" },
            { title: '근속연한', field: 'joined', cellStyle: { width: "9%" }, align: "center",
              render: rowData => {
                var elapsedMSec = new Date().getTime() - new Date(rowData.joined).getTime();
                const elapsedYear = (elapsedMSec / 1000 / 60 / 60 / 24 / 365).toFixed(1);
                return (
                  <>{elapsedYear}</>
                )
              } 
            },
          ]}
          data={emps || []}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
            Toolbar: props => (
              <div style={{backgroundColor: '#fafafa'}}>
                <MTableToolbar {...props} />
              </div>
            )
          }}
          options={{
            padding: "dense",
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, emps.length],
            selection: true,
            headerStyle: TABLE_HEADER,
            exportButton: true,
            exportAllData: true,
            filtering: true,
          }}
          onSelectionChange={(rows) => setChecked(rows)}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <SecondaryButton onClick={addEmps}>
          대상 추가
        </SecondaryButton>
      </Grid>
      <Grid item xs={12} style={{ minWidth: "1500px"}}>
        <MaterialTable
            icons={TABLEICONS}
            title="선정된 평가 대상자"
            data={selectedEmps}
            columns={[
              { title: '사번', field: 'employeeNo', cellStyle: { width: "8%" }, align: "center", },
              { title: '사업장', field: 'workplace', lookup: lookup.workplace, cellStyle: { width: "8%" }, align: "center", },
              { title: '파트', field: 'parentTeam', lookup: lookup.parentTeam, cellStyle: { width: "12%" }, align: "center", },
              { title: '팀', field: 'team', lookup: lookup.team, cellStyle: { width: "12%" }, align: "center", },
              { title: '직위', field: 'jobPosition', lookup: lookup.jobPosition, cellStyle: { width: "8%" }, align: "center", },
              { title: '직책', field: 'jobTitle', lookup: lookup.jobTitle, cellStyle: { width: "10%" }, align: "center", },
              { title: '성명', field: 'name', cellStyle: { width: "8%" }, align: "center", },
              { title: '고용형태', field: 'employmentForm', lookup: lookup.employmentForm, cellStyle: { width: "15%" }, align: "center", },
              { title: '입사일자', field: 'joined', cellStyle: { width: "9%" }, align: "center", },
              { title: '근속연한', field: 'joined', cellStyle: { width: "9%" }, align: "center",
                render: rowData => {
                var elapsedMSec = new Date().getTime() - new Date(rowData.joined).getTime();
                const elapsedYear = (elapsedMSec / 1000 / 60 / 60 / 24 / 365).toFixed(1);
                  return (
                    <>{elapsedYear}</>
                  )
                }
              },
            ]}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    EvaluationApi.DeleteEvaluatee(oldData.evaluationGroupId)
                    .then(() => {
                      onLoadSelected();
                    })
                    resolve(oldData);
                  }, 100);
                }),
            }}
            components={{
              Container: props => <Paper {...props} elevation={0}/>,
              Toolbar: props => (
                <div style={{backgroundColor: '#fafafa'}}>
                  <MTableToolbar {...props} />
                </div>
              )
            }}
            options={{
              actionsColumnIndex: -1,
              padding: "dense",
              selection: true,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, selectedEmps.length],
              headerStyle: TABLE_HEADER,
              exportButton: true,
              exportAllData: true,
              filtering: true,
            }}
            localization={LMATERIAL_TABLE}
            actions={[
              {
                tooltip: 'Remove Selected Users',
                icon: () => <Delete/>,
                onClick: (evt, data) => onDeleteUserList(data)
              }
            ]}
        />
      </Grid>
    </Grid>
  )
}


export default EvaluateeTable