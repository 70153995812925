import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./azure/authConfig";
import { registerLicense } from '@syncfusion/ej2-base';

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root");

registerLicense("Mgo+DSMBMAY9C3t2VVhiQlFacl9JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1jXX9YcndURWRbUkA=");
//registerLicense("Mgo+DSMBMAY9C3t2VVhjQlFac19JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RjW31fcHxQR2dUVEY="); 20.3.50

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  container
);

<script src="https://unpkg.com/react-router-dom/umd/react-router-dom.min.js"></script>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
