import { useEffect } from "react";
import ConfirmTable from "../../components/Progress/Confirm/ConfirmTable"
import { useHistory } from "react-router-dom"
import { usePageState } from "../../system/context/PageContext";

function ConfirmMain() {
  const page = usePageState();
  const history = useHistory();

  useEffect(() => {
    if (!page[2].isOpen) {
      alert("평가대상자 선택 기간이 아닙니다.")
      history.push("/")
    }
  },[history, page])

  if (page[2].isOpen) {
    return (
      <>
        <ConfirmTable id={page[2].evaluationId}></ConfirmTable>
      </>
    )
  } else return <></>;
}

export default ConfirmMain
