import React, { useCallback, useEffect, useState } from 'react'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import TitleDiv from '../../Common/TitleDiv'
import MaterialTable, { MTableToolbar } from 'material-table';
import { TABLEICONS } from '../../../system/Constants';
import { EvaluationItemViewModel, ModalType } from '../../../system/types/type';
import { LMATERIAL_TABLE } from '../../../system/Localization';
import PrimaryButton from '../../Common/PrimaryButton';
import { ErrorHandler, EvaluationApi } from '../../../system/ApiService';
import { useUserState } from '../../../system/context/UserContext';
import ConfirmModal from './ConfirmModal';
import { InitEvaluationItemViewModel } from '../../../system/types/initObject';
import SecondaryButton from '../../Common/SecondaryButton';
import { TABLE_HEADER } from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  headTitleBar: {
    letterSpacing: "-0.5px",
    fontWeight: "bold",
  },
}));

interface ConfirmTableProps {
  id: number,
}

function ConfirmTable({id: evaluationID}: ConfirmTableProps) {
  const classes = useStyles();
  const user = useUserState();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [data, setData] = useState<EvaluationItemViewModel[]>([]);
  const [state, setState] = useState<EvaluationItemViewModel>(InitEvaluationItemViewModel);
  const [type, setType] = useState<ModalType>("Add");
  const status = {0: "-", 1: "확인", 2: "삭제"}
  
  const onLoad = useCallback(() => {
    if (evaluationID !== 0) {
      EvaluationApi.GetEvaluationItemList(evaluationID, "evaluator", user.employeeId, 0)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err,"err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    }
  }, [evaluationID, user.employeeId])

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onConfirm = (data: EvaluationItemViewModel) => {
    setState(data)
    setConfirmModal(true)
    if (data.status !== 0) {
      setType("Edit")
    }
  }

  const onCloseModal = () => {
    setConfirmModal(false);
    onLoad();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title='평가대상자 선택'></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="h5" className={classes.headTitleBar}>
          본인이 평가하게 될 평가대상자 명단을 확인하신 후 '확인' 또는 '삭제' 버튼은 눌러 평가대상자를 선택해 주시기 바랍니다.
        </Typography>
        <Typography>*본인 평가 및 회사 평가는 필수 항목이므로 삭제가 불가능합니다.</Typography>
        <Typography>*평가가 불가한 경우, 평가 불가 사유를 작성해 주신 후 '삭제' 버튼을 눌러주시기 바랍니다.</Typography>
      </Grid>
      <Grid item xs={12} style={{ minWidth: "1200px"}}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            { title: 'No.', field: '', cellStyle: { width: "5%" }, align: "center",
              render: rowData => {
                return (
                  <>{data.indexOf(rowData) + 1}</>
                )
              } 
            },
            { title: '파트', field: 'evaluateeParentTeam', cellStyle: { width: "15%" }, align: "center", },
            { title: '팀', field: 'evaluateeTeam', cellStyle: { width: "15%" }, align: "center", },
            { title: '성명', field: 'evaluateeName', cellStyle: { width: "10%" }, align: "center", },
            { title: '확인/삭제', field: 'status', cellStyle: { width: "12%" }, lookup: status, align: "center", },
            { title: '평가 불가 사유', field: 'rejectReason', cellStyle: { width: "30%" }, },
            { title: '', field: '', cellStyle: { width: "13&" },
              render: rowData => {
                return (
                  <>
                    {rowData.status === 0 ? 
                      <PrimaryButton onClick={() => {onConfirm(rowData)}}>확정하기</PrimaryButton> :
                      <SecondaryButton onClick={() => {onConfirm(rowData)}}>수정하기</SecondaryButton>
                    }
                  </>
                )
              }
            },
          ]}
          data={data || []}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
            Toolbar: props => (
              <div style={{backgroundColor: '#fafafa'}}>
                <MTableToolbar {...props} />
              </div>
            )
          }}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            headerStyle: TABLE_HEADER,
            padding: "dense",
            rowStyle: (rowData) => {
              return {
                backgroundColor: rowData.status === 2 ? "#e8e8e8" : "#fff"
              }
            }
          }}
          localization={LMATERIAL_TABLE}
        />
        <ConfirmModal
        state={state} 
        type={type}
        open={confirmModal}
        onClose={onCloseModal}
        ></ConfirmModal>
      </Grid>
    </Grid>
  )
}


export default ConfirmTable