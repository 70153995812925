import { Grid, TextField } from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Fragment, useEffect, useState } from 'react';
import { ErrorHandler, EvaluationApi } from '../../system/ApiService';
import { InitEvaluation } from '../../system/types/initObject';
import { Evaluation } from '../../system/types/type';

interface SelectEvaluationProps {
  onChange?: (id: number) => void;
  onChangeEvaluation?: (evaluation: Evaluation) => void;
}

function SelectEvaluation({onChange, onChangeEvaluation}: SelectEvaluationProps) {
  const [evaluationList, setEvaluationList] = useState<Evaluation[]>([]);
  const [evaluation, setEvaluation] = useState<Evaluation>(InitEvaluation);

  useEffect(() => {
    EvaluationApi.GetEvaluationList()
    .then((res) => {
      setEvaluationList(res.data);
      setEvaluation(res.data[0]);
      if(onChange !== undefined)
        onChange(res.data[0].id);
      if(onChangeEvaluation !== undefined)
        onChangeEvaluation(res.data[0]);
    })
    .catch((err) => {
      console.log(err, "err");
      let msg = ErrorHandler(err);
        alert(msg);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const handleSelect = (event: React.ChangeEvent<{}>, value: Evaluation | null) => {
    if (value !== null) {
      if(onChange !== undefined)
        onChange(value.id);
      if(onChangeEvaluation !== undefined)
        onChangeEvaluation(value);
      setEvaluation(value);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          value={evaluation}
          inputValue={evaluation.title}
          options={evaluationList}
          getOptionLabel={(option) => option.title}
          onChange={handleSelect}
          style={{ maxWidth: 600 }}
          renderOption={(option) => (
            <Fragment>
              {option.title}
              <span
                style={{ marginLeft: "20px", fontSize: "14px", color: "#888888" }}
              >{`\t ${option.year}년 ${option.half}`}</span>
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} label="평가명"></TextField>
          )}
        ></Autocomplete>
      </Grid>
    </Grid>
  )
}


export default SelectEvaluation