import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { Dialog, DialogActions, DialogContent, Grid, IconButton, StepButton } from '@material-ui/core';
import { Answer, EvaluationItemViewModel } from '../../../system/types/type';
import EvaluateTemplate from './EvaluateTemplate';
import CloseIcon from '@material-ui/icons/Close';
import EvaluateAnswer from './EvaluateAnswer';
import PrimaryButton from '../../Common/PrimaryButton';
import SecondaryButton from '../../Common/SecondaryButton';
import { EvaluationApi } from '../../../system/ApiService';
import { PRIMARY } from '../../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      width: '100%',
      marginTop:"5px",
      marginLeft:"34px"
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      marginRight: theme.spacing(1),
    },
    step: {
      "& .MuiStepIcon-active": { color: PRIMARY },
      "& .MuiStepIcon-completed": { color: PRIMARY },
    }
  }),
);

function getSteps(type: number) {
  if (type === 2) {
    return ['시작', '공통', '파트 내 리더', '공통2', '종료'];
  } else if (type === 3) {
    return ['시작', '공통', '파트 내 동료', '공통2', '종료'];
  } else if (type === 4) {
    return ['시작', '공통', '공통2', '종료'];
  } else if (type === 6) {
    return ['시작', '회사 평가', '종료'];
  } else {
    return ['시작', '공통', '공통2', '종료'];
  }
}

function IsValidation(data: Answer[]) {
  let flag = true;

  for (let item of data)  {
    if (item.answerType === 1 || item.answerType === 4) {
      if (item.choice === 0) {
       flag = false; 
      }
    } else if (item.answerType === 2) {
      if (item.text === null || item.text === "") {
        flag = false;
      }
    }
  }
  return flag;
}

interface EvaluatePageProps {
  state: EvaluationItemViewModel;
  open: boolean;
  onClose: () => void;
}

function EvaluatePage({state, open, onClose}: EvaluatePageProps) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
  const [answerList, setAnswerList] = useState<Answer[]>([]);
  const steps = getSteps(state.type);
  const onCloseIcon = () => {
    if (window.confirm("확인 버튼을 누르지 않고 창을 닫을 경우 평가가 저장되지 않습니다. \n정말 종료하겠습니까?")) {
      onClose();
      setActiveStep(0);
      setCompleted({});
    }
  }

  // stepper
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step: number) => () => {
    if (state.status === 3) {
      setActiveStep(step);
    } else {alert("평가를 완료해주세요.")}
  };
  // stepper

  const onFinish = () => {
    if (window.confirm("평가를 종료합니다. 평가 완료 후에도 기간 내에 수정이 가능합니다.")) {
      // 평가 종료
      EvaluationApi.UpdateEvaluationItem({...state, status: 3})
      .then(() => {
        onClose();
        setActiveStep(0);
        setCompleted({});
      })
      .catch(err => console.log(err, "err"))
    }
  }

  const handleComplete = () => {
    const newCompleted = completed;
    if (isLastStep() || activeStep === 0) {
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    } else {
      if (IsValidation(answerList)) {
        EvaluationApi.UpdateAnswers(answerList)
        .then(() => {
          newCompleted[activeStep] = true;
          setCompleted(newCompleted);
          handleNext();
        })
        .catch(err => console.log(err, "send answer"))
      } else alert("모든 필수 문항에 답해주세요.");
    }
  };

  const onAnswerList = (answer: Answer[]) => {
    setAnswerList(answer);
  }

  const getStepComponent = (label: string) => {
    switch (label) {
      case "시작":
        return <EvaluateTemplate data={state} kind={"header"}></EvaluateTemplate>;
      case "공통":
        return <EvaluateAnswer data={state} kind={1} onAnswerList={onAnswerList}></EvaluateAnswer>;
      case "파트 내 리더":
        return <EvaluateAnswer data={state} kind={state.type} onAnswerList={onAnswerList}></EvaluateAnswer>;
      case "파트 내 동료":
        return <EvaluateAnswer data={state} kind={state.type} onAnswerList={onAnswerList}></EvaluateAnswer>;
      case "회사 평가":
        return <EvaluateAnswer data={state} kind={6} onAnswerList={onAnswerList}></EvaluateAnswer>;
      case "공통2":
        return <EvaluateAnswer data={state} kind={5} onAnswerList={onAnswerList}></EvaluateAnswer>;
      case "종료":
        return <EvaluateTemplate data={state} kind={"footer"}></EvaluateTemplate>;
      default:
        return 'Unknown step';
    }
  }
  return (
    <Dialog open={open} fullScreen>
      <MuiDialogTitle disableTypography className={classes.title}>
        현재 평가 대상자 : {`${state.evaluateeTeam} ${state.evaluateeName}`}
        <IconButton onClick={onCloseIcon} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Stepper nonLinear activeStep={activeStep} className={classes.step}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={handleStep(index)} completed={completed[index]}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {getStepComponent(steps[activeStep])}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent='center'>
          {activeStep !== steps.length &&
            <>
              {activeStep === totalSteps() - 1 ?
                <PrimaryButton variant="contained" onClick={onFinish}>종료</PrimaryButton> :
                <SecondaryButton variant="contained" onClick={handleComplete}>확인</SecondaryButton>
              }
            </>
          }
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default EvaluatePage;
