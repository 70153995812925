import { Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { useState } from "react";
import { ErrorHandler, SurveyApi } from "../../../system/ApiService";
import { SurveyAnswer, SurveyItem } from "../../../system/types/type";
import PrimaryButton from "../../Common/PrimaryButton";
import SurveyAnswers from "./Survey/SurveyAnswers";

function IsValidation(data: SurveyAnswer[]) {
  let flag = true;

  for (let item of data) {
    if (item.surveyAnswerType === 1 || item.surveyAnswerType === 4) {
      if (item.choice === 0) {
        flag = false;
      }
    } else if (item.surveyAnswerType === 2) {
      if (item.text === null) {
        flag = false;
      }
    }
  }
  return flag;
}

interface SurveyPageProps {
  data: SurveyItem;
  open: boolean;
  onClose: () => void;
}

function SurveyPage({ data, open, onClose }: SurveyPageProps) {
  const [answerList, setAnswerList] = useState<SurveyAnswer[]>([]);

  const onFinish = () => {
    if (IsValidation(answerList)) {
        if (window.confirm("만족도 조사를 종료합니다.")) {
          SurveyApi.UpdateSurvey(answerList)
          .then(() => {
            SurveyApi.UpdateSurveyItem({ ...data, status: 1 }).then(() => {
              onClose();
            });
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          });
        }
    } else alert("모든 필수 문항에 답해주세요.");
  };

  const onAnswerList = (answer: SurveyAnswer[]) => {
    setAnswerList(answer);
  };

  return (
    <Dialog open={open} fullScreen>
      <DialogContent>
        <SurveyAnswers data={data} onAnswerList={onAnswerList}></SurveyAnswers>
        <br></br>
        <Grid container justifyContent="center">
          <PrimaryButton variant="contained" onClick={onFinish}>
            저장 후 결과보기
          </PrimaryButton>
        </Grid>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default SurveyPage;
