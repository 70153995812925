import { Button, Grid, Paper } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import MaterialTable, { MTableToolbar } from "material-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TABLE_ACTION, TABLE_HEADER } from "../../../styles/theme";
import { AuthApi, ConstantApi, ErrorHandler } from "../../../system/ApiService";
import { TABLEICONS } from "../../../system/Constants";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { AuthViewModel, Constant } from "../../../system/types/type";
import TitleDiv from "../../Common/TitleDiv";
import AuthModal from "./AuthModal";

function AdminTable() {
   const [constant, setConstant] = useState<Constant[]>([]);
   const authLookup: Object = constant.reduce((x, y) => ({ ...x, [y.value]: y.remark }), {});
   const [selectModal, setSelectModal] = useState<boolean>(false);
   const [data, setData] = useState<AuthViewModel[]>([]);

   useMemo(() => {
      ConstantApi.GetConstantList("Authorization", "Auth")
         .then((res) => {
            setConstant(res.data);
         })
         .catch((err) => {
            console.log(err, "err");
            let msg = ErrorHandler(err);
            alert(msg);
         });
   }, []);

   const onLoad = useCallback(() => {
      AuthApi.GetAuth()
         .then((res) => {
            setData(res.data);
         })
         .catch((err) => {
            console.log(err, "err");
            let msg = ErrorHandler(err);
            alert(msg);
         });
   }, []);

   useEffect(() => {
      onLoad();
   }, [onLoad]);

   const openSelectModal = () => {
      setSelectModal(true);
   };

   const closeSelectModal = () => {
      onLoad();
      setSelectModal(false);
   };

   return (
      <Grid container spacing={2}>
         <Grid item xs={12}>
            <TitleDiv title="관리자 설정"></TitleDiv>
         </Grid>
         <Grid item xs={8} style={{ minWidth: "1000px" }}>
            <MaterialTable
               title=""
               icons={TABLEICONS}
               columns={[
                  {
                     title: "No.",
                     field: "",
                     cellStyle: { width: "10%" },
                     align: "center",
                     render: (rowData) => {
                        return <>{data.indexOf(rowData) + 1}</>;
                     },
                  },
                  { title: "파트", field: "parentTeam", cellStyle: { width: "20%" }, editable: "never", align: "center" },
                  { title: "팀", field: "team", cellStyle: { width: "20%" }, editable: "never", align: "center" },
                  { title: "성명", field: "name", cellStyle: { width: "10%" }, editable: "never", align: "center" },
                  { title: "권한 범위", field: "authorization", cellStyle: { width: "30%" }, lookup: authLookup, align: "center" },
               ]}
               data={data || []}
               editable={{
                  onRowUpdate: (newData, oldData) =>
                     new Promise((resolve, reject) => {
                        setTimeout(() => {
                           AuthApi.UpdateAuth(newData).then(() => {
                              onLoad();
                           });
                           resolve(newData);
                        }, 100);
                     }),
                  onRowDelete: (oldData) =>
                     new Promise((resolve, reject) => {
                        setTimeout(() => {
                           AuthApi.DeleteAuth(oldData.id).then(() => {
                              onLoad();
                           });
                           resolve(data);
                        }, 100);
                     }),
               }}
               components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                  Toolbar: (props) => (
                     <Grid container justifyContent="flex-end" alignItems="center" style={{ backgroundColor: "#fafafa" }}>
                        <Grid item>
                           <MTableToolbar {...props} />
                        </Grid>
                        <Grid item>
                           <Button onClick={openSelectModal}>
                              <AddBox />
                           </Button>
                        </Grid>
                     </Grid>
                  ),
               }}
               options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: TABLE_HEADER,
                  actionsCellStyle: TABLE_ACTION,
                  padding: "dense",
               }}
               localization={LMATERIAL_TABLE}
            />
            <AuthModal open={selectModal} onClose={closeSelectModal}></AuthModal>
         </Grid>
      </Grid>
   );
}

export default AdminTable;
