import React, { CSSProperties, ReactElement } from "react";
import purify from "dompurify";
interface Props {
  data: string | undefined | null;
  className?: string | undefined;
  style?: CSSProperties | undefined;
}

function HtmlContent({ data, className, style }: Props): ReactElement {
  const isWhiteSpace = style && style["whiteSpace"];

  if (data) {
    return (
      <div
        aria-label="caution"
        style={isWhiteSpace ? style : { ...style, whiteSpace: "pre-wrap" }}
        className={className}
        dangerouslySetInnerHTML={{ __html: purify.sanitize(data, { ADD_ATTR: ["target"] }) }}
      ></div>
    );
  } else {
    return <div aria-label="No caution"></div>;
  }
}

export default HtmlContent;
