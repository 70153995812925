import { createStyles, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import SelectEvaluation from "../../components/Common/SelectEvaluation";
import TitleDiv from '../../components/Common/TitleDiv'
import AnswerTable from "../../components/Setting/Paper/AnswerTable";
import TemplateTable from "../../components/Setting/Paper/TemplateTable";
import { PRIMARY } from "../../styles/theme";

const useStyles = makeStyles((theme) =>
  createStyles({
    indicator: {
      backgroundColor: PRIMARY,
    },
  })
);

function PaperMain() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [evaluationID, setEvaluationID] = useState<number>(0);

  const onChange = (id: number) => {
    setEvaluationID(id)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  
  return (
    <div>
      <Grid item xs={12}>
        <TitleDiv title="평가지 설정"></TitleDiv>
      </Grid>
      <br></br>
      <Grid item xs={12}>
        <SelectEvaluation onChange={onChange}></SelectEvaluation>
      </Grid>
      <br></br>
      <Tabs 
        value={value} 
        onChange={handleChange} 
        classes={{ indicator: classes.indicator }}
      >
        <Tab label="평가지 템플릿 설정" value={0} />
        <Tab label="평가 문항 및 답안 설정" value={1} />
      </Tabs>
      <br></br>
      {value === 0 && <TemplateTable id={evaluationID}></TemplateTable>}
      {value === 1 && <AnswerTable id={evaluationID}></AnswerTable> }
    </div>
  )
}
export default PaperMain