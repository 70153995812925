import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";
import { SECONDARY } from "../../styles/theme";

const useStyles = makeStyles((theme) => ({
  secondaryButton: {
    border: SECONDARY,
    background: SECONDARY,
    color: "white",
    margin: "2px",
  },
}));

function SecondaryButton(props: ButtonProps): ReactElement {
  const classes = useStyles();
  return (
    <Button className={classes.secondaryButton} variant="text" {...props}>
      {props.children}
    </Button>
  );
}

export default SecondaryButton;
