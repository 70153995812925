import React, { useEffect, useState, useMemo } from "react";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";
import { makeStyles, TableBody, TableCell, TableRow, Grid, Typography } from "@material-ui/core";
import { Table } from "react-bootstrap";
import { EvaluationReportSummary, User } from "../../../../system/types/type";
import { PRIMARY } from "../../../../styles/theme";

interface MultipleChoiceReportSummaryProps {
   evaluationId: number;
   employee: User;
   index: number;
}

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: "800px",
      marginTop: "10px",
   },
   tableHeader: {
      textAlign: "center",
      fontSize: "1rem",
   },
   fontRed: {
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: "red",
   },
   fontBlue: {
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: "blue",
   },
}));

function MultipleChoiceReportSummary({ evaluationId, employee, index }: MultipleChoiceReportSummaryProps) {
   const classes = useStyles();
   const [data, setData] = useState<EvaluationReportSummary[]>([]);

   useEffect(() => {
      ReportApi.GetEvaluationReportSummary(evaluationId, employee.employeeId)
         .then((res) => {
            setData(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [evaluationId, employee]);

   const getText = (list: EvaluationReportSummary[]) => {
      for (var item of list) {
         var text = "-";
         for (var subItem of item.categories) {
            if (text !== "-") text += ", ";
            else text = "";
            text += subItem.category;
         }
         item.text = text;
      }
      return list;
   };

   const ersList: EvaluationReportSummary[] = useMemo(() => getText(data || []), [data]);

   if (ersList.length > 0) {
      return (
         <>
            <Typography
               variant="body1"
               style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "10px" }}
            >{`2-${index}.객관식 결과 요약`}</Typography>
            {/* 결과 요약 테이블 */}
            <Grid item xs={9}>
               <Table className={classes.table}>
                  <TableBody>
                     <TableRow>
                        <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "30%" }}>구분</TableCell>
                        <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "70%" }}>내용</TableCell>
                     </TableRow>
                     {ersList.map((x, index) => {
                        if (index >= 3) {
                           return (
                              <TableRow key={`ersList-${index}`}>
                                 <TableCell style={{ textAlign: "center" }}>{x.type}</TableCell>
                                 <TableCell style={{ textAlign: "center" }}>{x.text}</TableCell>
                              </TableRow>
                           );
                        } else {
                           return <></>;
                        }
                     })}
                  </TableBody>
               </Table>
            </Grid>
            <Grid item xs={3}></Grid>
         </>
      );
   } else {
      return <></>;
   }
}

export default MultipleChoiceReportSummary;
