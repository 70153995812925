import React, { useState, useMemo, useEffect } from "react";
import { EmployeeApi, ErrorHandler, EvaluationApi } from "../../../../system/ApiService";
import { useUserState } from "../../../../system/context/UserContext";
import { InitEvaluation, InitUser } from "../../../../system/types/initObject";
import { Evaluation, User } from "../../../../system/types/type";
import { Grid, Typography } from "@material-ui/core";
import TitleDiv from "../../../Common/TitleDiv";
import SelectEvaluation from "../../../Common/SelectEvaluation";
import SelectEmployeeSimple from "../../../Common/SelectEmployee";
import SelectEmployeeByTreeView from "../../../Common/SelectEmployeeByTreeView";
import EssayAnswerReportForAdmin from "../EssayAnswer/EssayAnswerReportForAdmin";
import EvaluateeInformation from "../common/EvaluateeInformation";
import PersonalMultipleChoiceReport from "../MultipleChoice/PersonalMultipleChoiceReport";
import EvaluationReportAsEvaluator from "./EvaluationReportAsEvaluator";

interface PersonalReportPageProps {
   id: number;
}

function PersonalReportPage({ id: evaluationId }: PersonalReportPageProps) {
   const user = useUserState();
   const [employee, setEmployee] = useState<User>({ ...InitUser, employeeId: user.employeeId });
   const [evaluatee, setEvaluatee] = useState<User>(InitUser);
   const [evaluation, setEvaluation] = useState<Evaluation>({ ...InitEvaluation, id: evaluationId });
   const [empList, setEmpList] = useState<User[]>([]);

   useMemo(() => {
      // 전체사원 리스트 조회
      EmployeeApi.GetEmployee()
         .then((res) => {
            setEmpList(res.data);
         })
         .catch((err) => {
            console.log(err);
            let msg = ErrorHandler(err);
            alert(msg);
         });
   }, []);

   useEffect(() => {
      if (employee.employeeId !== "") {
         EvaluationApi.GetEvaluateeByEmployeeId(evaluation.id, employee.employeeId)
            .then((res) => {
               if (!res.data.employeeId) {
                  setEvaluatee({ ...InitUser });
               } else {
                  EmployeeApi.GetEmployeeByEmployeeId(res.data.employeeId)
                     .then((emp) => {
                        setEvaluatee(emp.data);
                     })
                     .catch((err) => {
                        let msg = ErrorHandler(err);
                        alert(msg);
                     });
               }
            })
            .catch((error) => {
               let msg = ErrorHandler(error);
               alert(msg);
            });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [evaluation, employee]);

   const onChangeEvaluation = (evaluation: Evaluation) => {
      setEvaluation(evaluation);
   };

   const onChangeEmp = (data: User) => {
      if (data) {
         setEmployee(data);
      }
   };

   const nodeClicked = (args: any): void => {
      var employeeId = args.node.dataset.uid;
      var employee = empList.find((x) => x.employeeId === employeeId);
      if (employee !== undefined) setEmployee(employee);
   };

   return (
      <>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <TitleDiv title="개인별 결과"></TitleDiv>
            </Grid>
            <Grid item xs={6}>
               <SelectEvaluation onChangeEvaluation={onChangeEvaluation}></SelectEvaluation>
            </Grid>
            <Grid item xs={6}>
               <SelectEmployeeSimple onChange={onChangeEmp}></SelectEmployeeSimple>
            </Grid>
            <Grid item xs={2}>
               <SelectEmployeeByTreeView nodeClicked={nodeClicked}></SelectEmployeeByTreeView>
            </Grid>
            <Grid item xs={10}>
               <Grid container>
                  <Grid item xs={12}>
                     {evaluatee.employeeId === "" ? (
                        <Grid item xs={12}>
                           <Typography variant="h6">해당 평가를 진행하지 않은 임직원입니다.</Typography>
                        </Grid>
                     ) : (
                        <>
                           {/* 평가 대상자 정보 */}
                           <Grid item xs={9}>
                              <EvaluateeInformation employee={evaluatee}></EvaluateeInformation>
                           </Grid>
                           <Grid item xs={3}></Grid>
                           {/* 객관식 평가 결과 */}
                           <Grid item xs={12}>
                              <PersonalMultipleChoiceReport evaluationId={evaluation.id} employee={evaluatee}></PersonalMultipleChoiceReport>
                           </Grid>
                           {/* 주관식 수정 테이블 */}
                           <Grid item xs={12}>
                              <br></br>
                              <br></br>
                              <EssayAnswerReportForAdmin evaluationId={evaluation.id} employee={evaluatee}></EssayAnswerReportForAdmin>
                           </Grid>
                           {/* 평가 대상자가 진행한 평가 결과 */}
                           <Grid item xs={9}>
                              <EvaluationReportAsEvaluator evaluationId={evaluation.id} employee={evaluatee}></EvaluationReportAsEvaluator>
                           </Grid>
                           <Grid item xs={3}></Grid>
                        </>
                     )}
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </>
   );
}

export default PersonalReportPage;
