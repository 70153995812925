import { AuthorityUser, Lookup } from "./interface";
import { Auth, Answer, Choice, DeptTreeView, Evaluation, EvaluationItem, EvaluationItemViewModel, EvaluationTemplate, Question, User, ReservedMail, EvaluationFeedbackViewModel, SurveyChoice, EvaluationStatisticsTalbeInfo } from "./type";

export const InitAuthorityUser: AuthorityUser = {
   employeeId: "",
   employeeNo: "",
   name: "",
   gwName: "",
   gwMail: "",
   jobTitle: "",
   jobPosition: "",
   workplace: "",
   teamId: 0,
   teamName: "",
   parentTeamId: 0,
   parentTeamName: "",
   role: "",
   positionCode: "",
   roles: [],
 };

 export const InitUser: User = {
  employeeId: "",
  employeeNo: "",
  name: "",
  gwName: "",
  teamId: "",
  team: "",
  parentTeamId: "",
  parentTeam: "",
  positionCode: "",
  role: "",
  workplace: "",
  jobTitle: "",
  jobPosition: "",
  gwMail: "",
  joined: "",
  employmentForm: "",

 }

export const InitTemplate: EvaluationTemplate = {
  id: 0,
  evaluationId: 0,
  header: "",
  footer: "",
 };

export const InitDeptTreeView: DeptTreeView = {
  id: "root",
  name: "(주)인바디",
  subChild: [],
};

export const InitEvaluation: Evaluation = {
  id: 0,
  year: 0,
  half: "",
  title: "",
}

export const  InitEvaluationStatisticsTableInfo: EvaluationStatisticsTalbeInfo = {
  types: [],
  kinds: [],
}

export const InitEvaluationItem: EvaluationItem = {
  id: 0, 
  evaluationId: 0,
  evaluateeId: "",
  evaluateeTeamId: "",
  evaluatorId: "",
  evaluatorTeamId: "",
  type: 0,
  status: 0,
  collaboration: "",
  rejectReason: "",
  order: 0,
}
export const InitEvaluationItemViewModel: EvaluationItemViewModel = {
  id: 0, 
  evaluationId: 0,
  evaluateeId: "",
  evaluateeTeamId: "",
  evaluatorId: "",
  evaluatorTeamId: "",
  status: 0,
  collaboration: "",
  rejectReason: "",
  order: 0,
  type: 0,
  typeName: "",
  evaluateeName: "",
  evaluateeParentTeam:	"",
  evaluateeTeam:	"",
  evaluatorName: "",
  evaluatorParentTeam: "",
  evaluatorTeam:	"",
}

export const InitAnswer: Answer = {
  id: 0,
  evaluationItemId: 0,
  questionId: 0,
  answerType: 0,
  choice: 0,
  text: "",
}

export const InitQuestion: Question = {
  id: 0,
  evaluationId: 0,
  evaluationItemType: 0,
  order: 0,
  category: "",
  text: "",
  answerType: 0,
  choices: [],
  answer: InitAnswer,
}

export const InitChoice : Choice[] = [
  {
    id: 0,
    questionId: 0,
    text: "",
    order: 1,
  },
  {
    id: 0,
    questionId: 0,
    text: "",
    order: 2,
  },
  {
    id: 0,
    questionId: 0,
    text: "",
    order: 3,
  },
  {
    id: 0,
    questionId: 0,
    text: "",
    order: 4,
  },
  {
    id: 0,
    questionId: 0,
    text: "",
    order: 5,
  },
  {
    id: 0,
    questionId: 0,
    text: "",
    order: 6,
  },
]

export const InitAdmin: Auth = {
  id: 0,
  employeeId: "",
  authorization: "",
}

export const InitReservedMail: ReservedMail = {
  id: 0,
  project: "다면평가",
  creator: "",
  reservedDate: "",
  sendDate: "",
  sender: "",
  receiverEmail: "",
  ccEmail: "",
  bccEmail: "",
  title: "",
  contents: "",
  status: "T",
}

export const InitLookup: Lookup = {
  workplace: {},
  parentTeam: {},
  team:{},
  jobPosition: {},
  jobTitle:{},
  employmentForm:{}
}

export const InitFeedbackViewModel: EvaluationFeedbackViewModel = {
  id: 0,
  evaluationId: 0,
  employeeTeamId: 0,
  employeeId: "",
  context: "",
  comment: "",
  year: 0,
  half: "",
  title: "",
  parentTeam: "",
  team: "",
  name: "",
}

export const InitSurveyAnswer = {
  id: 0,
  surveyItemId: 0,
  surveyAnswerType: 0,
  surveyQuestionId: 0,
  choice: 0,
  text: "",
}

export const InitSurveyChoice : SurveyChoice[] = [
  {
    id: 0,
    surveyQuestionId: 0,
    text: "",
    order: 1,
  },
  {
    id: 0,
    surveyQuestionId: 0,
    text: "",
    order: 2,
  },
  {
    id: 0,
    surveyQuestionId: 0,
    text: "",
    order: 3,
  },
  {
    id: 0,
    surveyQuestionId: 0,
    text: "",
    order: 4,
  },
  {
    id: 0,
    surveyQuestionId: 0,
    text: "",
    order: 5,
  },
  {
    id: 0,
    surveyQuestionId: 0,
    text: "",
    order: 6,
  },
]

export const InitSurveyQuestion = {
  id: 0,
  evaluationId: 0,
  surveyAnswerType: 0,
  text: "",
  order: 0,
  surveyChoices: InitSurveyChoice,
  surveyAnswer: InitSurveyAnswer,
}