import React, { useEffect, useState } from "react";
import { EvaluationReportType, User } from "../../../../system/types/type";
import {  Grid } from "@material-ui/core";
import MultipleChoiceReportTable from "./MultipleChoiceReportTable";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";
import MultipleChoiceReportSummary from "./MultipleChoiceReportSummary";
import MultipleChoiceReportChart from "./MultipleChoiceReportChart";
import MultipleChoiceIntro from "./MultipleChoiceIntro";

interface MultipleChoiceReportProps {
   evaluationId: number;
   employee: User;
}

function MultipleChoiceReport({ evaluationId, employee }: MultipleChoiceReportProps) {
   const [types, setTypes] = useState<EvaluationReportType[]>([]);
   useEffect(() => {
      ReportApi.GetEvaluationReportTypes(evaluationId, employee.employeeId, 0)
         .then((res) => {
            setTypes(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [evaluationId, employee]);

   return (
      <>
         {/* 객관식 평가 결과 설명 */}
         <MultipleChoiceIntro></MultipleChoiceIntro>
         {/* 객관식 평가 결과 */}
         {types.map((type, index) => {
            return (
               <>
                  <MultipleChoiceReportTable
                     evaluationId={evaluationId}
                     employee={employee}
                     index={index}
                     type={type}
                     key={`MultipleChoiceReportTable-${index}`}
                  ></MultipleChoiceReportTable>
               </>
            );
         })}
         <Grid container>
            {types.map((type, index) => {
               return (
                  <Grid item xs={6}>
                     <MultipleChoiceReportChart
                     evaluationId={evaluationId}
                     employee={employee}
                     index={index}
                     type={type}
                     key={`MultipleChoiceReportChart-${index}`}
                     ></MultipleChoiceReportChart>
                  </Grid>
                  );
               })}
         </Grid>
         {/* 객관식 결과 요약 */}
         <MultipleChoiceReportSummary evaluationId={evaluationId} employee={employee} index={types.length+1}></MultipleChoiceReportSummary>
      </>
   );
}

export default MultipleChoiceReport;
