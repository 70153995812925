import { ReactElement, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Content from "../pages/Layout/Content";
import Footer from "../pages/Layout/Footer";
import Header from "../pages/Layout/Header";
import LandingMain from "../pages/Layout/LandingMain";
import ConfirmMain from "../pages/Progress/ConfirmMain";
import SelectMain from "../pages/Progress/SelectMain";
import EvaluateMain from "../pages/Progress/EvaluateMain";
import EvaluateeMain from "../pages/Setting/EvaluateeMain";
import EvaluationMain from "../pages/Setting/EvaluationMain";
import ItemMain from "../pages/Setting/ItemMain";
import PaperMain from "../pages/Setting/PaperMain";
import { MyStyle } from "../styles/theme";
import { CommonRoutes, ImprovingRoutes, ProgressRoutes, ResultRoutes, SettingRoutes } from "../system/types/type";
import ConfirmAdminMain from "../pages/Progress/ConfirmAdminMain";
import AuthMain from "../pages/Setting/AuthMain";
import { IsAdmin, IsAdmin1, IsUser } from "../system/types/interface";
import { useUserState } from "../system/context/UserContext";
import MailMain from "../pages/Setting/MailMain";
import StatusMain from "../pages/Progress/StatusMain";
import ReportMain from "../pages/Result/ReportMain";
import SettingMain from "../pages/Result/SettingMain";
import FeedbackAdminMain from "../pages/Result/FeedbackAdminMain";
import SurveyMain from "../pages/Improving/SurveyMain";
import ResultMain from "../pages/Improving/ResultMain";
import ListMain from "../pages/Improving/ListMain";
import FeedbackMain from "../pages/Result/FeedbackMain";
import ReportAdminMain from "../pages/Result/ReportAdminMain";
import PersonalMain from "../pages/Result/PersonalMain";
import JobTitleMain from "../pages/Result/JobTitleMain";
import jobPositionMain from "../pages/Result/jobPositionMain";
import CompanyMain from "../pages/Result/CompanyMain";

function Routes(): ReactElement {
  const classes = MyStyle();
  const user = useUserState();
  const [open, setOpen] = useState<boolean>(true);

  return (
    <>
      <div className={classes.bodyWrap}>
        {/* Header - Navigation Menu */}
        <Header setOpen={setOpen} open={open}></Header>
        {/* Body - Content */}
        <Content open={open}>
          <Switch>
            {/* 사이트 메인 페이지 */}
            <Route path={CommonRoutes.root} component={LandingMain} exact></Route>
            {IsAdmin(user.role) && (
              <>
                {/* 설정 - 관리자1,2 */}
                <Route path={SettingRoutes.Evaluation} component={EvaluationMain} exact></Route>
                <Route path={SettingRoutes.Evaluatee} component={EvaluateeMain} exact></Route>
                <Route path={SettingRoutes.Paper} component={PaperMain} exact></Route>
                <Route path={SettingRoutes.Auth} component={AuthMain} exact></Route>
                <Route path={SettingRoutes.Item} component={ItemMain} exact></Route>
                <Route path={SettingRoutes.Mail} component={MailMain} exact></Route>
                {/* 진행 - 관리자1,2 */}
                <Route path={ProgressRoutes.Status} component={StatusMain} exact></Route>
                <Route path={ProgressRoutes.ConfirmAdmin} component={ConfirmAdminMain} exact></Route>
                {/* 개선 - 관리자1,2 */}
                <Route path={ImprovingRoutes.List} component={ListMain} exact></Route>
                {/* 결과/개선 - 관리자1 */}
                
                {IsAdmin1(user.role) && (
                  <>
                    {/* 결과 */}
                    <Route path={ResultRoutes.Setting} component={SettingMain} exact></Route>
                    <Route path={ResultRoutes.Personal} component={PersonalMain} exact></Route>
                    <Route path={ResultRoutes.JobTitle} component={JobTitleMain} exact></Route>
                    <Route path={ResultRoutes.JobPosition} component={jobPositionMain} exact></Route>
                    <Route path={ResultRoutes.Company} component={CompanyMain} exact></Route>
                    <Route path={ResultRoutes.Report} component={ReportMain} exact></Route>
                    <Route path={ResultRoutes.ReportAdmin} component={ReportAdminMain} exact></Route>
                    <Route exact></Route>
                    <Route path={ResultRoutes.FeedbackAdmin} component={FeedbackAdminMain} exact></Route>
                    {/* 개선 */}
                    <Route path={ImprovingRoutes.Survey} component={SurveyMain} exact></Route>
                    <Route path={ImprovingRoutes.Result} component={ResultMain} exact></Route>
                  </>
                )}
              </>
            )}
            {IsUser(user.role) && (
              <>
                {/* 진행 - 사용자 */}
                <Route path={ProgressRoutes.Select} component={SelectMain} exact></Route>
                <Route path={ProgressRoutes.Confirm} component={ConfirmMain} exact></Route>
                <Route path={ProgressRoutes.Evaluate} component={EvaluateMain} exact></Route>
                {/* 결과 - 사용자 */}
                <Route path={ResultRoutes.Report} component={ReportMain} exact></Route>
                <Route path={ResultRoutes.Feedback} component={FeedbackMain} exact></Route>
              </>
            )}
          </Switch>
        </Content>
      </div>
      {/* Footer */}
      <Footer></Footer>
    </>
  );
}

export default Routes;
