import React, { useEffect, useRef, useState } from "react";
import {
   createStyles,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControlLabel,
   Grid,
   makeStyles,
   Switch,
   TextField,
   Theme,
} from "@material-ui/core";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";
import { Email, ReservedMail } from "../../../system/types/type";
import { ErrorHandler, MailApi } from "../../../system/ApiService";
import { InitReservedMail } from "../../../system/types/initObject";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import Editor from "../../Common/Editor";
import SelectEmail from "../../Common/SelectEmail";
import { useUserState } from "../../../system/context/UserContext";
import HtmlContent from "../../Common/HtmlContent";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      formControl: {
         minWidth: 240,
      },
      textArea: {
         width: "100%",
         minHeight: "150px",
         padding: "10px",
      },
      inputDate: {
         border: "black",
         borderBottom: "1px black solid",
         "&:focus": {
            border: "0.5px solid green",
         },
      },
   })
);

interface MailModalProps {
   id: number;
   open: boolean;
   onClose: () => void;
}

function MailModal({ id: mailId, open, onClose }: MailModalProps) {
   const classes = useStyles();
   const user = useUserState();
   const refContents = useRef<Editor>(null);
   const [check, setCheck] = useState<boolean>(true);
   const [data, setData] = useState<ReservedMail>(InitReservedMail);

   useEffect(() => {
      if (mailId !== 0) {
         MailApi.GetReservedMail(mailId)
            .then((res) => {
               setData(res.data);
               if(res.data.status === "T"){
                  setCheck(true);
               }else{
                  setCheck(false);
               }
            })
            .catch((err) => {
               console.log(err, "get mail info");
               let msg = ErrorHandler(err);
               alert(msg);
            });
      } else setData({ ...InitReservedMail, creator: user.gwMail });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [mailId]);

   const onCheck = () => {
      setCheck(!check);
   };

   const onDate = (e: MaterialUiPickersDate) => {
      setData({
         ...data,
         reservedDate: moment(e).format("YYYY-MM-DD HH:mm"),
      });
   };

   const onChangeEmail = (mail: Email[], type: "cc" | "to" | "bcc") => {
      let list = mail.map((x) => x.email);
      if (type === "cc") {
         setData({
            ...data,
            ccEmail: list.join(),
         });
      } else if(type === "to"){
         setData({
            ...data,
            receiverEmail: list.join(),
         });
      } else if(type === "bcc"){
        setData({
          ...data,
          bccEmail: list.join(),
       });
      }
   };

   const onChange = (e: any) => {
      setData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const saveMail = () => {
      let flag = true;

      if (data.sender === "" || data.receiverEmail === "" || data.title === "") {
         flag = false;
      }

      if (flag) {
         // 예약 발송
         if (check) {
            if (mailId === 0) {
               MailApi.InsertReservedMail({ ...data, contents: refContents.current?.getContent() || "" })
                  .then(() => onClose())
                  .catch((err) => {
                     console.log(err);
                     let msg = ErrorHandler(err);
                     alert(msg);
                  });
            } else {
               MailApi.UpdateReservedMail({ ...data, contents: refContents.current?.getContent() || "" })
                  .then(() => onClose())
                  .catch((err) => {
                     console.log(err);
                     let msg = ErrorHandler(err);
                     alert(msg);
                  });
            }
         } else {
            // 바로 발송
            MailApi.SendEmailNow({ ...data, contents: refContents.current?.getContent() || "" })
               .then(() => onClose())
               .catch((err) => {
                  console.log(err);
                  let msg = ErrorHandler(err);
                  alert(msg);
               });
         }
      } else alert("메일 양식을 모두 작성해주세요.");
   };

   return (
      <>
         <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle></DialogTitle>
            <DialogContent>
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <TextField
                        fullWidth
                        label="보내는 사람 이름"
                        name="sender"
                        value={data.sender}
                        onChange={onChange}
                        disabled={data.status !== "T"}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <SelectEmail type={"to"} status={data.status} init={data.receiverEmail} onChange={onChangeEmail}></SelectEmail>
                  </Grid>
                  <Grid item xs={12}>
                     <SelectEmail type={"cc"} status={data.status} init={data.ccEmail} onChange={onChangeEmail}></SelectEmail>
                  </Grid>
                  <Grid item xs={12}>
                     <SelectEmail type={"bcc"} status={data.status} init={data.bccEmail} onChange={onChangeEmail}></SelectEmail>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField fullWidth label="메일 제목" name="title" value={data.title} onChange={onChange} disabled={data.status !== "T"} />
                  </Grid>
                  <Grid item xs={12}>
                     {data.status !== "T" ? (
                        <HtmlContent style={{ border: "1px black grey", padding: "3px" }} data={data.contents || ""}></HtmlContent>
                     ) : (
                        <Editor content={data.contents || ""} ref={refContents}></Editor>
                     )}
                  </Grid>
                  <Grid item xs={12}>
                     <FormControlLabel
                        control={<Switch checked={check} onChange={onCheck} name="check" disabled={data.status !== "T"} />}
                        label="예약발송"
                     />
                     {check && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                           <DateTimePicker
                              value={new Date(data.reservedDate) || new Date()}
                              minDate={"2022-01-01 00:00"}
                              format="yyyy-MM-dd HH:mm"
                              className={classes.inputDate}
                              style={{ textAlign: "center" }}
                              onChange={(e: MaterialUiPickersDate) => onDate(e)}
                              helperText={""}
                              disabled={data.status !== "T"}
                              showTodayButton
                           />
                        </MuiPickersUtilsProvider>
                     )}
                  </Grid>
               </Grid>
            </DialogContent>
            <DialogActions>
               <SecondaryButton onClick={onClose}>CANCEL</SecondaryButton>
               <PrimaryButton onClick={saveMail}>SAVE</PrimaryButton>
            </DialogActions>
         </Dialog>
      </>
   );
}

export default MailModal;
