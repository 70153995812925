import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";
import { EvaluationItemViewModel, ModalType } from "../../../system/types/type";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { useEffect, useState } from "react";
import { InitEvaluationItemViewModel } from "../../../system/types/initObject";

interface ConfirmModalProps {
  state: EvaluationItemViewModel;
  type: ModalType;
  open: boolean;
  onClose: () => void;
}

function ConfirmModal({ type, state, open, onClose }: ConfirmModalProps) {
  const [data, setData] = useState<EvaluationItemViewModel>(InitEvaluationItemViewModel);

  useEffect(() => {
    setData(state)
  }, [state])

  const onChange = (event: any) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  };

  const onChengeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) === 1){
      setData({
        ...data,
        [event.target.name]: parseInt(event.target.value),
        rejectReason: "",
      })
    } else {
      setData({
        ...data,
        [event.target.name]: parseInt(event.target.value)
      })
    }
  }

  const onSave = () => {
    if (data.status === 0) {
      alert("평가 여부를 선택해주세요.")
    } else if (data.status === 2 && data.rejectReason === "") {
      alert("평가 불가 사유를 입력해주세요.")
    } else {
      EvaluationApi.UpdateEvaluationItem(data)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.log(err, "save");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>평가대상자 확정</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>  
                평가 대상 : {data.evaluateeName} ({data.evaluateeTeam})
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="status" name="status" value={data.status} onChange={onChengeRadio}>
                <FormControlLabel value={1} control={<Radio />} label="확인" />
                <FormControlLabel value={2} control={<Radio />} label="삭제" disabled={data.evaluatorName === data.evaluateeName || data.evaluateeName === "인바디"} />
              </RadioGroup>
            </FormControl>
            </Grid>
            <Grid item xs={12}>
              {data.status === 2 && 
                <TextField
                  label="평가 불가 사유"
                  name="rejectReason"
                  value={data.rejectReason}
                  onChange={onChange}
                  style={{ width: "100%" }}
                  multiline
                ></TextField>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={onClose}>CANCEL</SecondaryButton>
          <PrimaryButton onClick={onSave}>SAVE</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmModal;
