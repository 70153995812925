import AuthTable from "../../components/Setting/Auth/AuthTable";

function AuthMain() {
  return (
    <>
      <AuthTable></AuthTable>
    </>
  )
}

export default AuthMain;