import { Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { SurveyAnswer, SurveyQuestion } from "../../system/types/type";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "100%",
    minHeight: "150px",
    padding: "10px",
    border: "none",
    outline: "none",
  },
}));

interface ShortAnswerSurveyProps {
  data: SurveyQuestion;
  onChange: (answer: SurveyAnswer) => void;
}

function ShortAnswerSurvey({ data, onChange }: ShortAnswerSurveyProps) {
  const classes = useStyles();
  const [value, setValue] = useState(data.surveyAnswer.text);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    onChange({ ...data.surveyAnswer, text: event.target.value });
  };

  return (
    <Grid>
      <textarea
        className={classes.textArea}
        placeholder="내용을 입력해주세요."
        name="text"
        value={value}
        onChange={handleChange}
      />
    </Grid>
  );
}

export default ShortAnswerSurvey;
