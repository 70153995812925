import SurveyTable from "../../components/Improving/Survey/SurveyTable";

function SurveyMain() {
  return (
    <>
      <SurveyTable></SurveyTable>
    </>
  );
}
export default SurveyMain;
