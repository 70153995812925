/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Grid, Table, TableContainer, Paper, makeStyles, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import TitleDiv from '../../components/Common/TitleDiv'
import { EvaluationApi } from '../../system/ApiService';
import { EvaluationTerm } from '../../system/types/type';
import { IsUser } from '../../system/types/interface';
import { useUserState } from '../../system/context/UserContext';
import { PRIMARY } from '../../styles/theme';

const useStyles = makeStyles({
  head: {
    backgroundColor: PRIMARY,
  },
  headCell: {
    color: "white",
    fontWeight: "bold",
  },
  table: {
    minWidth: 650,
  },
});

function LandingMain() {
  const classes = useStyles();
  const user = useUserState();
  const [data, setData] = useState<EvaluationTerm[]>([])
  const [title, setTitle] = useState<string>("");
  const onlyAdmin = ['평가집단 인사팀 중간 점검', '평가집단 인사팀 최종 확정', '결과 확인 및 보고서 검토', '피드백 진행']

  useEffect(() => {
    EvaluationApi.GetEvaluationList()
    .then((res) => {
      setTitle(res.data[0].title)
      EvaluationApi.GetTerm(res.data[0].id)
      .then((res) => {
        setData(res.data)
      })
    })
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title={title}></TitleDiv>
      </Grid>
      <Grid item xs={8} style={{ minWidth: "1000px"}}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell className={classes.headCell} align="center">시작일자</TableCell>
                <TableCell className={classes.headCell} align="center">종료일자</TableCell>
                <TableCell className={classes.headCell} align="center">내용</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {IsUser(user.role) ? 
                <>
                  {data.map((row) => {
                    if (!onlyAdmin.includes(row.term)) {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center">{row.started}</TableCell>
                          <TableCell align="center">{row.ended}</TableCell>
                          <TableCell align="center">{row.term}</TableCell>
                        </TableRow>
                      )
                    }
                  })}
                </> : 
                <>
                  {data.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.started}</TableCell>
                      <TableCell align="center">{row.ended}</TableCell>
                      <TableCell align="center">{row.term}</TableCell>
                    </TableRow>
                  ))}
                </>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default LandingMain