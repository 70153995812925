import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { TABLEICONS } from '../../../system/Constants';
import { EvaluationItemViewModel, User } from '../../../system/types/type';
import { ErrorHandler, EvaluationApi } from '../../../system/ApiService';
import { LMATERIAL_TABLE } from '../../../system/Localization';
import { Paper } from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import SelectModal from '../Select/SelectModal';
import { TABLE_ACTION, TABLE_HEADER } from '../../../styles/theme';

interface EvaluatorAdminProps {
  id: number,
  user: User,
}

function EvaluatorAdmin({id: evaluationID, user}: EvaluatorAdminProps) {
  const status = {0: "선택", 1: "확인", 2: "삭제"}
  const [evaluator, setEvaluator] = useState<EvaluationItemViewModel[]>([]);
  const [selectModal, setSelectModal] = useState<boolean>(false);

  const onLoad = useCallback(() => {
    if (evaluationID && user.employeeId) {
      EvaluationApi.GetEvaluationItemList(evaluationID, "evaluator", user.employeeId, 0)
      .then((res) => {
        setEvaluator(res.data);
      })
      .catch((err) => {
        console.log(err,"err");
        let msg = ErrorHandler(err);
        alert(msg);
      })
    } 
  }, [evaluationID, user.employeeId])

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const openSelectModal = () => {
    if (evaluationID !== 0) {
      setSelectModal(true)
    } else {
      alert("평가를 선택해주세요.")
    }
  };
  
  const closeSelectModal = () => {
    setSelectModal(false)
    onLoad();
  };

  return (
    <>
      <MaterialTable
        title={`내가 평가할 사람(${evaluator.length})`}
        icons={TABLEICONS}
        columns={[
          { title: 'No.', field: '', cellStyle: { width: "2%" },
          render: rowData => {
            return (
              <>{evaluator.indexOf(rowData) + 1}</>
              )
            }
          },
          { title: '파트', field: 'evaluateeParentTeam', cellStyle: { width: "13%" }, align: "center", },
          { title: '팀', field: 'evaluateeTeam', cellStyle: { width: "13%" }, align: "center", },
          { title: '성명', field: 'evaluateeName', cellStyle: { width: "10%" }, align: "center", },
          { title: '확인/삭제', field: 'status', cellStyle: { width: "12%" }, lookup: status, align: "center",},
          { title: '협업 내용', field: 'collaboration', cellStyle: { width: "25%" }, filtering: false },
          { title: '평가 불가 사유', field: 'rejectReason', cellStyle: { width: "25%" }, filtering: false},
        ]}
        data={evaluator || []}
        components={{
          Container: props => <Paper {...props} elevation={0}/>,
          Toolbar: props => (
            <div style={{backgroundColor: '#fafafa'}}>
              <MTableToolbar {...props} />
            </div>
          )
        }}
        actions={[
          {
            icon: () => <AddBox/>,
            isFreeAction: true,
            onClick: () => openSelectModal()
          }
        ]}
        editable={{
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              if (user.name !== oldData.evaluateeName || oldData.evaluateeName === "인바디") {
                setTimeout(() => {
                  EvaluationApi.DeleteEvaluationItem(oldData.id)
                  .then(() => {
                    onLoad();
                  })
                }, 100)
              } else {alert("본인 평가는 삭제할 수 없습니다.")}
              resolve(evaluator);
            }),
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          headerStyle: TABLE_HEADER,
          actionsCellStyle: TABLE_ACTION,
          padding: "dense",
          filtering: true,
        }}
        localization={LMATERIAL_TABLE}
      />
      <SelectModal
        id={evaluationID}
        type={"Evaluator"}
        user={user}
        open={selectModal}
        onClose={closeSelectModal}
      ></SelectModal>
    </>
  )
}


export default EvaluatorAdmin