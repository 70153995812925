import React, { useEffect, useMemo } from "react";
import { EvaluationAnswer } from "../../../../system/types/type";
import { TableRow, TableCell, makeStyles } from "@material-ui/core";

interface EvaluationReportAsEvaluatorRowsProps {
   data: EvaluationAnswer[][];
}

const useStyles = makeStyles((theme) => ({
   categoryCell: {
      backgroundColor: "#DDDDDD",
      textAlign: "center",
   },
   scoreCell: {
      textAlign: "center",
   },
   sumCell: {
      textAlign: "center",
      fontWeight: "bold",
   }
}));

function EvaluationReportAsEvaluatorRows({ data }: EvaluationReportAsEvaluatorRowsProps) {
   const classes = useStyles();
   useEffect(() => {}, [data]);

   const sum = useMemo(() => {
      // 평가 접수 합산 및 100점 변환
      let sum1 = 0,
         sum2 = 0;
      if (data.length > 0) {
         data[0].forEach((x) => {
            sum1 += x.score;
         });
         sum1 *= 100 / (data[0].length * 10);
         data[1].forEach((x) => {
            sum2 += x.score;
         });
         sum2 *= 100 / (data[1].length * 10);
      }
      return [sum1, sum2];
   }, [data]);

   if (data.length > 0) {
      return (
         <>
            <TableRow>
               <TableCell rowSpan={2} className={classes.categoryCell}>회사평가</TableCell>
               {data[1].map((x, index) => (
                  <TableCell className={classes.categoryCell}>{x.category}</TableCell>
               ))}
            </TableRow>
            <TableRow>
               {data[1].map((x, index) => (
                  <TableCell className={classes.scoreCell}>{x.score}</TableCell>
               ))}
               <TableCell className={classes.sumCell}>{sum[1]}</TableCell>
            </TableRow>
            {data[0].length > 0 && (
               <>
                  <TableRow>
                     <TableCell rowSpan={2} className={classes.categoryCell}>리더평가</TableCell>
                     {data[0].map((x, index) => (
                        <TableCell className={classes.categoryCell}>{x.category}</TableCell>
                     ))}
                     <TableCell className={classes.categoryCell}></TableCell>
                  </TableRow>
                  <TableRow>
                     {data[0].map((x, index) => (
                        <TableCell className={classes.scoreCell}>{x.score}</TableCell>
                     ))}
                     <TableCell className={classes.sumCell}>{sum[0]}</TableCell>
                  </TableRow>
               </>
            )}
         </>
      );
   } else {
      return <></>;
   }
}

export default EvaluationReportAsEvaluatorRows;
