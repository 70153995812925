import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper, TextField } from "@material-ui/core";
import TitleDiv from "../../Common/TitleDiv";
import MaterialTable, { MTableToolbar } from "material-table";
import { TABLEICONS } from "../../../system/Constants";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { TABLE_ACTION, TABLE_HEADER } from "../../../styles/theme";
import SelectEvaluation from "../../Common/SelectEvaluation";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { EvaluationImprove } from "../../../system/types/type";

function ListTable() {
  const [data, setData] = useState<EvaluationImprove[]>([]);
  const [evaluationID, setEvaluationID] = useState<number>(0);

  const onChange = (id: number) => {
    setEvaluationID(id);
  };

  const onLoad = useCallback(() => {
    if (evaluationID !== 0) {
      EvaluationApi.GetImproveList(evaluationID)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  }, [evaluationID]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="평가 개선 방향 설정"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <SelectEvaluation onChange={onChange}></SelectEvaluation>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            {
              title: "No.",
              field: "",
              cellStyle: { width: "10%" },
              align: "center",
              render: (rowData) => {
                return <>{data.indexOf(rowData) + 1}</>;
              },
            },
            {
              title: "개선 사항",
              field: "improvement",
              cellStyle: { width: "60%" },
              editComponent: (props) => (
                <TextField
                  multiline
                  name="improvement"
                  value={props.value}
                  style={{ width: "100%" }}
                  onChange={(e) => props.onChange(e.target.value)}
                ></TextField>
              ),
            },
            {
              title: "반영 여부",
              field: "reflect",
              cellStyle: { width: "30%" },
              editComponent: (props) => (
                <TextField
                  multiline
                  name="reflect"
                  value={props.value}
                  style={{ width: "100%" }}
                  onChange={(e) => props.onChange(e.target.value)}
                ></TextField>
              ),
            },
          ]}
          data={data || []}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  EvaluationApi.InsertImprove({ ...newData, evaluationId: evaluationID }).then(() => {
                    setData([...data, newData]);
                  });
                  resolve(data);
                }, 100);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  EvaluationApi.UpdateImprove(newData).then(() => {
                    setData(data.map((x) => (x.id === newData.id ? newData : x)));
                  });
                  resolve(newData);
                }, 100);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  EvaluationApi.DeleteImprove(oldData.id)
                    .then(() => {
                      setData(() => data.filter((x) => x.id !== oldData.id));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  resolve(data);
                }, 100);
              }),
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div style={{ backgroundColor: "#fafafa" }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            actionsCellStyle: TABLE_ACTION,
            headerStyle: TABLE_HEADER,
            padding: "dense",
            pageSize: 20,
            exportButton: true,
          }}
          localization={LMATERIAL_TABLE}
        />
      </Grid>
    </Grid>
  );
}

export default ListTable;
