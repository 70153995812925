import React, {useEffect, useState} from 'react'
import { Grid, Typography } from "@material-ui/core";
import { ErrorHandler, ReportApi } from '../../../../system/ApiService';

interface CompanyReportSummaryProps{
   evaluationId: number;
}

function CompanyReportSummary({evaluationId } : CompanyReportSummaryProps) {
   const [data, setData] = useState<any>();

   useEffect(()=>{   
      ReportApi.GetCompanyEvaluationSummary(evaluationId).then((res)=>{
         setData(res.data);
      }).catch((error)=>{
         let msg = ErrorHandler(error);
         alert(msg);
      })
   },[evaluationId]);
   
   const GetText = (data: any[], kind: number) => {
      var result = "";
      for(var item of data){
         if(result !== "")
            result += ", ";
         if(kind === 0)
            result += item.category;
         else if(kind === 1)
            result += item.kind;
      }
      return <span style={{color: "red", fontWeight: "bold"}}>{result}</span>;
   }

  return (
    <>
      <Grid container style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "30px" }}>
         <Grid item xs={12}>
            <Typography variant="h6">{"4. 결과 요약"}</Typography>
         </Grid>
         {data !== undefined && 
         <>
            <Grid item xs={12}>
               <span>전 직원이 회사에 대해 가장 긍정적으로 평가한 영역은 </span>
               {GetText(data.highCategory, 0)}
               <span>, 가장 부정적으로 평가한 영역은 </span>
               {GetText(data.lowCategory, 0)}
               <span>입니다.</span>
            </Grid>
            <Grid item xs={12}>
            <span>전 직위 중 회사에 대해 긍정적으로 평가한 직위는 </span>
            {GetText(data.highJobPosition, 1)}
            <span>, 부정적으로 평가한 영직위는 </span>
            {GetText(data.lowJobPosition, 1)}
            <span>입니다.</span>
         </Grid>
         <Grid item xs={12}>
            <span>전 직챙 중 회사에 대해 긍정적으로 평가한 직책은 </span>
            {GetText(data.highJobTitle, 1)}
            <span>, 부정적으로 평가한 직책은 </span>
            {GetText(data.lowJobTitle, 1)}
            <span>입니다.</span>
         </Grid>
         </>
         }
      </Grid>
    </>
  )
}

export default CompanyReportSummary