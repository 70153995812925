import { Grid, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { ReactElement } from "react";
import { TABLE_ACTION, TABLE_HEADER } from "../../../styles/theme";
import { CONSTANT_TABLE, CONSTANT_TABLE_COLUMN, TABLEICONS } from "../../../system/Constants";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { Constant } from "../../../system/types/type";

interface ConstantTableGridProps {
  table: string;
  data: Constant[];
  refreshData: () => void;
}

function ItemTable({ table, data, refreshData }: ConstantTableGridProps): ReactElement {
  const ref = React.createRef<MaterialTable<Constant>>();

  return (
    <Grid container>
      <Grid item xs={8} style={{ minWidth: "1000px"}}>
        <MaterialTable
          ref={ref}
          icons={TABLEICONS}
          options={{
            actionsColumnIndex: -1,
            showTitle: false,
            filtering: true,
            padding: "dense",
            pageSize: 20,
            headerStyle: TABLE_HEADER,
            actionsCellStyle: TABLE_ACTION,
          }}
          columns={[
            { title: "ID", field: "", align: "center", cellStyle: { width: "10%" },
              render: rowData => {
                return (
                  <>{data.indexOf(rowData) + 1}</>
                )
              }
            },
            { title: "테이블명", field: "tableName", lookup: CONSTANT_TABLE, editable: "never", align: "center", cellStyle: { width: "15%" }, },
            { title: "항목명", field: "columnName", lookup: CONSTANT_TABLE_COLUMN[table], editable: "never", align: "center", cellStyle: { width: "15%" }, },
            { title: "이름", field: "value", editable: "never", align: "center", cellStyle: { width: "45%" }, },
            { title: "표시순서", field: "order", type: "numeric", align: "center", cellStyle: { width: "15%" }, },
          ]}
          data={data}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
            Toolbar: props => (
              <div style={{backgroundColor: '#fafafa'}}>
                <MTableToolbar {...props} />
              </div>
            )
          }}
          localization={LMATERIAL_TABLE}
        />
      </Grid>
    </Grid>
  );
}

export default ItemTable;
