import React, { useEffect, useState } from "react";
import {
	ChartComponent,
	SeriesCollectionDirective,
	SeriesDirective,
	Inject,
	Tooltip,
	Legend,
	LineSeries,
	Category,
	PolarSeries,
	RadarSeries,
	AxisModel,
	LegendSettingsModel,
	ColumnSeries,
	ChartSeriesType,
} from "@syncfusion/ej2-react-charts";
import {
	EvaluationReportType,
	EvaluationStatistics,
	User,
} from "../../../../system/types/type";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";
import BarChartIcon from '@material-ui/icons/BarChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

interface MultipleChoiceReportChartProps {
	evaluationId: number;
	employee: User;
	index: number;
	type: EvaluationReportType;
}

function PersonalMultipleChoiceReportChart({
	evaluationId,
	employee,
	index,
	type,
}: MultipleChoiceReportChartProps) {
	const [data, setData] = useState<any[]>();
	const [chartSeriesType, setChartSeriesType] = useState<ChartSeriesType>("Line");

	var chartInstance: ChartComponent | null;

	const changeVisibility = () => {
		if (chartInstance !== null && chartInstance !== undefined) {
			chartInstance.refresh();
		}
	};
	
	const onLoad = () => {
		let temp: any[] = [];

		// 평가 결과 input
		ReportApi.GetMultiChoiceReport(evaluationId, employee.employeeId, type.type)
			.then((res) => {
				for (var kind of res.data.kindList) {
					var searchKind = kind;
					if (kind.includes("직위")) searchKind = employee.jobPosition;
					else if (kind.includes("직책")) searchKind = employee.jobTitle;
					// eslint-disable-next-line no-loop-func
					let list = res.data.statisticsList.filter(
						// eslint-disable-next-line no-loop-func
						(x: EvaluationStatistics) => x.kind === searchKind
					);
					let scoreObj: any = {
						type: kind,
						data: [],
					};

					let index = 0;

					for (var item of list) {
						if (item.category) {
							if (item.score > 10) {
								scoreObj.data[index++] = {
									category: `${item.category}`,
									score: "",
								};
							} else if (
								(item.score !== 0 && kind !== "타인평가-본인평가") ||
								kind === "타인평가-본인평가"
							) {
								scoreObj.data[index++] = {
									category: `${item.category}`,
									score: item.score,
								};
							} else
								scoreObj.data[index++] = {
									category: `${item.category}`,
									score: null,
								};
						}
					}
					// 한개이상의 데이터를 취득했을때 Chart에 반영
					if (index > 0) {
						temp.push(scoreObj);
					}
				}
				setData(temp);
			})
			.catch((error) => {
				console.log(error);
				let msg = ErrorHandler(error);
				alert(msg);
			});
	}

	useEffect(() => {
		onLoad()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [evaluationId, type, employee]);

	useEffect(() => {
		changeVisibility();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartSeriesType])

	const primaryxAxis: AxisModel = { title: "category", valueType: "Category" };
	const primaryyAxis: AxisModel = {
		minimum: 0,
		maximum: 10,
		interval: 1,
		title: "score",
		labelFormat: "{value}",
	};
	const maker: any = {
		shape: "Diamond",
		isFilled: true,
		visible: true,
		width: 7,
		height: 7,
	};
	const legendSettings: LegendSettingsModel = {
		visible: true,
		position: "Top",
	};

	if (data !== undefined && data.length > 0) {
		return (
			<>
				{ /*e-btn-group*/}
				<div id="text" className="d-flex flex-row-reverse">
					<ButtonComponent onClick={() => setChartSeriesType("Column")}><BarChartIcon></BarChartIcon></ButtonComponent>
					<ButtonComponent onClick={() => setChartSeriesType("Line")}><ShowChartIcon></ShowChartIcon></ButtonComponent>
				</div>

				<ChartComponent
					id={`charts-${type.type}-${index}`}
					ref={chart => chartInstance = chart}
					primaryXAxis={primaryxAxis}
					primaryYAxis={primaryyAxis}
					legendSettings={legendSettings}
					tooltip={{ enable: true }}
					palettes={[
						"#3366CC",
						"#DC3912",
						"#FF9900",
						"#109618",
						"#990099",
						"#3B3EAC",
						"#0099C6",
						"#D47",
						"#316395",
						"#949",
					]}
					title={`${type.value} 항목의 본인/타인 인식 비교 그래프`}
					titleStyle={{ color: "red" }}
				>
					<Inject
						services={[
							ColumnSeries,
							RadarSeries,
							PolarSeries,
							LineSeries,
							Category,
							Legend,
							Tooltip,
						]}
					/>
					<SeriesCollectionDirective>
						{data !== undefined &&
							data.map((item, index) => {
								return (
									<SeriesDirective
										dataSource={item.data}
										xName="category"
										yName="score"
										type={chartSeriesType}
										name={item.type}
										marker={maker}
										width={2}
										visible={
											item.type !== "본인평가" &&
												item.type !== "타인평가" &&
												item.type !== "전체 평균"
												? false
												: true
										}
									></SeriesDirective>
								);
							})}
					</SeriesCollectionDirective>
				</ChartComponent>
			</>
		);
	} else {
		return <></>;
	}
}

export default PersonalMultipleChoiceReportChart;
