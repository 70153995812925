import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import TitleDiv from "../../../Common/TitleDiv";
import SelectEvaluation from "../../../Common/SelectEvaluation";
import { Evaluation, EvaluationStatisticsTalbeInfo } from "../../../../system/types/type";
import { InitEvaluation, InitEvaluationStatisticsTableInfo, InitUser } from "../../../../system/types/initObject";
import TitleDivInReport from "../../../Common/TitleDivInReport";
import EvaluationStatisticsTable from "../common/EvaluationStatisticsTable";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";
import EssayAnswerReportForAdmin from "../EssayAnswer/EssayAnswerReportForAdmin";
import { INBODY_EMPLOYEENO } from "../../../../system/Constants";
import CompanyReportSummary from "./CompanyReportSummary";

interface CompanyReportPageProps {
   id: number;
}

function CompanyReportPage({ id: evaluationId }: CompanyReportPageProps) {
   const [evaluation, setEvaluation] = useState<Evaluation>({ ...InitEvaluation, id: evaluationId });
   const [info, setInfo] = useState<EvaluationStatisticsTalbeInfo[]>([InitEvaluationStatisticsTableInfo, InitEvaluationStatisticsTableInfo]);

   useEffect(() => {
      let obj1: EvaluationStatisticsTalbeInfo = InitEvaluationStatisticsTableInfo;
      let obj2: EvaluationStatisticsTalbeInfo = InitEvaluationStatisticsTableInfo;
      ReportApi.GetEvaluationStatisticsKind(evaluationId)
         .then((res) => {
            let kinds1 = res.data.jobTitle;
            kinds1.push({ text: "전체 평균", value: "전체 평균" });
            obj1 = { ...obj1, kinds: kinds1 };
            let kinds2 = res.data.jobPosition;
            kinds2.push({ text: "전체 평균", value: "전체 평균" });
            obj2 = { ...obj2, kinds: kinds2 };
            ReportApi.GetEvaluationReportTypes(evaluationId, "total", 0)
               .then((res) => {
                  obj1 = { ...obj1, types: res.data };
                  obj2 = { ...obj2, types: res.data };
                  setInfo([obj1, obj2]);
               })
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [evaluationId]);

   const onChangeEvaluation = (evaluation: Evaluation) => {
      setEvaluation(evaluation);
   };

   return (
      <>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <TitleDiv title="회사 평가 결과"></TitleDiv>
            </Grid>
            <Grid item xs={12}>
               <SelectEvaluation onChangeEvaluation={onChangeEvaluation}></SelectEvaluation>
            </Grid>
            <Grid item xs={12}>
               <TitleDivInReport title={`${evaluation.title} 결과 조회(회사 평가)`}></TitleDivInReport>
            </Grid>
            <Grid item xs={12}>
               <EvaluationStatisticsTable
                  evaluation={evaluation}
                  evaluationItemType={6}
                  evaluationItemTypeName={"객관식 평가(직책별)"}
                  index={0}
                  kinds={info[0].kinds}
                  columnName={"jobTitle"}
               ></EvaluationStatisticsTable>
            </Grid>
            <Grid item xs={12}>
               <EvaluationStatisticsTable
                  evaluation={evaluation}
                  evaluationItemType={6}
                  evaluationItemTypeName={"객관식평가(직위별)"}
                  index={1}
                  kinds={info[1].kinds}
                  columnName={"jobPosition"}
               ></EvaluationStatisticsTable>
            </Grid>
            <Grid item xs={12}>
               {/* <br></br>
                <br></br> */}
               <EssayAnswerReportForAdmin
                  evaluationId={evaluation.id}
                  employee={{ ...InitUser, employeeId: INBODY_EMPLOYEENO }}
                  isCompany={true}
               ></EssayAnswerReportForAdmin>
            </Grid>
            <Grid item xs={12}>
                <CompanyReportSummary evaluationId={evaluation.id}></CompanyReportSummary>
            </Grid>
         </Grid>
      </>
   );
}

export default CompanyReportPage;
