import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";
import AnswerModalTemplate from "./AnswerModalTemplate";
import { Choice, Question } from "../../../system/types/type";
import { ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { InitChoice, InitQuestion } from "../../../system/types/initObject";

interface AnswerModalProps {
   evaluationId: number;
   open: boolean;
   id?: number;
   type: "Edit" | "Add";
   onClose: () => void;
}

function AnswerModal({ evaluationId, open, id, type, onClose }: AnswerModalProps) {
   const [data, setData] = useState<Question>(InitQuestion);
   const [choice, setChoice] = useState<Choice[]>(InitChoice);

   const onLoad = useCallback(() => {
      if (id && id !== 0) {
         EvaluationApi.GetQuestion(id)
            .then((res) => {
               setData(res.data);
               if (res.data.choices.length > 0) {
                  setChoice(res.data.choices);
               } else {
                  setChoice(InitChoice);
               }
            })
            .catch((err) => {
               console.log(err, "err");
               let msg = ErrorHandler(err);
               alert(msg);
            });
      } else {
         setData(InitQuestion);
         setChoice(InitChoice);
      }
   }, [id]);

   useEffect(() => {
      onLoad();
   }, [onLoad]);

   const onChange = (event: any) => {
      setData({
         ...data,
         [event.target.name]: event.target.value,
      });
   };

   const onChangeChoice = (event: any, index: number) => {
      const clonedData = [...choice];
      clonedData[index].text = event.target.value;
      setChoice(clonedData);
   };

   const saveAnswer = () => {
      let flag = true;

      if (evaluationId === 0) {
         flag = false;
      } else if ((data.answerType === 1 || data.answerType === 4) && choice.filter((x) => x.text === "").length >= 1) {
         flag = false;
      } else if (data.evaluationItemType === 0 || data.answerType === 0 || data.category === "" || data.text === "") {
         flag = false;
      }

      if (flag) {
         if (type === "Add") {
            EvaluationApi.InsertQuestion({ ...data, evaluationId: evaluationId, choices: choice })
               .then((res) => {
                  onClose();
               })
               .catch((err) => {
                  console.log(err, "Add");
                  let msg = ErrorHandler(err);
                  alert(msg);
               });
         } else if (type === "Edit") {
            EvaluationApi.UpdateQuestion({ ...data, evaluationId: evaluationId, choices: choice })
               .then((res) => {
                  onClose();
               })
               .catch((err) => {
                  console.log(err, "Edit");
                  let msg = ErrorHandler(err);
                  alert(msg);
               });
         }
      } else {
         alert("평가명 혹은 문항 설정 항목을 모두 입력했는지 확인해주세요.");
      }
   };

   return (
      <>
         <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>평가 문항 설정</DialogTitle>
            <DialogContent>
               <AnswerModalTemplate data={data} choice={choice} onChange={onChange} onChangeChoice={onChangeChoice}></AnswerModalTemplate>
            </DialogContent>
            <DialogActions>
               <SecondaryButton onClick={onClose}>CANCEL</SecondaryButton>
               <PrimaryButton onClick={saveAnswer}>SAVE</PrimaryButton>
            </DialogActions>
         </Dialog>
      </>
   );
}

export default AnswerModal;
