import React, { useCallback, useEffect, useState } from 'react'
import { Grid, MenuItem, Paper, Select, TextField } from '@material-ui/core'
import TitleDiv from '../../Common/TitleDiv'
import MaterialTable, { MTableToolbar } from 'material-table';
import { TABLEICONS } from '../../../system/Constants';
import EvaluationPeriodTable from './EvaluationPeriodTable';
import { Evaluation } from '../../../system/types/type';
import { ErrorHandler, EvaluationApi } from '../../../system/ApiService';
import { LMATERIAL_TABLE } from '../../../system/Localization';
import { TABLE_ACTION, TABLE_HEADER } from '../../../styles/theme';

function EvaluationTable() {
  const [id, setId] = useState<number>();
  const [data, setData] = useState<Evaluation[]>([]);
  const onClickRow = (id: number) => {
    setId(id)
  }

  const onLoad = useCallback(() => {
    EvaluationApi.GetEvaluationList()
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err,"err");
      let msg = ErrorHandler(err);
        alert(msg);
    })
  }, [])

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  function checkValidation(data: Evaluation) {
    if (data.year === undefined) {
       alert("연도를 입력해주세요.");
       return false;
    } else if (data.half === undefined) {
       alert("반기를 선택해주세요.");
       return false;
    } else if (data.title === undefined) {
       alert("평가명을 입력해주세요.");
       return false;
    } 
    return true;
 }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title='평가 생성 및 기간 설정'></TitleDiv>
      </Grid>
      <Grid item xs={8} style={{ minWidth: "1000px"}}>
        <MaterialTable
          title="평가 생성"
          icons={TABLEICONS}
          columns={[
            { title: 'No.', field: '', cellStyle: { width: "5%" }, align: "center",
              render: rowData => {
                return (
                  <>{data.indexOf(rowData) + 1}</>
                )
              } 
            },
            { title: '연도', field: 'year', cellStyle: { width: "15%" }, align: "center",
              editComponent: props => (
                <TextField
                  type="number"
                  value={props.value}
                  style={{ width: "100%" }}
                  onChange={e => props.onChange(e.target.value)}
                ></TextField>
              )
            },
            { title: '반기', field: 'half', cellStyle: { width: "15%" }, align: "center",
              editComponent: props => (
                <Select
                  value={props.value}
                  style={{ width: "100%" }}
                  onChange={e => props.onChange(e.target.value)}
                >
                  <MenuItem value={"상반기"}>상반기</MenuItem>
                  <MenuItem value={"하반기"}>하반기</MenuItem>
                </Select>
              )
            },
            { title: '평가명', field: 'title', cellStyle: { width: "50%" },
              editComponent: props => (
                <TextField
                  multiline
                  value={props.value}
                  style={{ width: "100%" }}
                  onChange={e => props.onChange(e.target.value)}
                ></TextField>
              )
            },
          ]}
          data={data}
          onRowClick={(event, rowData) => {
            if (rowData) {
              onClickRow(rowData.id)
            }
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (checkValidation(newData)) {
                    EvaluationApi.InsertEvaluation(newData)
                    .then((res) => {
                      EvaluationApi.AddTerm(res.data.id)
                      .then(() => {
                        onLoad();
                      })
                    })
                    resolve(data);
                  } else {
                    reject()
                  }
                }, 100)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (checkValidation(newData)) {
                    EvaluationApi.UpdateEvaluation(newData)
                    .then(() => {
                      onLoad();
                    })
                    resolve(newData)
                  } else {
                    reject();
                  }
                }, 100)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  EvaluationApi.DeleteEvaluation(oldData.id)
                  .then(() => {
                    onLoad();
                  })
                  resolve(data);
                }, 100)
              }),
          }}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
            Toolbar: props => (
              <div style={{backgroundColor: '#fafafa'}}>
                <MTableToolbar {...props} />
              </div>
            )
          }}
          options={{
            actionsColumnIndex: -1,
            actionsCellStyle: TABLE_ACTION,
            headerStyle: TABLE_HEADER,
            padding: "dense",
            pageSize: 5,
          }}
          localization={LMATERIAL_TABLE}
        />
      </Grid>
      {id && 
      <Grid item xs={8} style={{ minWidth: "1000px"}}>
        <EvaluationPeriodTable id={id}></EvaluationPeriodTable>  
      </Grid>
      }
    </Grid>
  )
}


export default EvaluationTable