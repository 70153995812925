import React from 'react'
import CompanyReportPage from '../../components/Result/Report/Company/CompanyReportPage'
import { usePageState } from '../../system/context/PageContext';

function CompanyMain() {
  const page = usePageState();

  return (
    <CompanyReportPage id={page[6].evaluationId}></CompanyReportPage>
  )
}

export default CompanyMain