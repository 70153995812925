import React from 'react'
import { Typography, makeStyles, TableBody, TableCell, TableRow } from "@material-ui/core";
import { Table } from 'react-bootstrap';
import { PRIMARY } from '../../../../styles/theme';
import { User } from '../../../../system/types/type';


interface EvaluateeInformationProps{
   employee: User;
}

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: "800px",
      marginTop: "10px"
   },
   tableHeader: {
     textAlign: "center",
     fontSize: "1rem",
   },
 }));

function EvaluateeInformation({employee}:EvaluateeInformationProps) {
   const classes = useStyles();

  return (
   <>
   <Typography variant="h6">1. 평가 대상자 정보</Typography>
    <Table className={classes.table}>
      <TableBody>
         <TableRow>
            <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "15%" }}>성명</TableCell>
            <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "30%" }}>소속</TableCell>
            <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "25%" }}>직위</TableCell>
            <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "30%" }}>직책</TableCell>
         </TableRow>
         <TableRow>
            <TableCell style={{textAlign: "center"}}>{employee.name}</TableCell>
            <TableCell style={{textAlign: "center"}}>{`${employee.parentTeam}`}</TableCell>
            <TableCell style={{textAlign: "center"}}>{employee.jobPosition}</TableCell>
            <TableCell style={{textAlign: "center"}}>{employee.jobTitle}</TableCell>
         </TableRow>
      </TableBody>
    </Table>
   </>
  )
}

export default EvaluateeInformation