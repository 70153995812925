import React, { useCallback, useEffect, useState } from "react";
import { ErrorHandler, ReportApi } from "../../../system/ApiService";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { Grid, Typography } from "@material-ui/core";
import TitleDiv from "../../Common/TitleDiv";
import SelectEvaluation from "../../Common/SelectEvaluation";
import { MultiSelectComponent, CheckBoxSelection, Inject, MultiSelect } from "@syncfusion/ej2-react-dropdowns";
import PrimaryButton from "../../Common/PrimaryButton";
import SubTitleDiv from "../../Common/SubTitleDiv";
import { Alert } from "react-bootstrap";
import HelpIcon from '@material-ui/icons/Help';

function ResultSettingTable() {
   const loadingDispatch = useLoadingDispatch();
   const [evaluationID, setEvaluationID] = useState<number>(0);
   const [data, setData] = useState<any>();
   const [jobTitle, setJobTitle] = useState<string[]>([]);
   const [jobPosition, setJobPosition] = useState<string[]>([]);

   let jobTitleObj: MultiSelect;
   let jobPositionObj: MultiSelect;

   const fields: object = { text: "text", value: "value" };

   const onChange = (id: number) => {
      setEvaluationID(id);
   };

   const onLoad = useCallback(() => {
      if (evaluationID !== 0) {
         ReportApi.GetEvaluationStatisticsKind(evaluationID)
            .then((res) => {
               setData(res.data);
            })
            .catch((err) => {
               console.log(err, "err");
               let msg = ErrorHandler(err);
               alert(msg);
            })
      }
   }, [evaluationID]);

   useEffect(() => {
      onLoad();
   }, [onLoad]);   

   const CalculateAverage = () => {
      if(jobTitle.length === 0 && jobPosition.length === 0){
         alert("평균을 계산할 직책 또는 직위를 1개 이상 선택해 주세요.");
         return;
      }
      loadingDispatch({ type: "LOADING_MESSAGE", message: "직책별 평균 계산중..." });
      ReportApi.InsertEvaluationStatistics(evaluationID, "jobTitle", jobTitle)
         .then((res) => {
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         })
         .finally(() => {
            loadingDispatch({ type: "LOADING_MESSAGE", message: "직위별 평균 계산중..." });
            ReportApi.InsertEvaluationStatistics(evaluationID, "jobPosition", jobPosition)
               .then((res2) => {
               })
               .catch((error2) => {
                  let msg = ErrorHandler(error2);
                  alert(msg);
               })
               .finally(() => loadingDispatch({ type: "COMPLETE" }));
         });
   };

   const onJobTitleChange = () => {
      setJobTitle(jobTitleObj.value as string[]);
   };

   const onJobPositionChange = () => {
      setJobPosition(jobPositionObj.value as string[]);
   };

   return (
      <Grid container spacing={2}>
         <Grid item xs={12}>
            <TitleDiv title="결과 보고서 설정"></TitleDiv>
         </Grid>
         <Grid item xs={12}>
            <SelectEvaluation onChange={onChange}></SelectEvaluation>
         </Grid>
         <Grid item xs={12}>
            <SubTitleDiv title="1. 직위, 직책별 평균 계산하기"></SubTitleDiv>
         </Grid>
         <Grid item xs={12}>
         <div style={{width: "800px"}}>
            <Alert variant="primary">
               <Typography variant="subtitle1"><HelpIcon></HelpIcon>도움말</Typography>
               <Typography variant="body1">{" - 계산하고 싶은 직책or직위를 최소 1개 이상 선택하고 계산하기 버튼을 클릭해 주세요."}</Typography>
               <Typography variant="body1">{" - 이미 계산된 평균 값이라면 현재 기준으로 다시 계산하여 업데이트 합니다."}</Typography>
            </Alert>
         </div>
         </Grid>
         <Grid item xs={12}>
            <MultiSelectComponent
               id="defaultelement"
               ref={(scope) => {
                  (jobTitleObj as MultiSelect | null) = scope;
               }}
               dataSource={data?.jobTitle}
               mode="CheckBox"
               fields={fields}
               placeholder="직책 선택"
               selectAllText="전체 선택"
               unSelectAllText="전체 선택 해제"
               showSelectAll={true}
               onChange={onJobTitleChange}
               width={800}
            >
               <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
         </Grid>
         <Grid item xs={12}>
            <MultiSelectComponent
               id="defaultelement"
               ref={(scope) => {
                  (jobPositionObj as MultiSelect | null) = scope;
               }}
               dataSource={data?.jobPosition}
               mode="CheckBox"
               fields={fields}
               placeholder="직위 선택"
               selectAllText="전체 선택"
               unSelectAllText="전체 선택 해제"
               showSelectAll={true}
               onChange={onJobPositionChange}
               width={800}
            >
               <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
         </Grid>
         <Grid item xs={12}>
            <div style={{width: "800px", textAlign: "center"}}>
               <PrimaryButton onClick={CalculateAverage}>평균 계산하기</PrimaryButton>
            </div>
         </Grid>
         <Grid item xs={6}></Grid>
      </Grid>
   );
}

export default ResultSettingTable;
