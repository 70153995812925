import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import PrimaryButton from "../../Common/PrimaryButton";
import SecondaryButton from "../../Common/SecondaryButton";
import { Auth } from "../../../system/types/type";
import { InitAdmin } from "../../../system/types/initObject";
import AdminModalTemplate from "./AuthModalTemplate";
import { AuthApi, ErrorHandler } from "../../../system/ApiService";

interface SelectModalProps {
   open: boolean;
   onClose: () => void;
}

function AdminModal({ open, onClose }: SelectModalProps) {
   const [data, setData] = useState<Auth>(InitAdmin);

   const onChange = (event: any) => {
      setData(event);
   };

   const saveAdmin = () => {
      AuthApi.InsertAuth(data)
         .then((res) => {
            onClose();
         })
         .catch((err) => {
            console.log(err);
            let msg = ErrorHandler(err);
            alert(msg);
         });
   };

   return (
      <>
         <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>관리자 선택</DialogTitle>
            <DialogContent>
               <AdminModalTemplate data={data} onChange={onChange}></AdminModalTemplate>
            </DialogContent>
            <DialogActions>
               <SecondaryButton onClick={onClose}>CANCEL</SecondaryButton>
               <PrimaryButton onClick={saveAdmin}>SAVE</PrimaryButton>
            </DialogActions>
         </Dialog>
      </>
   );
}

export default AdminModal;
