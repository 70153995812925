import React from 'react'
import JobPositionReportPage from '../../components/Result/Report/JobPosition/JobPositionReportPage';
import { usePageState } from '../../system/context/PageContext';

function jobPositionMain() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const page = usePageState();

  if(page[0]){
    return (
      <JobPositionReportPage evaluationId={page[0].evaluationId}></JobPositionReportPage>
    )
  }else{
    return <></>;
  }
}

export default jobPositionMain