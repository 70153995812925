import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";
import { PRIMARY } from "../../styles/theme";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: PRIMARY,
    background: PRIMARY,
    color: "white",
    margin: "2px",
  }
}));

function PrimaryButton(props: ButtonProps): ReactElement {
  const classes = useStyles();
  return (
    <Button className={classes.primaryButton} variant="text" {...props}>
      {props.children}
    </Button>
  );
}

export default PrimaryButton;
