import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid, List, ListItem, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ImprovingRoutes, ProgressRoutes, ResultRoutes, SettingRoutes } from "../../system/types/type";
import { SECONDARY } from "../../styles/theme";
import { IsAdmin1, IsAdmin2, IsUser } from "../../system/types/interface";
import { useUserState } from "../../system/context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      fontWeight: "bold",
      marginTop: "10px",
    },
    item: {
      "& a:hover": {
        color: SECONDARY,
      },
    },
  })
);

interface SiteMapModalProps {
  open: boolean;
  onClose: () => void;
}

function SiteMapModal({ open, onClose }: SiteMapModalProps) {
  const classes = useStyles();
  const user = useUserState();

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h5" className={classes.title}>
              사이트 맵
            </Typography>
          </Grid>
          <Grid item xs={12}></Grid>
          {IsAdmin1(user.role) || IsAdmin2(user.role) ? (
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                설정
              </Typography>
              <List component="div" disablePadding className={classes.item}>
                <ListItem button onClick={onClose} component={Link} to={SettingRoutes.Evaluation}>
                  <ListItemText primary="평가 생성 및 기간 설정" />
                </ListItem>
                <ListItem button onClick={onClose} component={Link} to={SettingRoutes.Evaluatee}>
                  <ListItemText primary="평가 대상자 설정" />
                </ListItem>
                <ListItem button onClick={onClose} component={Link} to={SettingRoutes.Paper}>
                  <ListItemText primary="평가지 설정" />
                </ListItem>
                <ListItem button onClick={onClose} component={Link} to={SettingRoutes.Mail}>
                  <ListItemText primary="이메일 설정" />
                </ListItem>
                <ListItem button onClick={onClose} component={Link} to={SettingRoutes.Auth}>
                  <ListItemText primary="관리자 설정" />
                </ListItem>
                <ListItem button onClick={onClose} component={Link} to={SettingRoutes.Item}>
                  <ListItemText primary="다면평가 항목관리" />
                </ListItem>
              </List>
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.title}>
              진행
            </Typography>
            <List component="div" disablePadding className={classes.item}>
              {/* admin */}
              {IsAdmin1(user.role) || IsAdmin2(user.role) ? (
                <>
                  <ListItem button onClick={onClose} component={Link} to={ProgressRoutes.Status}>
                    <ListItemText primary="현황 관리" />
                  </ListItem>
                  <ListItem button onClick={onClose} component={Link} to={ProgressRoutes.ConfirmAdmin}>
                    <ListItemText primary="평가집단 확인" />
                  </ListItem>
                </>
              ) : null}
              {/* user */}
              {IsUser(user.role) ? (
                <>
                  <ListItem button onClick={onClose} component={Link} to={ProgressRoutes.Select}>
                    <ListItemText primary="평가자 선택" />
                  </ListItem>
                  <ListItem button onClick={onClose} component={Link} to={ProgressRoutes.Confirm}>
                    <ListItemText primary="평가대상자 선택" />
                  </ListItem>
                  <ListItem button onClick={onClose} component={Link} to={ProgressRoutes.Evaluate}>
                    <ListItemText primary="평가하기" />
                  </ListItem>
                </>
              ) : null}
            </List>
          </Grid>
          {!IsAdmin2(user.role) && (
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                결과
              </Typography>
              <List component="div" disablePadding className={classes.item}>
                {/* admin */}
                {IsAdmin1(user.role) && (
                  <>
                    <ListItem button onClick={onClose} component={Link} to={ResultRoutes.Setting}>
                      <ListItemText primary="결과 보고서 설정" />
                    </ListItem>
                    <ListItem button onClick={onClose} component={Link} to={ResultRoutes.Personal}>
                      <ListItemText primary="개인별 결과" />
                    </ListItem>
                    <ListItem button onClick={onClose} component={Link} to={ResultRoutes.JobTitle}>
                      <ListItemText primary="직책별 종합 결과" />
                    </ListItem>
                    <ListItem button onClick={onClose} component={Link} to={ResultRoutes.JobPosition}>
                      <ListItemText primary="직위별 종합 결과" />
                    </ListItem>
                    <ListItem button onClick={onClose}>
                      <ListItemText primary="결과 보고서" />
                    </ListItem>
                    <ListItem button onClick={onClose} component={Link} to={ResultRoutes.FeedbackAdmin}>
                      <ListItemText primary="피드백 조회" />
                    </ListItem>
                  </>
                )}
                {/* user */}
                {IsUser(user.role) && (
                  <>
                    <ListItem button onClick={onClose} component={Link} to={ResultRoutes.Report}>
                      <ListItemText primary="결과 보고서" />
                    </ListItem>
                    <ListItem button onClick={onClose} component={Link} to={ResultRoutes.Feedback}>
                      <ListItemText primary="피드백 요청" />
                    </ListItem>
                  </>
                )}
              </List>
            </Grid>
          )}
          {IsAdmin1(user.role) && (
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                개선
              </Typography>
              <List component="div" disablePadding className={classes.item}>
                <ListItem button onClick={onClose} component={Link} to={ImprovingRoutes.Survey}>
                  <ListItemText primary="평가 만족도 조사 설정" />
                </ListItem>
                <ListItem button onClick={onClose} component={Link} to={ImprovingRoutes.Result}>
                  <ListItemText primary="평가 만족도 조사 결과 조회" />
                </ListItem>
                <ListItem button onClick={onClose} component={Link} to={ImprovingRoutes.List}>
                  <ListItemText primary="평가 개선 방향 설정" />
                </ListItem>
              </List>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default SiteMapModal;
