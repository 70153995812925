import { Localization } from "material-table";

export const LMATERIAL_TABLE: Localization = {
  header: {
    actions: "",
  },
  body: {
    emptyDataSourceMessage: "표시할 데이터가 없습니다.",
    editTooltip: "편집",
    deleteTooltip: "삭제",
    addTooltip: "추가",
    editRow: {
      deleteText: "정말 삭제하시겠습니까?",
      cancelTooltip: "취소",
      saveTooltip: "저장",
    },
  },
};
