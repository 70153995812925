
import ReportPage from "../../components/Result/Report/ReportPage";
import { usePageState } from "../../system/context/PageContext";
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

function ReportMain() {
  const page = usePageState();
  const history = useHistory();

  useEffect(() => {
    if (!page[6].isOpen) {
      alert("결과 조회 기간이 아닙니다.")
      history.push("/")
    }
  },[history, page])

  if(page[6].isOpen){
    return (
      <>
        <ReportPage id={page[6].evaluationId}></ReportPage>
      </>
    );
  }else{
    return <></>;
  }
}

export default ReportMain;
