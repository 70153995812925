import MaterialTable, { MTableToolbar } from "material-table";
import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ErrorHandler, EvaluationApi, ReportApi } from "../../../../system/ApiService";
import {  EvaluationReportType, User } from "../../../../system/types/type";
import { TABLEICONS } from "../../../../system/Constants";
import { TABLE_ACTION, TABLE_HEADER } from "../../../../styles/theme";
import { LMATERIAL_TABLE } from "../../../../system/Localization";

interface MultipleChoiceReportTableProps {
   evaluationId: number;
   employee: User;
   index: number;
   type: EvaluationReportType;
}

function MultipleChoiceReportTable({ evaluationId, employee, index, type }: MultipleChoiceReportTableProps) {
   const [data, setData] = useState<any[]>([]);
   const [columns, setColumns] = useState<{}[]>([]);

   useEffect(() => {
      let temp: any[] = [];
      EvaluationApi.GetQuestionList(evaluationId, 0, type.type)
         .then((res) => {
            setColumnsByData(res.data);
            let categoryObj: any;
            categoryObj = { ...categoryObj, type: "평가지표" };
            for (var x of res.data) {
               categoryObj = { ...categoryObj, [`${x.category}`]: x.category };
            }
            categoryObj = { ...categoryObj, sum: "합계\r\n(100점 변환)" };
            temp.push(categoryObj);

            ReportApi.GetEvaluationReport(evaluationId, employee.employeeId, "choice", type.type, 1)
               .then((res) => {
                  let scoreObj: any;
                  let scoreObj2: any;
                  let sum: number = 0;
                  // 평가 지표 행, 본인 평가 점수 행
                  scoreObj = { ...scoreObj, type: "본인평가" };
                  for (var x of res.data) {
                     if(x.score > 0)
                        scoreObj = { ...scoreObj, [`${x.category}`]: x.score };
                     else
                        scoreObj = { ...scoreObj, [`${x.category}`]: "-" };
                     sum += x.score;
                     // 타인평가 0점(모름)인것 category 비어있는 것 채우기
                     scoreObj2 = { ...scoreObj2, [`${x.category}`]: "-"  };
                  }
                  sum *= 100 / (res.data.length * 10);
                  scoreObj = { ...scoreObj, [`sum`]: sum.toFixed(1) };
                  if (res.data.length > 0) 
                    temp.push(scoreObj);

                  // 타인 평가 점수 행
                  ReportApi.GetEvaluationReport(evaluationId, employee.employeeId, "choice", type.type, 0)
                     .then((res2) => {
                        scoreObj2 = { ...scoreObj2, type: "타인평가" };
                        sum = 0;
                        for (var x of res2.data) {
                           if(x.score > 0)
                              scoreObj2 = { ...scoreObj2, [`${x.category}`]: x.score };
                           sum += x.score;
                        }
                        sum *= 100 / (res2.data.length * 10);
                        scoreObj2 = { ...scoreObj2, [`sum`]: sum.toFixed(1) };
                        if (res2.data.length > 0) 
                          temp.push(scoreObj2);
                        setData(temp);
                     })
                     .catch((error) => {
                        let msg = ErrorHandler(error);
                        alert(msg);
                     });
               })
               .catch((error) => {
                  let msg = ErrorHandler(error);
                  alert(msg);
               });
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [evaluationId, employee.employeeId, type]);

   const setColumnsByData = (data: any) => {
      // columns
      var cols = [];
      cols.push({
         title: "구분",
         field: "type",
         cellStyle: { width: `${2*100 / (data.length+3)}%`, textAlign: "center" },
         headerStyle: { textAlign: "center" },
         align: "center",
      });
      let idx = 1;
      for (var z of data) {
         cols.push({
            title: idx + "",
            field: z.category,
            cellStyle: { width: `${100 / (data.length+3)}%`, textAlign: "center" },
            headerStyle: { textAlign: "center" },
         });
         idx++;
      }
      cols.push({
         title: "-",
         field: "sum",
         cellStyle: { width: `${100 / (data.length+3)}%`, textAlign: "center", backgroundColor: "#FCBCC0", fontWeight: "bold" },
         headerStyle: { textAlign: "center", color: "black" },
      });
      setColumns(cols);
   };

   return (
      <>
         <Grid container style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "30px"}}>
            <Grid item xs={12}>
               <Typography variant="body1" style={{ fontWeight: "bold"}}>{`2-${
                  index + 1
               }.${type.value}`}</Typography>
            </Grid>
            <Grid item xs={index===0? 11 : 9}>
               <MaterialTable
                  title=""
                  icons={TABLEICONS}
                  style={{ marginTop: "10px", minWidth: `${index===0?1000:800}px` }}
                  columns={columns}
                  data={data}
                  components={{
                     Toolbar: (props) => (
                        <div style={{ backgroundColor: "#fafafa" }}>
                           <MTableToolbar {...props} />
                        </div>
                     ),
                  }}
                  options={{
                     actionsColumnIndex: -1,
                     actionsCellStyle: TABLE_ACTION,
                     headerStyle: TABLE_HEADER,
                     padding: "dense",
                     exportButton: true,
                     toolbar: false,
                     paging: false,
                     sorting: false,
                     rowStyle: (rowData) => {
                        if (rowData.type === "평가지표") {
                           return { backgroundColor: "#DDDDDD", whiteSpace: "pre-line" };
                        } else if (rowData.type === "타인평가") {
                           return { backgroundColor: "#FFFFFF" };
                        } else if (rowData.type === "본인평가") {
                           return { backgroundColor: "#FFFFFF" };
                        } else {
                           return {};
                        }
                     },
                  }}
                  localization={LMATERIAL_TABLE}
               />
            </Grid>
            <Grid item xs={index===0? 1 : 3}>
               
            </Grid>
         </Grid>
      </>
   );
}

export default MultipleChoiceReportTable;
