import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { EvaluationApi } from "../../../system/ApiService";
import { InitTemplate } from "../../../system/types/initObject";
import { EvaluationItemViewModel, EvaluationTemplate } from "../../../system/types/type";
import HtmlContent from "../../Common/HtmlContent";

const useStyles = makeStyles((theme) => ({
  table: {
    border: "0.5px black solid",
    marginTop: "10px",
  },
  title: {
    padding: "20px",
    backgroundColor: "#EDEDED",
    display: "table",
    borderBottom: "0.5px black solid",
  }, 
  content: {
    padding: "10px"
  },
}));

interface EvaluateTemplateProps {
  data: EvaluationItemViewModel;
  kind: "header" | "footer";
}

function EvaluateTemplate({data, kind}: EvaluateTemplateProps) {
  const classes = useStyles();
  const [template, setTemplate] = useState<EvaluationTemplate>(InitTemplate);
  const [title, setTitle] = useState<string>("");

  // 템플릿 조회
  const onLoad = useCallback(() => {
    if (data.evaluationId) {
      EvaluationApi.GetEvaluation(data.evaluationId)
      .then((res) => {
        setTitle(res.data.title)
      })
      .catch((err) => console.log(err, "get title"))

      EvaluationApi.GetTemplate(data.evaluationId)
      .then((res) => {
        setTemplate(res.data[0])
      })
      .catch((err) => console.log(err, "get template"))
    }
  }, [data.evaluationId])

  useEffect(() => {
    onLoad();
  }, [onLoad])

  return (
    <Grid container justifyContent="center">
      <Grid item xs={8} style={{ minWidth: "1000px"}}>
        <Box className={classes.table}>
          <Grid container className={classes.title}>
            <Grid item xs={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          </Grid>
          <Grid className={classes.content}>
            {kind === "header" ? 
              <HtmlContent data={template.header}></HtmlContent>
              :
              <HtmlContent data={template.footer}></HtmlContent>
            }
          </Grid>
        </Box>
      </Grid>
    </Grid> 
  );
}

export default EvaluateTemplate;
