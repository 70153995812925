import React, { useEffect, useState } from "react";
import { ErrorHandler, ReportApi } from "../../../../system/ApiService";
import { makeStyles, TableBody, TableCell, TableRow, Grid, Typography } from "@material-ui/core";
import { Table } from "react-bootstrap";
import { PRIMARY } from "../../../../styles/theme";
import { EvaluationStatisticsSummary } from "../../../../system/types/type";

interface EvaluationStatisticsSummaryProps {
   evaluationId: number;
   columnName: string;
}

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: "800px",
      marginTop: "10px",
   },
   tableHeader: {
      textAlign: "center",
      fontSize: "1rem",
   },
   fontRed: {
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: "red",
   },
   fontBlue: {
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: "blue",
   },
}));

function EvaluationStatisticsSummaryTable({ evaluationId, columnName }: EvaluationStatisticsSummaryProps) {
   const classes = useStyles();
   const [data, setData] = useState<EvaluationStatisticsSummary[]>([]);

   useEffect(() => {
      ReportApi.GetEvaluationStatisticsSummary(evaluationId, columnName)
         .then((res) => {
            setData(res.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [evaluationId, columnName]);

   return (
      <Grid container>
         <Grid item xs={12}>
            <Typography variant="h6">{`4. 결과 요약`}</Typography>
         </Grid>
         {/* 결과 요약 테이블 */}
         <Grid xs={8}>
            <Table className={classes.table}>
               <TableBody>
                  <TableRow>
                     <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "20%" }}>구분</TableCell>
                     <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "40%" }}>강점</TableCell>
                     <TableCell style={{ backgroundColor: PRIMARY, color: "white", textAlign: "center", width: "40%" }}>보완점</TableCell>
                  </TableRow>
                  {data.map((x, index) => {
                     return (
                        <TableRow key={`statistics-summary-${index}`} style={{ backgroundColor: x.type === "전체 평균" ? "#ABDEA1" : "" }}>
                           <TableCell style={{ textAlign: "center" }}>{x.type}</TableCell>
                           <TableCell style={{ textAlign: "center" }}>{x.high}</TableCell>
                           <TableCell style={{ textAlign: "center" }}>{x.low}</TableCell>
                        </TableRow>
                     );
                  })}
               </TableBody>
            </Table>
         </Grid>
         <Grid xs={4}></Grid>
      </Grid>
   );
}

export default EvaluationStatisticsSummaryTable;
