import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import TitleDiv from "../../Common/TitleDiv";
import SubTitleDiv from "../../Common/SubTitleDiv";
import SelectEvaluation from "../../Common/SelectEvaluation";
import SelectEmployee from "../../Common/SelectEmployee";
import EvaluatorAdmin from "./EvaluatorAdmin";
import EvaluateeAdmin from "./EvaluateeAdmin";
import { DeptTreeView, User } from "../../../system/types/type";
import { InitDeptTreeView, InitUser } from "../../../system/types/initObject";
import { EmployeeApi, ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import SecondaryButton from "../../Common/SecondaryButton";
import AllEvaluationItem from "./AllEvaluationItem";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";

function ConfirmTableAdmin() {
   const [change, setChange] = useState<boolean>(false);
   const [evaluationID, setEvaluationID] = useState<number>(0);
   const [employee, setEmployee] = useState<User>(InitUser);
   const [empList, setEmpList] = useState<User[]>([]);
   const [filteredList, setFilteredList] = useState<User[]>([]);
   const [dept, setDept] = useState<DeptTreeView>(InitDeptTreeView);
   const field: Object = { dataSource: [dept], id: "id", text: "name", child: "subChild" };
   const style: string = "accordiontree";
   var treeObj: TreeViewComponent;

   useEffect(() => {
      // 현재연도 조직도 불러오기
      EmployeeApi.GetDeptTreeView(new Date().getFullYear())
         .then((res) => {
            if (res !== undefined) {
               res.data.subChild?.forEach((x) => {
                  if (x.name !== "지사") x.expanded = true;
               });
               setDept({ ...res.data, expanded: true });
            }
         })
         .catch((err) => {
            console.log(err);
            let msg = ErrorHandler(err);
            alert(msg);
         });
      // 전체사원 리스트 조회
      EmployeeApi.GetEmployee()
         .then((res) => {
            setEmpList(res.data);
            setFilteredList(res.data);
         })
         .catch((err) => {
            console.log(err);
            let msg = ErrorHandler(err);
            alert(msg);
         });
   }, []);

   const insertItem = () => {
      if (evaluationID !== 0) {
         EvaluationApi.InsertBatchEvaluationItem(evaluationID)
            .then(() => alert("해당 평가의 파트장/팀장 및 본인 평가를 생성했습니다."))
            .catch((err) => {
               console.log(err, "err");
               let msg = ErrorHandler(err);
               alert(msg);
            });
      } else {
         alert("평가를 선택해주세요.");
      }
   };

   const onChange = (id: number) => {
      setEvaluationID(id);
   };

   const onChangeEmp = (data: User) => {
      if (data) {
         setEmployee(data);
      }
   };

   const nodeClicked = (args: any): void => {
      let checkedNode: any = [args.node];
      if (args.event.target.classList.contains("e-fullrow") || args.event.key === "Enter") {
         let getNodeDetails: any = treeObj.getNode(args.node);
         if (getNodeDetails.isChecked === "true") {
            treeObj.uncheckAll(checkedNode);
         } else {
            treeObj.checkAll(checkedNode);
         }
      }

      let list: User[] = [];
      treeObj.checkedNodes.forEach((x) => {
         empList.forEach((emp) => {
            if (emp.teamId === x) {
               list.push(emp);
            }
         });
      });
      setFilteredList(list);
   };

   const handleChange = () => {
      setChange(!change);
   };

   return (
      <Grid container spacing={2} style={{ minWidth: "1650px" }}>
         <Grid item xs={12}>
            <TitleDiv title="평가집단 확인"></TitleDiv>
         </Grid>
         <Grid item xs={12}>
            <SubTitleDiv title="평가자 명단 확인"></SubTitleDiv>
         </Grid>
         <Grid item xs={12}>
            <SecondaryButton onClick={insertItem}>파트장/팀장 및 본인 일괄 업데이트</SecondaryButton>
            <FormGroup row>
               <FormControlLabel control={<Switch checked={change} onChange={handleChange} name="change-all" />} label="전체 평가자 보기" />
            </FormGroup>
         </Grid>
         <Grid item container justifyContent="space-between">
            <Grid item xs={5}>
               <SelectEvaluation onChange={onChange}></SelectEvaluation>
            </Grid>
            <Grid item xs={5}>
               <SelectEmployee list={filteredList} onChange={onChangeEmp}></SelectEmployee>
            </Grid>
         </Grid>
         {change ? (
            <Grid container item xs={12} spacing={2}>
               <Grid item xs={12}>
                  <AllEvaluationItem id={evaluationID}></AllEvaluationItem>
               </Grid>
            </Grid>
         ) : (
            <Grid container item xs={12} spacing={2}>
               <Grid item xs={2}>
                  <TreeViewComponent
                     fields={field}
                     cssClass={style}
                     showCheckBox={true}
                     ref={(treeview) => (treeObj = treeview as TreeViewComponent)}
                     nodeClicked={nodeClicked}
                  ></TreeViewComponent>
               </Grid>
               <Grid item xs={10}>
                  <EvaluatorAdmin id={evaluationID} user={employee}></EvaluatorAdmin>
                  <br />
                  <EvaluateeAdmin id={evaluationID} user={employee}></EvaluateeAdmin>
               </Grid>
            </Grid>
         )}
      </Grid>
   );
}

export default ConfirmTableAdmin;
