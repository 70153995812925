import React, { useCallback, useEffect, useState } from "react";
import { Button, createStyles, Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TitleDiv from "../../Common/TitleDiv";
import MaterialTable, { MTableToolbar } from "material-table";
import { TABLEICONS } from "../../../system/Constants";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { TABLE_HEADER } from "../../../styles/theme";
import { AddBox, DeleteOutline, Edit } from "@material-ui/icons";
import { ErrorHandler, MailApi } from "../../../system/ApiService";
import { ReservedMail } from "../../../system/types/type";
import MailModal from "./MailModal";

const useStyles = makeStyles((theme) =>
   createStyles({
      toolBar: {
         justifyContent: "flex-end",
         alignItems: "center",
         backgroundColor: "#fafafa",
      },
      button: {
         float: "right",
      },
      iconButton: {
         padding: "5px",
      },
      buttonGroup: {
         textAlign: "end",
      },
   })
);

function MailTable() {
   const classes = useStyles();
   const [id, setId] = useState<number>(0);
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [data, setData] = useState<ReservedMail[]>([]);

   const onLoad = useCallback(() => {
      MailApi.GetReservedMailList()
         .then((res) => setData(res.data))
         .catch((err) => {
            console.log(err);
            let msg = ErrorHandler(err);
            alert(msg);
         });
   }, []);

   useEffect(() => {
      onLoad();
   }, [onLoad]);

   const openMailModal = (id: number) => {
      setId(id);
      setOpenModal(true);
   };

   const closeMailModal = () => {
      setOpenModal(false);
      onLoad();
      setId(0);
   };

   const onDeleteMail = (id: number) => {
      if (window.confirm("정말 삭제하시겠습니까?")) {
         MailApi.DeleteReservedMail(id)
            .then(() => {
               let newData = data.filter((x) => x.id !== id);
               setData(newData);
            })
            .catch((err) => {
               console.log(err);
               let msg = ErrorHandler(err);
               alert(msg);
            });
      }
   };

   return (
      <Grid container spacing={2}>
         <Grid item xs={12}>
            <TitleDiv title="이메일 설정"></TitleDiv>
         </Grid>
         <Grid item xs={8} style={{ minWidth: "1200px" }}>
            <MaterialTable
               title=""
               icons={TABLEICONS}
               columns={[
                  {
                     title: "No.",
                     field: "id",
                     cellStyle: { width: "5%" },
                     align: "center",
                     render: (rowData) => {
                        return <>{data.indexOf(rowData) + 1}</>;
                     },
                  },
                  { title: "예약일자", field: "reservedDate", cellStyle: { width: "20%" }, align: "center" },
                  { title: "발신일자", field: "sendedDate", cellStyle: { width: "20%" }, align: "center" },
                  { title: "항목", field: "title", cellStyle: { width: "45%" }, align: "center" },
                  {
                     title: "",
                     field: "",
                     cellStyle: { width: "15%" },
                     render: (rowData) => {
                        return (
                           <>
                              {rowData.status === "T" ? (
                                 <div className={classes.buttonGroup}>
                                    <IconButton className={classes.iconButton} onClick={() => openMailModal(rowData.id)}>
                                       <Edit />
                                    </IconButton>
                                    <IconButton className={classes.iconButton} onClick={() => onDeleteMail(rowData.id)}>
                                       <DeleteOutline />
                                    </IconButton>
                                 </div>
                              ) : (
                                 <div className={classes.buttonGroup}>
                                    <IconButton className={classes.iconButton} onClick={() => openMailModal(rowData.id)}>
                                       <MoreHorizIcon />
                                    </IconButton>
                                 </div>
                              )}
                           </>
                        );
                     },
                  },
               ]}
               data={data || []}
               options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: TABLE_HEADER,
                  padding: "dense",
                  exportButton: true,
                  exportAllData: true,
               }}
               components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                  Toolbar: (props) => (
                     <Grid container className={classes.toolBar}>
                        <Grid item>
                           <MTableToolbar {...props} />
                        </Grid>
                        <Grid item>
                           <Button onClick={() => openMailModal(0)}>
                              <AddBox />
                           </Button>
                        </Grid>
                     </Grid>
                  ),
               }}
               localization={LMATERIAL_TABLE}
            />
            <MailModal id={id} open={openModal} onClose={closeMailModal}></MailModal>
         </Grid>
      </Grid>
   );
}

export default MailTable;
