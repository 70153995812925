import ResultTable from "../../components/Improving/Result/ResultTable";

function ResultMain() {
  return (
    <>
      <ResultTable></ResultTable>
    </>
  );
}
export default ResultMain;
