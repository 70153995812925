import { Button, Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TABLEICONS } from "../../../system/Constants";
import AnswerModal from "./AnswerModal";
import { Constant, ModalType, Question } from "../../../system/types/type";
import { AddBox, DeleteOutline, Edit } from "@material-ui/icons";
import { ConstantApi, EvaluationApi } from "../../../system/ApiService";
import { TABLE_HEADER } from "../../../styles/theme";

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
  },
  iconButton: {
    padding: "5px",
  },
  buttonGroup: {
    textAlign: "end",
  },
}));

interface AnswerTableProps {
  id: number;
}

function AnswerTable({id: evaluationId}: AnswerTableProps) {
  const classes = useStyles();
  const [list, setList] = useState<Question[]>([]);
  const [id, setId] = useState<number>();
  const [type, setType] = useState<ModalType>("Edit");
  const [answerModal, setAnswerModal] = useState<boolean>(false);
  const [itemType, setItemType] = useState<Constant[]>([]);
  const itemTypeLookUp: Object = itemType.reduce((x, y) => ({ ...x, [y.key]: y.value }), {});
  const [answer, setAnswer] = useState<Constant[]>([]);
  const answerLookUp: Object = answer.reduce((x, y) => ({ ...x, [y.key]: y.value }), {});

  const onLoad = useCallback(() => {
    if (evaluationId !== 0) {  
      EvaluationApi.GetQuestionList(evaluationId, 0, 0)
      .then((res) => {
        setList(res.data)
      })
      .catch((err) => console.log(err, "get question"))
    }
  }, [evaluationId])

  useMemo(() => {
    ConstantApi.GetConstantList("EvaluationItem", "Type")
    .then((res) => {
      setItemType(res.data);      
    })
    ConstantApi.GetConstantList("Question", "AnswerType")
    .then((res) => {
      setAnswer(res.data);
    })
  }, [])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onOpenModal = (id: number) => {
    setAnswerModal(true)
    if (id !== 0) {
      setId(id)
      setType("Edit")
    } else {
      setId(0)
      setType("Add")
    }
  }

  const onCloseModal = () => {
    setAnswerModal(false);
    onLoad();
  }

  const onDeleteAnswer = (id: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      EvaluationApi.DeleteQuestion(id)
      .then(() => onLoad())
      .catch((err) => console.log(err, "delete question"))
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ minWidth: "1400px"}}>
        <MaterialTable
          title=""
          icons={TABLEICONS}
          columns={[
            { title: 'No.', field: '', cellStyle: { width: "5%" }, 
              render: rowData => {
                return (
                  <>{list.indexOf(rowData) + 1}</>
                )
              } 
            },
            { title: '구분', field: 'evaluationItemType', cellStyle: { width: "10%" }, lookup: itemTypeLookUp, align: "center", },
            { title: '문항순서', field: 'order', cellStyle: { width: "10%" }, align: "center", },
            { title: '평가영역', field: 'category', cellStyle: { width: "10%" }, align: "center", },
            { title: '평가문항', field: 'text', cellStyle: { width: "40%" }, },
            { title: '답안유형', field: 'answerType', cellStyle: { width: "15%" }, lookup: answerLookUp, align: "center", },
            { title: '', field: '', cellStyle: { width: "8%" },
              render: rowData => {
                return (
                  <div className={classes.buttonGroup}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => onOpenModal(rowData.id)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => onDeleteAnswer(rowData.id)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </div>
                )
              }
            },
          ]}
          data={list || []}
          components={{
            Container: props => <Paper {...props} elevation={0}/>,
            Toolbar: props => (
              <Grid container justifyContent='flex-end' alignItems='center' style={{backgroundColor: '#fafafa'}}>
                <Grid item>
                  <MTableToolbar {...props} />
                </Grid> 
                <Grid item>
                  <Button onClick={() => onOpenModal(0)}><AddBox/></Button>
                </Grid> 
              </Grid>
            )
          }}
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            pageSize: 20,
            headerStyle: TABLE_HEADER,
            exportButton: true,
            exportAllData: true,
          }}
        />
        <AnswerModal
          evaluationId={evaluationId}
          open={answerModal}
          id={id}
          type={type}
          onClose={onCloseModal}
        ></AnswerModal>
      </Grid>
    </Grid>
  );
}

export default AnswerTable;
