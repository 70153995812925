import { createContext, Dispatch, useContext, useReducer } from "react";
import { EvaluationTerm } from "../types/type";

const PageStateContext = createContext<EvaluationTerm[] | undefined>(undefined);

type Action = { type: "LOADING"; item: EvaluationTerm[] };

type PageDispatch = Dispatch<Action>;
const PageDispatchContext = createContext<PageDispatch | undefined>(undefined);

function PageReducer(state: EvaluationTerm[], action: Action): EvaluationTerm[] {
  switch (action.type) {
    case "LOADING":
      return {
        ...action.item,
      };
  }
}

export function PageContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(PageReducer, []);
  return (
    <PageDispatchContext.Provider value={dispatch}>
      <PageStateContext.Provider value={state}>{children}</PageStateContext.Provider>
    </PageDispatchContext.Provider>
  );
}

//custom hook
export function usePageState() {
  const state = useContext(PageStateContext);
  if (!state) throw new Error("PageStateProvider not found");
  return state;
}

export function usePageDispatch() {
  const dispatch = useContext(PageDispatchContext);
  if (!dispatch) throw new Error("PageDispatchProvider not found");
  return dispatch;
}
