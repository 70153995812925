import React, { useState } from "react";
import { SECONDARY } from "../../styles/theme";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Drawer, CssBaseline, List, ListItem, ListItemText, Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useUserState } from "../../system/context/UserContext";
import { NavLink } from "react-router-dom";
import { ImprovingRoutes, ProgressRoutes, ResultRoutes, SettingRoutes } from "../../system/types/type";
import { IsAdmin1, IsAdmin2, IsUser } from "../../system/types/interface";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      fontSize: "20px",
      textAlign: "center",
      backgroundColor: SECONDARY,
      color: "white",
      "& a:hover": {
        color: "#e72a49cc",
        backgroundColor: "#373f45",
        filter: "brightness(1.2)",
      },
    },
    drawerHeader: {
      ...theme.mixins.toolbar,
      marginTop: "80px",
    },
    presentation: {
      height: "100%",
      position: "absolute",
      zIndex: 0,
    },
    collapse: {
      backgroundColor: "#373f45",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    active: {
      color: "#e72a49",
    },
  })
);

interface PersistentDrawerLeftProps {
  open: boolean;
}

export default function PersistentDrawerLeft({ open }: PersistentDrawerLeftProps) {
  const classes = useStyles();
  const user = useUserState();
  const [setting, setSetting] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [result, setResult] = useState<boolean>(false);
  const [improving, setImproving] = useState<boolean>(false);

  const handleClick = (name: string) => {
    if (name === "setting") {
      setSetting(!setting);
    } else if (name === "processing") {
      setProcessing(!processing);
    } else if (name === "result") {
      setResult(!result);
    } else if (name === "improving") {
      setImproving(!improving);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.presentation} role="presentation">
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Grid container direction="row" alignContent="space-between">
              <Grid item xs={12}>
                <List>
                  {/* only admin */}
                  {IsAdmin1(user.role) || IsAdmin2(user.role) ? (
                    <>
                      <ListItem button onClick={() => handleClick("setting")}>
                        <ListItemText primary="설정" />
                        {setting ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={setting} timeout="auto" unmountOnExit className={classes.collapse}>
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={SettingRoutes.Evaluation}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가 생성 및 기간 설정" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={SettingRoutes.Evaluatee}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가 대상자 설정" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={SettingRoutes.Paper}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가지 설정" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={SettingRoutes.Mail}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="이메일 설정" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={SettingRoutes.Auth}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="관리자 설정" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={SettingRoutes.Item}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="다면평가 항목관리" />
                          </ListItem>
                        </List>
                      </Collapse>
                    </>
                  ) : null}
                  <ListItem button onClick={() => handleClick("processing")}>
                    <ListItemText primary="진행" />
                    {processing ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={processing} timeout="auto" unmountOnExit className={classes.collapse}>
                    <List component="div" disablePadding>
                      {IsUser(user.role) ? (
                        // user
                        <>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={ProgressRoutes.Select}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가자 선택" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={ProgressRoutes.Confirm}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가대상자 선택" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={ProgressRoutes.Evaluate}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가하기" />
                          </ListItem>
                        </>
                      ) : (
                        // admin
                        <>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={ProgressRoutes.Status}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="현황 관리" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={ProgressRoutes.ConfirmAdmin}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가집단 확인" />
                          </ListItem>
                        </>
                      )}
                    </List>
                  </Collapse>
                  {!IsAdmin2(user.role) && (
                    <>
                      <ListItem button onClick={() => handleClick("result")}>
                        <ListItemText primary="결과" />
                        {result ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={result} timeout="auto" unmountOnExit className={classes.collapse}>
                        <List component="div" disablePadding>
                          {/* admin */}
                          {IsAdmin1(user.role) && (
                            <>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.Setting}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="결과 보고서 설정" />
                              </ListItem>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.Personal}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="개인별 결과" />
                              </ListItem>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.JobTitle}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="직책별 종합 결과" />
                              </ListItem>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.JobPosition}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="직위별 종합 결과" />
                              </ListItem>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.Company}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="회사 평가 결과" />
                              </ListItem>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.ReportAdmin}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="결과 보고서" />
                              </ListItem>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.FeedbackAdmin}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="피드백 조회" />
                              </ListItem>
                            </>
                          )}
                          {/* user */}
                          {IsUser(user.role) && (
                            <>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.Report}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="결과 보고서" />
                              </ListItem>
                              <ListItem
                                button
                                className={classes.nested}
                                component={NavLink}
                                to={ResultRoutes.Feedback}
                                activeClassName={classes.active}
                              >
                                <ListItemText primary="피드백 요청" />
                              </ListItem>
                            </>
                          )}
                        </List>
                      </Collapse>
                    </>
                  )}
                  {/* only admin */}
                  {IsAdmin1(user.role) && (
                    <>
                      <ListItem button onClick={() => handleClick("improving")}>
                        <ListItemText primary="개선" />
                        {improving ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={improving} timeout="auto" unmountOnExit className={classes.collapse}>
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={ImprovingRoutes.Survey}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가 만족도 조사 설정" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={ImprovingRoutes.Result}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가 만족도 조사 결과" />
                          </ListItem>
                          <ListItem
                            button
                            className={classes.nested}
                            component={NavLink}
                            to={ImprovingRoutes.List}
                            activeClassName={classes.active}
                          >
                            <ListItemText primary="평가 개선 방향 설정" />
                          </ListItem>
                        </List>
                      </Collapse>
                    </>
                  )}
                </List>
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </div>
    </>
  );
}
