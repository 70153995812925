import FeedbackTableAdmin from "../../components/Result/Feedback/FeedbackTableAdmin";

function FeedbackAdminMain() {
  return (
    <>
      <FeedbackTableAdmin></FeedbackTableAdmin>
    </>
  );
}
export default FeedbackAdminMain;
