import React, { useCallback, useEffect, useMemo, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { TABLEICONS } from "../../../system/Constants";
import { EvaluationGroupViewModel } from "../../../system/types/type";
import { EmployeeApi, ErrorHandler, EvaluationApi } from "../../../system/ApiService";
import { LMATERIAL_TABLE } from "../../../system/Localization";
import { Grid, Paper } from "@material-ui/core";
import { TABLE_ACTION, TABLE_HEADER } from "../../../styles/theme";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import SelectEvaluation from "../../Common/SelectEvaluation";
import TitleDiv from "../../Common/TitleDiv";
import { Lookup } from "../../../system/types/interface";
import { InitLookup } from "../../../system/types/initObject";

function StatusTable() {
   const loadingDispatch = useLoadingDispatch();
   const [evaluationID, setEvaluationID] = useState<number>(0);
   const statusLookup = { 0: "초기상태", 1: "평가자선택", 2: "평가대상자선택", 3: "평가진행중", 4: "평가완료" };
   const stepLookup = { true: "O", false: "-" };
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [data, setData] = useState<EvaluationGroupViewModel[]>([]);
   const [lookup, setLookup] = useState<Lookup>(InitLookup);

   const onChange = (id: number) => {
      setEvaluationID(id);
   };

   const onLoad = useCallback(() => {
      if (evaluationID !== 0) {
         loadingDispatch({ type: "LOADING" });
         EvaluationApi.GetEvaluationGroups(evaluationID)
            .then((res) => {
               setData(res.data);
            })
            .catch((err) => {
               console.log(err, "err");
               let msg = ErrorHandler(err);
               alert(msg);
            })
            .finally(() => loadingDispatch({ type: "COMPLETE" }));
      }
   }, [evaluationID, loadingDispatch]);

   useMemo(() => {
    EmployeeApi.GetLookup("all").then((res)=>{
      var parentTeam :string[] = res.data.parentTeam;
      var parentTeamLookups: Object = parentTeam.reduce((x, y) => ({ ...x, [y]: y }), {});
      var team :string[] = res.data.team;
      var teamLookups: Object = team.reduce((x, y) => ({ ...x, [y]: y }), {});
      var temp: Lookup = InitLookup;
      temp = {...temp, parentTeam: parentTeamLookups, team: teamLookups};
      setLookup(temp);
    })
  }, [])

   useEffect(() => {
      onLoad();
   }, [onLoad]);

   const getStep = (step: boolean) => {
      if (step) {
         return <span style={{ color: "black" }}>O</span>;
      } else {
         return <span style={{ color: "black" }}>-</span>;
      }
   };

   return (
      <Grid container spacing={2}>
         <Grid item xs={12}>
            <TitleDiv title="현황 관리"></TitleDiv>
         </Grid>
         <Grid item xs={12}>
            <SelectEvaluation onChange={onChange}></SelectEvaluation>
         </Grid>
         <Grid item xs={12}>
            <MaterialTable
               title=""
               icons={TABLEICONS}
               columns={[
                  {
                     title: "No.",
                     field: "",
                     cellStyle: { width: "10%" },
                     render: (rowData) => {
                        return <>{data.indexOf(rowData) + 1}</>;
                     },
                  },
                  { title: "파트", field: "parentTeam", lookup: lookup.parentTeam, cellStyle: { width: "15%" }, align: "center" },
                  { title: "팀", field: "team", lookup: lookup.team, cellStyle: { width: "15%" }, align: "center" },
                  { title: "성명", field: "name", cellStyle: { width: "10%" }, align: "center" },
                  {
                     title: "평가자선택",
                     field: "step1",
                     render: (rowData) => {
                        return getStep(rowData.step1);
                     },
                     lookup: stepLookup,
                     cellStyle: { width: "10%" },
                     align: "center",
                  },
                  {
                     title: "평가대상자\r\n선택",
                     field: "step2",
                     render: (rowData) => {
                        return getStep(rowData.step2);
                     },
                     lookup: stepLookup,
                     cellStyle: { width: "10%" },
                     align: "center",
                  },
                  {
                     title: "평가진행중",
                     field: "step3",
                     render: (rowData) => {
                        return getStep(rowData.step3);
                     },
                     lookup: stepLookup,
                     cellStyle: { width: "10%" },
                     align: "center",
                  },
                  {
                     title: "평가완료",
                     field: "step4",
                     render: (rowData) => {
                        return getStep(rowData.step4);
                     },
                     lookup: stepLookup,
                     cellStyle: { width: "10%" },
                     align: "center",
                  },
                  { title: "상태", field: "status", cellStyle: { width: "10%" }, lookup: statusLookup, align: "center" },
               ]}
               data={data || []}
               components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                  Toolbar: (props) => (
                     <div style={{ backgroundColor: "#fafafa" }}>
                        <MTableToolbar {...props} />
                     </div>
                  ),
               }}
               options={{
                  actionsColumnIndex: -1,
                  pageSize: 20,
                  pageSizeOptions: [5, 10, 20, data.length],
                  headerStyle: TABLE_HEADER,
                  actionsCellStyle: TABLE_ACTION,
                  padding: "dense",
                  selection: true,
                  filtering: true,
                  exportButton: true,
                  exportAllData: true,
               }}
               localization={LMATERIAL_TABLE}
            />
         </Grid>
      </Grid>
   );
}

export default StatusTable;
