import React from "react";
import { Typography } from "@material-ui/core";

function MultipleChoiceIntro() {
   return (
      <>
         <Typography variant="h6">2. 객관식 평가 결과</Typography>
         <Typography variant="body1"> - '본인 평가'는 본인 스스로를 평가한 결과를 점수로 환산한 값입니다.</Typography>
         <Typography variant="body1"> - '타인 평가'는 본인 평가 결과는 제외된, 타인이 본인을 평가한 결과를 점수로 환산한 값의 평균입니다.</Typography>
         <Typography variant="body1">
            {" "}
            * 객관식 점수 산정 방식 : 항상 그렇다(10점), 자주 그렇다(8점), 가끔 그렇다(6점), 거의 그렇지 않다(4점), 항상 그렇지 않다(2점),
            모름(미반영)
         </Typography>
      </>
   );
}

export default MultipleChoiceIntro;
