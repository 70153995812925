import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class _HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(url?: string) {
    const baseURL = url ? url : `${window.location.origin}/api`;

    this.instance = axios.create({
      baseURL,
    });
    this.instance.defaults.withCredentials = true;
  }
}

export default class HttpClient extends _HttpClient {
  protected token: string | null;

  public constructor(url?: string) {
    super(url);
    this.token = null;
    this.instance.interceptors.request.use(this.requestInterceptor, (error) => Promise.reject(error));
  }

  private requestInterceptor = (config: AxiosRequestConfig) => {
    if (this.token !== null && this.token.length >= 1 && config.headers !== undefined) {
      config.headers["Authorization"] = `Bearer ${this.token}`;
      config.headers["Page"] = window.location.href;
    }
    return config;
  };

  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return this.instance.post<T, R>(url, data, config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.instance.get<T, R>(url, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return this.instance.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.instance.delete<T, R>(url, config);
  }

  public setToken(newToken: string) {
    if (newToken.length >= 1) {
      this.token = newToken;
    } else {
      throw new Error("ArgumentException token");
    }
  }
  public disableToken() {
    this.token = null;
  }
}

export class HttpUrlClient {
  protected prefix: string;
  private client: HttpClient;

  public constructor(client: HttpClient, prefix?: string) {
    this.client = client;
    this.prefix = prefix || "";
  }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.client
        .post<T>(this.prefix + url, data, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.client
        .get<T>(this.prefix + url, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.client
        .put<T>(this.prefix + url, data, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  delete<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.client
        .delete<T>(this.prefix + url, {
          ...config,
          data: data,
        })
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }
}
