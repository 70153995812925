import React from "react";
import { Paper } from "@material-ui/core";
import { PRIMARY } from "../../styles/theme";

interface TitleDivInReportProps {
   title: string;
}

function TitleDivInReport({ title }: TitleDivInReportProps) {
   return (
      <Paper style={{ backgroundColor: PRIMARY, padding: "10px", color: "white", fontWeight: "white", textAlign: "center", fontSize: "20px" }}>
         {title}
      </Paper>
   );
}

export default TitleDivInReport;
